import React, { Component } from 'react'
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Booking.css'
import getAllBookingsQuery from './getAllBookings.graphql';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import BookingList from '../../../components/SiteAdmin/Booking/BookingList';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

export class Booking extends Component {

    static propTypes = {
        title: PropTypes.string.isRequired
    }

    static defaultProps = {
        bookingDetails: {
            loading: true
        }
    }

    render() {
        const { title, bookingDetails, bookingDetails: { loading } } = this.props;
        
        return (
            <Loader type={"page"} show={loading}>
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.heading}>
                        <FormattedMessage {...messages.bookingsMenu} />
                        </div>
                        <div className={s.paddingRoutesSection}>
                            {
                                !loading && <BookingList bookingDetails={bookingDetails} />
                            }
                        </div>
                    </div>
                </div>
            </Loader>
        );
    }
}

export default compose(
    withStyles(s),
    graphql(getAllBookingsQuery, {
        name: 'bookingDetails',
        options: {
            variables: {
                currentPage: 1
            },
            fetchPolicy: 'network-only'
        }
    })
)(Booking);
