import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

// Redux form
import { Field, reduxForm } from 'redux-form';

import submit from './submit';
import validate from './validate';

// Style
import {
  Button,
  FormGroup,
  FormControl
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ChangePasswordForm.css';
import bt from '../../../../components/restaurantCommon.css';

// Locale
import messages from '../../../../locale/messages';

class ChangePasswordForm extends React.Component {

  static propTypes = {
    formatMessage: PropTypes.any,
    initialValues: PropTypes.shape({
      email: PropTypes.string,
      token: PropTypes.string
    }).isRequired
  };

  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx(s.marginBottom)}>
        <FormControl {...input} placeholder={label} type={type} className={className} />
        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  }

  render() {
    const { handleSubmit, submitting, dispatch, initialValues, title } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={s.root}>
        <div className={s.container}>
          <div>
            <h2 className={s.heading}><FormattedMessage {...messages.changePassword} /></h2>
            <div>
              <form onSubmit={handleSubmit(submit)}>
                <Field className={cx(bt.formControlInputRestaurant)} name="newPassword" type="password" component={this.renderFormControl} label={formatMessage(messages.newPassword)} />
                <Field className={cx(bt.formControlInputRestaurant)} name="confirmPassword" type="password" component={this.renderFormControl} label={formatMessage(messages.confirmPassword)} />
                <FormGroup className={cx(s.formGroup, s.textAlignCenter)}>
                  <Button className={cx(bt.btnPrimary, s.btnWidth, s.saveBtnWidth)} type="submit" disabled={submitting}>
                    <FormattedMessage {...messages.save} />
                  </Button>
                </FormGroup>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChangePasswordForm = reduxForm({
  form: 'ChangeForgotPasswordForm', // a unique name for this form
  validate
})(ChangePasswordForm);

export default injectIntl(withStyles(s, bt)(ChangePasswordForm));