import gql from 'graphql-tag';
import {
	SHOP_MENU_UPDATE_START,
	SHOP_MENU_UPDATE_SUCCESS,
	SHOP_MENU_UPDATE_ERROR
} from '../../../constants/index';
import { toastr } from 'react-redux-toastr';
import { loadAccount } from '../userAccount/userAccount';

let query = gql`
query($currentPage: Int, $searchList: String) {
	getAllMenu(currentPage: $currentPage, searchList: $searchList){
	  count
	  status
	  errorMessage
	  results{
	   id
	   shopId
	   menuName
	   isEnable
	   menuAvailability{
		menuId
		operationDay
		isOpen
		isWholeDay
	   }
	  }
	}
  }
`;


export default function deleteMenu(id, currentPage) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: SHOP_MENU_UPDATE_START,
			payload: {
				updateLoading: true
			}
		});

		try {
			let errorMessage;

			let mutation = gql`
            mutation deleteMenu($id: Int) {
							deleteMenu(id: $id) {
                  status
                  errorMessage
                }
              }
            `;

			const { data } = await client.mutate({
				mutation,
				variables: {
					id
				},
				refetchQueries: [{ query, variables: { currentPage } }]
			});

			if (data && data.deleteMenu && data.deleteMenu.status === 200) {
				dispatch({
					type: SHOP_MENU_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				toastr.success('Success', `The Menu has been deleted successfully.`);
				dispatch(loadAccount())
			} else {
				dispatch({
					type: SHOP_MENU_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});

				errorMessage = (data && data.deleteMenu && data.deleteMenu.errorMessage) || "Oops! Something went wrong. Please try again.";
				toastr.error('Error!', errorMessage);
			}
		} catch (err) {
			toastr.error('Error!', err);
			dispatch({
				type: SHOP_MENU_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};
