import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getOrders from './getOrders.graphql';
import s from './OrderDetailsContainer.css';
import OrderDetails from '../../../components/Shop/OrderDetails/OrderDetails';
import Loader from '../../../components/Common/Loader';

class OrderDetailsContainer extends React.Component {
    static defaultProps = {
        orderData: {
            loading: true,
            getOrders: null
        }
    }

    render() {
        const { type, bookingId, orderData, orderData: { loading, getOrders } } = this.props;
        
        return (
            <div className={s.root}>
                <div className={s.container}>
                    {
                        !loading && orderData && <OrderDetails 
                            type={type} 
                            bookingId={bookingId}
                            data={getOrders && getOrders.result} 
                        />
                    }
                    {
                        (loading || (loading && !orderData)) && <Loader type="text" />
                    }
                </div>
            </div>
        );
    }
}

export default compose(
    withStyles(s),
    graphql(getOrders, {
        name: 'orderData',
        options: (props)=>({
            variables: {
                id: props.bookingId
            },
            fetchPolicy: 'network-only',
            ssr: true
        })
    })
)(OrderDetailsContainer);
// export default withStyles(s)(OrderDetailsContainer);
