import {
    SHOP_DOCUMENT_UPDATE_ERROR,
    SHOP_DOCUMENT_UPDATE_START,
    SHOP_DOCUMENT_UPDATE_SUCCESS
} from '../../../constants';

import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';


export default function uploadShopDocument(documentList) {
    return async (dispatch, getState, { client }) => {
        let status, errorMessage = 'Oops! something went wrong! Please try again.';

        try {
            dispatch({
                type: SHOP_DOCUMENT_UPDATE_START
            });

            dispatch(setLoaderStart('RestaurantDocumentUpdate'));

            const mutation = gql`
            mutation (
              $documentList: String
            ) {
                uploadShopDocument (
                    documentList:$documentList
                ) {
                    status
                    errorMessage                     
                }
            }`;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    documentList
                }
            });

            if (data && data.uploadShopDocument && data.uploadShopDocument.status == 200) {
                toastr.success('Success', `Your Document has been successfully uploaded.`);

                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('RestaurantDocumentUpdate'));

            } else {
                errorMessage = data && data.uploadShopDocument && data.uploadShopDocument.errorMessage
                toastr.error('Error!', errorMessage);

                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_ERROR
                });
                dispatch(setLoaderComplete('RestaurantDocumentUpdate'));
            }
        } catch (error) {
            errorMessage = "Something went wrong! " + error;

            toastr.error('Error!', errorMessage);

            await dispatch({
                type: SHOP_DOCUMENT_UPDATE_ERROR
            });
            dispatch(setLoaderComplete('RestaurantDocumentUpdate'));
        }
    }
};