import updateOperationHours from '../../../actions/shop/updateOperationHours';
import { toastr } from 'react-redux-toastr';


async function submit(values, dispatch) {
    let isError = false;

    values.OperationHours && values.OperationHours.map((item, index) => {
        item.isOpen = item.isOpen == false ? '0' : '1';
        if(item.endTime && item.startTime) { 
            if(Number(item.startTime) < Number(item.endTime)) {
                isError = false;
            } else if(Number(item.startTime) > Number(item.endTime) || Number(item.startTime) == Number(item.endTime)) {
                isError  = true;
            }
        }
    });

    let operationHours = values.OperationHours && values.OperationHours.length > 0 ? JSON.stringify(values.OperationHours) : JSON.stringify([]);
    
    if(!isError) {
        await dispatch(updateOperationHours(operationHours));
    } else {
        toastr.error('Error!', 'The end time must be higher than the start time.');
    }
}

export default submit;