import gql from 'graphql-tag';
import {
    SHOP_MENU_UPDATE_START,
    SHOP_MENU_UPDATE_SUCCESS,
    SHOP_MENU_UPDATE_ERROR
} from '../../../constants/index';
import { toastr } from 'react-redux-toastr';
import { loadAccount } from '../userAccount/userAccount';

let query = gql`
query($currentPage: Int, $searchList: String, $menuId: Int) {
    getAllSubMenu(currentPage: $currentPage, searchList: $searchList, menuId: $menuId){
      status
      errorMessage
      menuName
      results {
        id
        shopId
        menuId
        name
        isEnable
        item {
            id
            subMenuId
            menuId
            itemName
            itemDescription
            price
            tax
            image
            itemType
            isEnable
        }
      }
    }  
  }
  
`;


export default function updateSubMenuStatus(id, status, currentPage, menuId) {
    return async (dispatch, getState, { client }) => {
        

        try {
            let errorMessage;

            let mutation = gql`
            mutation updateSubMenuStatus($id: Int, $status: Boolean) {
                updateSubMenuStatus(id: $id, status:$status) {
                  status
                  errorMessage
                }
              }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    status: status == 'false' ? 0 : 1
                },
                refetchQueries: [{ query, variables: { currentPage, menuId } }]
            });

            if (data && data.updateSubMenuStatus && data.updateSubMenuStatus.status === 200) {
                
                toastr.success('Success', `The Sub Menu status has been updated successfully.`);
                dispatch(loadAccount())
            } else {
                

                errorMessage = (data && data.updateSubMenuStatus && data.updateSubMenuStatus.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
            }
        } catch (err) {
            toastr.error('Error!', err);
            
        }
    }
};
