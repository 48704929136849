import gql from 'graphql-tag';
import history from '../../history';
import {
    ADMIN_USER_LOGOUT_START,
    ADMIN_USER_LOGOUT_SUCCESS,
    ADMIN_USER_LOGOUT_ERROR,
    SET_RUNTIME_VARIABLE
} from '../../constants';
import fetch from 'node-fetch';

export function adminLogout() {
    return async (dispatch, getState, { client }) => {

        dispatch({
            type: ADMIN_USER_LOGOUT_START
        });

        try {
            await fetch('/adminLogout', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: null,
                credentials: 'include'
            });

            dispatch({
                type: ADMIN_USER_LOGOUT_SUCCESS
            }); 
        } catch (error) {
            dispatch({
                type: ADMIN_USER_LOGOUT_ERROR,
                payload: {
                    error
                }
            });
            return false;
        }
        return true;
    }
}