import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './OrderManageSection.css';
import OrderManagement from '../../../components/Shop/OrderManagement/OrderManagement';
import SocketContext from '../../../components/SocketContext';

class OrderManageSection extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchList: ''
    };

    this.changeKeyword = this.changeKeyword.bind(this);
  }

  changeKeyword(searchList) {
    this.setState({ searchList });
  }

  render() {
    const { type } = this.props;
    const { searchList } = this.state;

    return (
      <SocketContext.Consumer>
      {
        socket => (<div className={s.root}>
          <div className={s.container}>
            <OrderManagement type={type} changeKeyword={this.changeKeyword} searchList={searchList} socket={socket} />
          </div>
        </div>)
      }
      </SocketContext.Consumer>
    );
  }
}

export default withStyles(s)(OrderManageSection);
