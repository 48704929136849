import gql from 'graphql-tag';
import {
    SHOP_USER_UPDATE_SUCCESS,
    SHOP_USER_UPDATE_START,
    SHOP_USER_UPDATE_ERROR
} from '../../../constants/index';
import { toastr } from 'react-redux-toastr';
import socketNotification from '../../../core/socket/socketNotification';

export default function updateShopStatus(updateField, id, status, currentPage, userType) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: SHOP_USER_UPDATE_START,
            payload: {
                updateLoading: true
            }
        });

        try {
            let errorMessage, label = 'Shop';
            let mutation = gql`
            mutation updateShopStatus($id: ID, $updateField: String, $status: String) {
                updateShopStatus(id: $id, updateField:$updateField, status:$status) {
                  status
                  errorMessage
                }
              }
            `;

            label = userType === 3 ? 'Shop' : (userType === 2 ? 'Driver' : 'User')

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    updateField,
                    status: status == '0' ? 0 : status
                }
            });

            if (data && data.updateShopStatus && data.updateShopStatus.status === "200") {
                dispatch({
                    type: SHOP_USER_UPDATE_SUCCESS,
                    payload: {
                        updateLoading: false
                    }
                });
                if (updateField === "isBan" && status == 1) {
                    let requestData = { id };
                    socketNotification('webUserLogout', JSON.stringify(requestData));
                }
                toastr.success('Success', `The ${label} status has been updated successfully.`);
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: SHOP_USER_UPDATE_ERROR,
                    payload: {
                        updateLoading: false
                    }
                });

                errorMessage = (data && data.updateShopStatus && data.updateShopStatus.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
                return {
                    status: 400
                };
            }
        } catch (err) {
            toastr.error('Error!', err);
            dispatch({
                type: SHOP_USER_UPDATE_ERROR,
                payload: {
                    updateLoading: false
                }
            });
            return {
                status: 400
            };
        }
    }
};