import gql from 'graphql-tag';
import {
    SHOP_MENU_UPDATE_START,
    SHOP_MENU_UPDATE_SUCCESS,
    SHOP_MENU_UPDATE_ERROR
} from '../../../constants/index';
import { toastr } from 'react-redux-toastr';
import { loadAccount } from '../userAccount/userAccount';

let query = gql`
query($currentPage: Int, $searchList: String, $menuId: Int) {
    getAllSubMenu(currentPage: $currentPage, searchList: $searchList, menuId: $menuId){
      status
      errorMessage
      menuName
      results {
        id
        shopId
        menuId
        name
        isEnable
        item {
            id
            subMenuId
            menuId
            itemName
            itemDescription
            price
            tax
            image
            itemType
            isEnable
        }
      }
    }  
  }
`;


export default function deleteSubMenu(id, currentPage, menuId) {
    return async (dispatch, getState, { client }) => {

        try {
            let errorMessage;

            let mutation = gql`
            mutation deleteSubMenu($id: Int) {
							deleteSubMenu(id: $id) {
                  status
                  errorMessage
                }
              }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                },
                refetchQueries: [{ query, variables: { currentPage, searchList: '', menuId } }]
            });

            if (data && data.deleteSubMenu && data.deleteSubMenu.status === 200) {
                toastr.success('Success', `The Sub Menu has been deleted successfully.`);
                dispatch(loadAccount())
            } else {
                errorMessage = (data && data.deleteSubMenu && data.deleteSubMenu.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
            }
        } catch (err) {
            toastr.error('Error!', err);

        }
    }
};
