import messages from '../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.homeSectionTitle1) {
        errors.homeSectionTitle1 = messages.required;
    } else if (values.homeSectionTitle1.trim() == "") {
        errors.homeSectionTitle1 = messages.required;
    } else if (values.homeSectionTitle1.length < 2 ) {
        errors.homeSectionTitle1 = messages.minExceedLimit
    } else if (values.homeSectionTitle1.length > 30 ) {
        errors.homeSectionTitle1 = messages.exceedLimit30
    }

    if (!values.homeSectionDescription) {
        errors.homeSectionDescription = messages.required;
    } else if (values.homeSectionDescription.trim() == "") {
        errors.homeSectionDescription = messages.required;
    } else if (values.homeSectionDescription.length < 2 ) {
        errors.homeSectionDescription = messages.minExceedLimit
    } else if (values.homeSectionDescription.length > 400 ) {
        errors.homeSectionDescription = messages.exceedLimit400
    }

    

    return errors;
};

export default validate;