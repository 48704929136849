import React, { Component } from 'react';
import s from './AboutSettingsForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
    Form,
    Col,
    Row,
    FormControl,
    FormGroup,
    Container
} from 'react-bootstrap';
import Link from '../../Link/Link';
import messages from '../../../locale/messages';
import validate from './validate';
import { api, homepageUploadDir } from '../../../config';
import Dropzone from './Dropzone.js'
import Loader from '../../Common/Loader';

export class AboutSettingsForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={s.space3}>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }

    render() {
        const { handleSubmit, aboutGridImage1, aboutGridImage2, aboutGridImage3, loading, submitting } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col lg={10} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <h1 className={s.titleTextAdmin}>{formatMessage(messages.howItWorks)}</h1>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="aboutGridTitle5" type="text" component={this.renderField} label={formatMessage(messages.titleLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="aboutGridContent5" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.descriptionLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <label>{formatMessage(messages.homeImage1)}</label><br />
                                                        {
                                                            aboutGridImage1 &&
                                                            <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir + aboutGridImage1})` }} />
                                                        }
                                                        <div>
                                                            <Dropzone
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                fieldName={'aboutGridImage1'}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                inputContainer={'.dzInputContainerAboutImage1'}
                                                                inputContainerClass={'dzInputContainerAboutImage1'}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="aboutGridTitle1" type="text" component={this.renderField} label={formatMessage(messages.bannerImage1DescriptionLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <label>{formatMessage(messages.homeImage2)}</label><br />
                                                        {
                                                            aboutGridImage2 &&
                                                            <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir + aboutGridImage2})` }} />
                                                        }
                                                        <div>
                                                            <Dropzone
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                fieldName={'aboutGridImage2'}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                inputContainer={'.dzInputContainerAboutImage2'}
                                                                inputContainerClass={'dzInputContainerAboutImage2'}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="aboutGridTitle2" type="text" component={this.renderField} label={formatMessage(messages.bannerImage2DescriptionLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <label>{formatMessage(messages.homeImage3)}</label><br />
                                                        {
                                                            aboutGridImage2 &&
                                                            <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir + aboutGridImage3})` }} />
                                                        }
                                                        <div>
                                                            <Dropzone
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                fieldName={'aboutGridImage3'}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                inputContainer={'.dzInputContainerAboutImage3'}
                                                                inputContainerClass={'dzInputContainerAboutImage3'}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="aboutGridTitle3" type="text" component={this.renderField} label={formatMessage(messages.bannerImage3DescriptionLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                                <Form.Group className={s.noMargin}>
                                                    <div className={s.displayInlineBlock}>
                                                        <Loader
                                                            type={"button"}
                                                            label={formatMessage(messages.submitButton)}
                                                            show={loading}
                                                            buttonType={'submit'}
                                                            className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                                                            disabled={submitting || loading}
                                                            isSuffix={true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

AboutSettingsForm = reduxForm({
    form: 'AboutSettingsForm',
    onSubmit: submit,
    validate
})(AboutSettingsForm);

const selector = formValueSelector('AboutSettingsForm')

const mapState = (state) => ({
    aboutGridImage1: selector(state, 'aboutGridImage1'),
    aboutGridImage2: selector(state, 'aboutGridImage2'),
    aboutGridImage3: selector(state, 'aboutGridImage3'),
    loading: state.loader.AboutSettingsForm
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AboutSettingsForm)));