import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './RestaurantPartners.css'
import { api, partnerHomepageUploadDir } from '../../../../config'

class RestaurantPartnerSection extends React.Component {

    render() {
        const { title, Image } = this.props;
        return (
            <div>
                <div className={s.mainSection}>
                    <div>
                        <div className={s.bannerBackgroundImage}
                            style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir + Image})` }} />
                        {/* <img className={s.partnerImage} src={api.apiEndpoint + partnerHomepageUploadDir + Image} alt="PartnerImages" /> */}
                    </div>
                </div>
                <div className={s.spaceTop5}>
                    <p className={s.titleText}>
                        {title}
                    </p>
                </div>
            </div>
        );
    }
}


export default injectIntl(withStyles(s)(RestaurantPartnerSection));