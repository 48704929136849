import messages from '../../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.bannerTitle1) {
        errors.bannerTitle1 = messages.required;
    } else if (values.bannerTitle1.trim() == "") {
        errors.bannerTitle1 = messages.required;
    } else if (values.bannerTitle1.length < 2 ) {
        errors.bannerTitle1 = messages.required
    } else if (values.bannerTitle1.length > 200 ) {
        errors.bannerTitle1 = messages.exceedLimit
    } 

    if (!values.bannerTitle2) {
        errors.bannerTitle2 = messages.required;
    } else if (values.bannerTitle2.trim() == "") {
        errors.bannerTitle2 = messages.required;
    } else if (values.bannerTitle2.length < 2 ) {
        errors.bannerTitle2 = messages.required
    } else if (values.bannerTitle2.length > 200 ) {
        errors.bannerTitle2 = messages.exceedLimit
    } 

    if (!values.bannerContent1) {
        errors.bannerContent1 = messages.required;
    } else if (values.bannerContent1.trim() == "") {
        errors.bannerContent1 = messages.required;
    } else if (values.bannerContent1.length < 2 ) {
        errors.bannerContent1 = messages.required
    } else if (values.bannerContent1.length > 200 ) {
        errors.bannerContent1 = messages.exceedLimit
    } 

    
    return errors;
};

export default validate;