import React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import s from './Password.css';
import rs from '../../restaurantCommon.css';
import messages from '../../../locale/messages';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import validate from './validate';
import submit from './submit';
import { connect } from 'react-redux';

import {
    Button,
    FormGroup,
    Col,
    Row,
    FormControl,
    Form,
    Container
} from 'react-bootstrap';


class Password extends React.Component {

    renderFormControl = ({ input, label, type, meta: { touched, error }, className, autocomplete }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(s.formGroup, rs.displayGridPrifile, rs.alignItemCenter)}>
                <div className={s.changePassPadding}>
                    <Form.Label className={rs.restaurantLabelText}>{label}</Form.Label>
                </div>
                <div>
                    <FormControl {...input} placeholder={label} type={type} className={className} autocomplete={autocomplete} />
                    {touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
                </div>
            </FormGroup>
        );
    }

    render() {
        const { error, handleSubmit, submitting } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div className={'mainContainer'}>
                <Container fluid>
                    <Row>
                        <Col md={12} lg={12} sm={12} xs={12}>
                            <div>
                                <h1 className={cx(s.titleBottom, rs.restaurantTitleText)}>{formatMessage(messages.changePassword)}</h1>
                            </div>
                            <Form onSubmit={handleSubmit(submit)}>
                                {error && <span className={rs.errorMessage}>{error}</span>}
                                <FormGroup className={s.formGroup}>
                                    <Field name="currentPassword" type="password"
                                        label={formatMessage(messages.currentPassword)}
                                        component={this.renderFormControl}
                                        placeholder={'Password'}
                                        className={rs.formControlInputRestaurant}
                                        autocomplete={'current-password'}    
                                    />
                                </FormGroup>
                                <FormGroup className={s.formGroup}>
                                    <Field name="newPassword" type="password"
                                        label={formatMessage(messages.newPassword)}
                                        component={this.renderFormControl}
                                        placeholder={'Password'}
                                        className={rs.formControlInputRestaurant}
                                        autocomplete={'new-password'}
                                    />
                                </FormGroup>
                                <FormGroup className={s.formGroup}>
                                    <Field name="confirmNewPassword" type="password"
                                        label={formatMessage(messages.confirmNewPassword)}
                                        component={this.renderFormControl}
                                        placeholder={'Password'}
                                        className={rs.formControlInputRestaurant}
                                        autocomplete={'new-password'} 
                                    />
                                </FormGroup>
                                <FormGroup className={s.formGroup}>
                                    <Button className={cx(rs.button, rs.btnPrimary, s.btnWidth)} block type="submit" disabled={submitting}>
                                        {formatMessage(messages.updatePassword)}
                                    </Button>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

}


Password = reduxForm({
    form: 'ChangePassword',
    validate
})(Password);

const mapState = (state) => ({});

const mapDispatch = {};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(Password)));