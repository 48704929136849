import gql from 'graphql-tag';
import {
    PARTNER_HOMEPAGE_UPDATE_SUCCESS,
    PARTNER_HOMEPAGE_UPDATE_START,
    PARTNER_HOMEPAGE_UPDATE_ERROR
} from '../../../constants/index';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';

export default function updatePartnerHomepage(values) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: PARTNER_HOMEPAGE_UPDATE_START
        });

        try {
            let errorMessage;

            dispatch(setLoaderStart('UpdatePartnerHomepage'));

            let mutation = gql`
            mutation updatePartnerHomepage (
                $bannerTitle1: String,
                $bannerTitle2: String,
                $bannerContent1: String,
                $bannerImage1: String,
                $sliderTitle1: String,
                $sliderContent1: String,
                $sliderImage1: String,
                $sliderTitle2: String,
                $sliderContent2: String,
                $sliderImage2: String,
                $sliderTitle3: String,
                $sliderContent3: String,
                $sliderImage3: String,
                $sliderTitle4: String,
                $sliderContent4: String,
                $sliderTitle5: String,
                $infoTitle1: String,
                $infoImage1: String,
                $infoTitle2: String,
                $infoImage2: String,
                $infoTitle3: String,
                $infoImage3: String,
                $infoTitle4: String,
                $infoContent4: String,
                $buttonName: String,
                $buttonLink: String,
                $sliderImage5: String
            ) {
              updatePartnerHomepage (
                bannerTitle1: $bannerTitle1,
                bannerTitle2: $bannerTitle2,
                bannerContent1: $bannerContent1,
                bannerImage1: $bannerImage1,
                sliderTitle1: $sliderTitle1,
                sliderContent1: $sliderContent1,
                sliderImage1: $sliderImage1,
                sliderTitle2: $sliderTitle2,
                sliderContent2: $sliderContent2,
                sliderImage2: $sliderImage2,
                sliderTitle3: $sliderTitle3,
                sliderContent3: $sliderContent3,
                sliderImage3: $sliderImage3,
                sliderTitle4: $sliderTitle4,
                sliderContent4: $sliderContent4,
                sliderTitle5: $sliderTitle5,
                infoTitle1: $infoTitle1,
                infoImage1: $infoImage1,
                infoTitle2: $infoTitle2,
                infoImage2: $infoImage2,
                infoTitle3: $infoTitle3,
                infoImage3: $infoImage3,
                infoTitle4: $infoTitle4,
                infoContent4: $infoContent4,
                buttonName: $buttonName,
                buttonLink: $buttonLink,
                sliderImage5: $sliderImage5
              ) {
                status
              }
            }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    bannerTitle1: values && values.bannerTitle1,
                    bannerTitle2: values && values.bannerTitle2,
                    bannerContent1: values && values.bannerContent1,
                    bannerImage1: values && values.bannerImage1,
                    sliderTitle1: values && values.sliderTitle1,
                    sliderContent1: values && values.sliderContent1,
                    sliderImage1: values && values.sliderImage1,
                    sliderTitle2: values && values.sliderTitle2,
                    sliderContent2: values && values.sliderContent2,
                    sliderImage2: values && values.sliderImage2,
                    sliderTitle3: values && values.sliderTitle3,
                    sliderContent3: values && values.sliderContent3,
                    sliderImage3: values && values.sliderImage3,
                    sliderTitle4: values && values.sliderTitle4,
                    sliderContent4: values && values.sliderContent4,
                    sliderTitle5: values && values.sliderTitle5,
                    infoTitle1: values && values.infoTitle1,
                    infoImage1: values && values.infoImage1,
                    infoTitle2: values && values.infoTitle2,
                    infoImage2: values && values.infoImage2,
                    infoTitle3: values && values.infoTitle3,
                    infoImage3: values && values.infoImage3,
                    infoTitle4: values && values.infoTitle4,
                    infoContent4: values && values.infoContent4,
                    buttonName: values && values.buttonName,
                    buttonLink: values && values.buttonLink,
                    sliderImage5: values && values.sliderImage5
                }
            });

            if (data && data.updatePartnerHomepage && data.updatePartnerHomepage.status == "200") {
                dispatch({
                    type: PARTNER_HOMEPAGE_UPDATE_SUCCESS
                });

                await dispatch(setLoaderComplete('UpdatePartnerHomepage'));
                toastr.success('Success', `The Shop Homepage has been updated successfully.`);
            } else {
                dispatch({
                    type: PARTNER_HOMEPAGE_UPDATE_ERROR
                });

                errorMessage = (data && data.updatePartnerHomepage && data.updatePartnerHomepage.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
            }
        } catch (err) {

            toastr.error('Error!', err);
            dispatch({
                type: PARTNER_HOMEPAGE_UPDATE_ERROR
            });
        }

    }
};