import messages from '../locale/messages';

const deliveryOptions = {
  '1': 'meetAtDoor',
  '2': 'leaveAtDoor',
  '3': 'pickUpOutside',
};

export function getDeliveryName(option) {
  if (!option || !deliveryOptions[option]) return null;
  return messages[deliveryOptions[option]];
}