exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2mgPm {\n  display: inline-block;\n  width: 50%;\n  position: relative;\n  margin-bottom: 30px;\n  vertical-align: top;\n}\n._1cOkJ {\n  position: absolute;\n  top: -15px;\n  right: 2px;\n}\n._20XoN, ._20XoN:active {\n  border-radius: 50% !important;\n  padding: 0 !important;\n  width: 28px;\n  height: 28px;\n  text-align: center;\n  color: #000;\n  background: #fff !important;\n  border: 2px solid #FF1F1F !important;\n  -webkit-box-shadow: 0px 0px 15px 3px #eeebeb;\n          box-shadow: 0px 0px 15px 3px #eeebeb;\n}\n._20XoN:focus {\n  -webkit-box-shadow: none !important;\n          box-shadow: none !important;\n}\n._20XoN:hover {\n  background: #fff !important;\n  border: 2px solid #FF1F1F;\n}\n@media screen and (max-width: 991px) {\n  ._2mgPm {\n    width: 100%;\n  }\n}\n@media screen and (max-width: 767px) {\n  .Tidwg {\n    padding: 0px;\n  }\n}\n._1hHFT {\n  color: red !important;\n}", ""]);

// exports
exports.locals = {
	"displayInlineBock": "_2mgPm",
	"removeSection": "_1cOkJ",
	"removeBtnSection": "_20XoN",
	"responsiveNoPadding": "Tidwg",
	"errorMessage": "_1hHFT"
};