import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Field, reduxForm, initialize, change } from 'redux-form';
import {
    Row,
    FormGroup,
    Col,
    FormControl,
    Container
} from 'react-bootstrap';
import cx from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages'
import s from './ManageNotificationsForm.css'
import submit from './submit'
import validate from './validate'
import Loader from '../../Common/Loader';

export class ManageNotificationsForm extends Component {

    static defaultProps = {
        loading: false,
        siteName: ''
    };

    componentDidMount() {
        const { initialize, siteName } = this.props
        initialize({ to: "all", messageType: "pushNotification", title: siteName })
    }

    componentDidUpdate(prevProps) {
        const { siteName, change } = this.props;
        if (this.props.siteName !== prevProps.siteName) {
            change('title', siteName);
        }
    }

    renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.space3}>
                    <div>
                        <label>{label}</label>
                    </div>
                    <div>
                        <FormControl {...input} placeholder={label} type={type} className={className} maxlength={maxlength} />
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }

    render() {
        const { formatMessage } = this.props.intl
        const { handleSubmit, loading, submitting } = this.props;
        return (
            <div className={'customRatioButton'}>
                <div className={cx(s.space5, s.spaceTop5)}>
                    <Container fluid>
                        <Row>
                            <Col lg={8} md={12} sm={12} xs={12} className={s.blackCenter}>
                                <div className={s.blackCenterSection}>
                                    <form onSubmit={handleSubmit(submit)}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <div>
                                                    <FormGroup className={s.space3}>
                                                        <div>
                                                            <div>
                                                                <label className={s.labelText}>{formatMessage(messages.toLabel)}:</label>
                                                            </div>
                                                            <div className={cx(s.blockRadioButton, s.displayInlineBlock, s.responsiveBlock)}>
                                                                <Field name="to" component="input" type="radio" value="all" className={s.cursurPointer} />
                                                                {' '}<label className={cx(s.labelText, s.noMargin, s.radioTextLabel)}>{formatMessage(messages.allLabel)}</label>
                                                            </div>
                                                            <div className={cx(s.blockRadioButton, s.displayInlineBlock, s.radioText, s.responsiveBlock)}>
                                                                <Field name="to" component="input" type="radio" value="2" className={s.cursurPointer} />
                                                                {' '} <label className={cx(s.labelText, s.noMargin, s.radioTextLabel)}>{formatMessage(messages.driver)}</label>
                                                            </div>
                                                            <div className={cx(s.blockRadioButton, s.displayInlineBlock, s.responsiveBlock)}>
                                                                <Field name="to" component="input" type="radio" value="1" className={s.cursurPointer} />
                                                                {' '}<label className={cx(s.labelText, s.noMargin, s.radioTextLabel)}>{formatMessage(messages.eaters)}</label>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Field name="message" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.messageLabel)} />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                                                <div>
                                                    <div className={s.displayInlineBlock}>
                                                        <Loader
                                                            type={"button"}
                                                            label={formatMessage(messages.submitButton)}
                                                            show={loading}
                                                            buttonType={'submit'}
                                                            className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                                                            disabled={submitting || loading}
                                                            isSuffix={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

ManageNotificationsForm = reduxForm({
    form: 'ManageNotificationsForm',
    onSubmit: submit,
    validate
})(ManageNotificationsForm)

const mapState = (state) => ({
    loading: state.loader.sendNotification,
    siteName: state.siteSettings && state.siteSettings.data && state.siteSettings.data.siteName
})

const mapDispatch = {
    initialize,
    change
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ManageNotificationsForm)));