import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import getOrderDetailsQuery from '../../../routes/shop/orderDetails/getOrders.graphql';

export default function updateFoodStatus(id, isOrderDetailPage, status) {
    return async (dispatch, getState, { client }) => {
        try {
            let refetchQueries = [];
            let mutation = gql`
                mutation updateFoodStatus($id: Int!, $status: String) {
                    updateFoodStatus (id: $id, status: $status) {
                        status
                        errorMessage
                        successMessage
                    }
                }
            `;

            if (isOrderDetailPage) {
                refetchQueries = [{
                    query: getOrderDetailsQuery,
                    variables: { id }
                }];
            }

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    status
                },
                refetchQueries
            });

            if (data && data.updateFoodStatus && data.updateFoodStatus.status === 200) {
                toastr.success('Success', data.updateFoodStatus.successMessage);
            } else {
                toastr.error('Error', data && data.updateFoodStatus && data.updateFoodStatus.errorMessage);
            }

            return true;
        } catch (error) {
            toastr.error('Error', error);

        }
    }
};