import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
// style
import s from './CommonSettings.css';
// graphql
import getCommonSettingsData from './getCommonSettingsData.graphql';
// component
import CommonSettingsManagement from '../../../components/SiteAdmin/CommonSettingsManagement';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

class CommonSettings extends React.Component {
  static defaultProps = {
    settingsData: {
      loading: true
    }
  };

  render() {
    const { typeData, settingsData, settingsData: { loading, getAdminCommonSettingData } } = this.props;

    return (
      <Loader type={'page'} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.heading}><FormattedMessage {...messages.dietaryType} /></div>
            <div className={s.paddingRoutesSection}>
              {
                !loading && settingsData && getAdminCommonSettingData && <CommonSettingsManagement 
                  typeData={typeData} 
                  settingsData={settingsData}
                />
              }
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getCommonSettingsData, {
    name: 'settingsData',
    options: (props) => ({
      variables: {
        typeId: props.typeId,
        currentPage: 1
      },
      fetchPolicy: 'network-only',
      // ssr: true
    })
  })
)(CommonSettings);