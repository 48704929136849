import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Header.css';
import { Navbar, Nav } from 'react-bootstrap';
import cx from 'classnames';
import { flowRight as compose } from 'lodash';

// Component
import LoginModal from '../Shop/LoginModal/LoginModal';
import ForgotPasswordModal from '../Shop/ForgotPassword/ForgotPasswordModal';
import Link from '../Link';
import NavLink from '../NavLink';
import HeaderModal from '../HeaderModal/HeaderModal';

// Action
import { openHeaderModal } from '../../actions/siteadmin/modalActions';
import { openOrderModal } from '../../actions/modalActions';
import { getPendingOrders } from '../../actions/shop/orders/getPendingOrders';
// helper
import { formatLocale } from '../../helpers/formatLocale';

// Local
import messages from '../../locale/messages';
import languageIcon from '../../../public/Icons/Language-black.svg';

// Config
import { api, logoUploadDir } from '../../config';
import OrderModal from '../Shop/OrderModal/OrderModal';

class Header extends React.Component {
  static defaultProps = {
    isRestaurantAuthenticated: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: 0,
    };
    this.openMenu = this.openMenu.bind(this);
    this.openClose = this.openClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSocketResponse = this.handleSocketResponse.bind(this);

  }
  async handleSocketResponse() {
    const { openOrderModal, getPendingOrders } = this.props;
    await getPendingOrders();
    await openOrderModal();
  }

  componentDidMount() {
    const { socket, userId } = this.props;
    if (socket && userId) {
      socket.on(`orderReceivedShop-${userId}`, (data) => { this.handleSocketResponse() });
    }
  }

  componentWillUnmount() {
    const { socket, userId } = this.props;
    if (socket && userId) {
      socket.removeListener(`webUserLogout-${userId}`);
      socket.removeListener(`webAdminUserLogout-${userId}`);
      socket.removeListener(`orderReceivedShop-${userId}`)
    }
  }


  async openMenu() {
    this.setState({
      isOpen: 1,
    })
  }
  async openClose() {
    this.setState({
      isOpen: 0,
    })
  }


  handleChange(e) {
    const { openHeaderModal } = this.props;
    openHeaderModal('languageModal');
  }



  render() {
    const { logo, logoHeight, logoWidth, siteName, isOpen, isRestaurantAuthenticated, currentLocale } = this.props;
    const { socket, userId } = this.props;
    if (socket && userId) {
      socket.on(`webAdminUserLogout-${userId}`, (data) => {
        if (typeof window !== "undefined") window.location.assign('/adminUserLogout');
      });

      socket.on(`webUserLogout-${userId}`, (data) => {
        if (typeof window !== "undefined") window.location.assign('/signup');
      });
    }
    return (
      <div className={cx(s.root, 'mainMenu')}>
        <Navbar expand="lg" className={s.navBg}>
          <Navbar.Brand>
            <Link className={s.brand} to="/">
              <img
                src={api.apiEndpoint + logoUploadDir + logo}
                // srcSet={`${logoUrl2x} 2x`}
                width={Number(logoWidth)}
                height={Number(logoHeight)}
                alt={siteName}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className={cx(s.borderNone, s.outlineNone)}
            children={
              <div className={'menuToggle'} onClick={() => this.openMenu()}>
                {/* <input type="checkbox" /> */}
                <span></span>
                <span></span>
                <span></span>
              </div>
            }
          />
          <Navbar.Collapse id="basic-navbar-nav" className={cx({ [s.menuOpened]: this.state.isOpen == 1 }, s.justifyFlexEnd, s.menuClosed)} in={isOpen}>
            {/* <Navigation /> */}
            <div>
              <LoginModal />
              <ForgotPasswordModal />
              <HeaderModal />
              <OrderModal />
              <Nav className={s.navigationLink} onClick={() => this.openClose()}>
                <div className={s.closeButton}> &#x2715; </div>
                <NavLink
                  onClick={(e) => this.handleChange(e)}
                  className={cx(s.siteColor, s.cursurPointer)}
                >
                  <span className={cx(s.languageIcon, 'languageIconRTL')}><img src={languageIcon} /></span>
                  <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding)}>
                    {formatLocale(currentLocale)}
                  </span>
                </NavLink>
                <NavLink to="/"><FormattedMessage {...messages.home} /></NavLink>
                {
                  !isRestaurantAuthenticated && <NavLink to="/signup" ><FormattedMessage {...messages.signupHeader} /></NavLink>
                }
                <NavLink to="/contact"><FormattedMessage {...messages.contact} /></NavLink>
                {
                  isRestaurantAuthenticated && <NavLink to="/shop/dashboard" ><FormattedMessage {...messages.dashboard} /></NavLink>
                }
              </Nav>
            </div>
          </Navbar.Collapse>

        </Navbar>
      </div>
    )
  }
}

const mapState = (state) => ({
  logo: state.siteSettings.data.homeLogo,
  logoHeight: state.siteSettings.data.logoHeight,
  logoWidth: state.siteSettings.data.logoWidth,
  siteName: state.siteSettings.data.siteName,
  isRestaurantAuthenticated: state.runtime.isRestaurantAuthenticated,
  userId: state.account && state.account.data && state.account.data.id,
  currentLocale: state.intl.locale
})
const mapDispatch = {
  openHeaderModal,
  openOrderModal,
  getPendingOrders
}

export default compose(
  withStyles(s),
  connect(mapState, mapDispatch)
)(Header);
