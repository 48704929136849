import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import {
    UPDATE_SITE_SETTINGS_START,
    UPDATE_SITE_SETTINGS_ERROR,
    UPDATE_SITE_SETTINGS_SUCCESS,
    TONE_UPLOAD_START,
    TONE_UPLOAD_SUCCESS,
    TONE_UPLOAD_ERROR
} from '../../constants/index';

import { change } from 'redux-form';
import { api } from '../../config';
import { siteSettings } from './siteSettings';

export default function updateSiteSettings(siteName, siteTitle, metaDescription, facebookLink, twitterLink, instagramLink, logoHeight, logoWidth, metaKeyword, homeLogo, youtubeLink, currency, distanceUnits, appForceUpdate, eaterAndroidVersion, eaterIosVersion, driverAndroidVersion, driverIosVersion, preferredDelivery, favicon, contactNumber, contactEmail, contactSkype, notificationInterval, requestTimeTone, requestToneFile, orderAcceptInterval) {

    return async (dispatch, getState, { client }) => {

        dispatch({
            type: UPDATE_SITE_SETTINGS_START
        })

        try {
            const mutation = gql`
            mutation updateSiteSettings(
                $siteName: String,
                $siteTitle: String,
                $metaDescription: String, 
                $facebookLink: String,
                $twitterLink: String,
                $instagramLink: String,
                $logoHeight: Int,
                $logoWidth: Int,
                $metaKeyword: String,
                $homeLogo: String,
                $youtubeLink: String,
                $currency: String,
                $distanceUnits: String,
                $appForceUpdate: String,
                $eaterAndroidVersion: String,
                $eaterIosVersion: String,
                $driverAndroidVersion: String,
                $driverIosVersion: String,
                $preferredDelivery: String,
                $favicon: String
                $contactNumber: String,
                $contactEmail: String,
                $contactSkype: String,
                $notificationInterval: String,
                $requestTimeTone: String,
                $requestToneFile: String,
                $orderAcceptInterval: String
                ) {
                    updateSiteSettings(
                    siteName: $siteName
                    siteTitle: $siteTitle
                    metaDescription: $metaDescription
                    facebookLink: $facebookLink
                    twitterLink: $twitterLink
                    instagramLink: $instagramLink
                    logoHeight: $logoHeight
                    logoWidth: $logoWidth
                    metaKeyword: $metaKeyword
                    homeLogo: $homeLogo
                    youtubeLink: $youtubeLink
                    currency: $currency
                    distanceUnits: $distanceUnits
                    appForceUpdate: $appForceUpdate
                    eaterAndroidVersion: $eaterAndroidVersion
                    eaterIosVersion: $eaterIosVersion
                    driverAndroidVersion: $driverAndroidVersion
                    driverIosVersion: $driverIosVersion
                    preferredDelivery: $preferredDelivery
                    favicon: $favicon
                    contactNumber: $contactNumber
                    contactEmail: $contactEmail
                    contactSkype:  $contactSkype
                    notificationInterval: $notificationInterval
                    requestTimeTone:$requestTimeTone
                    requestToneFile:$requestToneFile
                    orderAcceptInterval: $orderAcceptInterval,
                    ){
                        status
                        errorMessage
                    }
                }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    siteName,
                    siteTitle,
                    metaDescription,
                    facebookLink,
                    twitterLink,
                    instagramLink,
                    logoHeight,
                    logoWidth,
                    metaKeyword,
                    homeLogo,
                    youtubeLink,
                    currency,
                    distanceUnits,
                    appForceUpdate,
                    eaterAndroidVersion,
                    eaterIosVersion,
                    driverAndroidVersion,
                    driverIosVersion,
                    preferredDelivery,
                    favicon,
                    contactNumber,
                    contactEmail,
                    contactSkype,
                    notificationInterval,
                    requestTimeTone,
                    requestToneFile,
                    orderAcceptInterval
                }
            })

            if (data && data.updateSiteSettings && data.updateSiteSettings.status == 200) {

                dispatch({
                    type: UPDATE_SITE_SETTINGS_SUCCESS
                })
                await dispatch(siteSettings())
                toastr.success('Success', 'Site settings changes have been successfully applied')
            } else {
                dispatch({
                    type: UPDATE_SITE_SETTINGS_ERROR
                })
                toastr.error('Oops!', 'Something went wrong')
            }
        } catch (err) {
            dispatch({
                type: UPDATE_SITE_SETTINGS_ERROR
            })
            toastr.error('Oops!', 'Something went wrong')
        }

    }
}


export function deleteTone(fileName) {

    return async (dispatch, getState, { client }) => {
        dispatch({
            type: TONE_UPLOAD_START
        })

        try {

            dispatch(change("SiteSettingsForm", "requestTimeTone", null));

            const url = api.apiEndpoint + "/deleteTone";
            const resp = await fetch(url, {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ fileName }),
            });

            const { status } = await resp.json();

            if (status == 200) {

                const mutation = gql`
				mutation updateTone {
						updateTone {
						status
						}
					}
				`
                const { data } = await client.mutate({
                    mutation
                })

                dispatch({
                    type: TONE_UPLOAD_SUCCESS
                })

            } else {
                dispatch({
                    type: TONE_UPLOAD_ERROR
                })
            }

        } catch (err) {

            dispatch({
                type: TONE_UPLOAD_ERROR
            })
            toastr.error("Error!", "Oops! Something went wrong, " + err);
            return true;
        }
    }
}
