import { sendNotifications } from '../../../helpers/push-notification/sendNotifications';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { reset } from 'redux-form';
import { toastr }  from 'react-redux-toastr';

async function submit(values, dispatch) {
    let content = {
	    title: values.title,
        message: values.message
    };
    
    let userType = values.to;
    
    dispatch(setLoaderStart('sendNotification'));
    sendNotifications('notification', content, null, userType);
    dispatch(reset('ManageNotificationsForm'));
    dispatch(setLoaderComplete('sendNotification'));
    toastr.success('Success!', 'The notification has been sent successfully.');
}

export default submit;
