import { change } from 'redux-form';
import {
    ADDITEM_MODIFIER_START,
    ADDITEM_MODIFIER_SUCCESS,
    ADDITEM_MODIFIER_ERROR
} from '../../../constants/index';
import getExistingModifierQuery from '../../../routes/shop/editModifierGroups/getModifier.graphql';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';

export default function getExistingModifier(id) {
    return async (dispatch, getState, { client }) => {

        try {

            dispatch({
                type: ADDITEM_MODIFIER_START,
            });
            dispatch(setLoaderStart('updateLoading'));

            const { data: { getModifier } } = await client.query({
                query: getExistingModifierQuery, variables: {
                    id
                }, fetchPolicy: 'network-only'
            });

            let updatedModifierData = [], existingModifierData = [], modifiers = {}, arrayData = [];
            if (getModifier && getModifier.status == 200) {

                if (getModifier.result.isActive === "true") {
                    arrayData.push(getModifier.result);
                    modifiers['modifierGroups'] = arrayData;
                } else {
                    await dispatch(setLoaderComplete('updateLoading'));
                    toastr.error('Error!', 'The modifier is unavailable at the moment. Please activate it on the "Modifier Groups" section.');
                    return;
                }

            } else {
                await dispatch(setLoaderComplete('updateLoading'));
                toastr.error('Error!', 'The modifier is unavailable at the moment. Please activate it on the "Modifier Groups" section.');
                return;
            }

            if (getState().form.AddItemForm && getState().form.AddItemForm.values) {
                existingModifierData = getState().form.AddItemForm.values.modifiers;
            }
            if (existingModifierData && existingModifierData.length > 0) {
                existingModifierData.map((item, key) => {
                    updatedModifierData.push(item);
                });
            }
            updatedModifierData.push(modifiers);
            dispatch({
                type: ADDITEM_MODIFIER_SUCCESS,
            });
            await dispatch(change('AddItemForm', 'modifiers', updatedModifierData));
            await dispatch(setLoaderComplete('updateLoading'));

        } catch (error) {
            dispatch({
                type: ADDITEM_MODIFIER_ERROR,
            });
            await dispatch(setLoaderComplete('updateLoading'));

        }
    }
}