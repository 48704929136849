import React, { Component } from 'react';
import { connect } from 'react-redux';
import Stripe from './Stripe';

class PayoutConfirm extends Component {
  
  render() {
    const { previousPage } = this.props;

    return (
      <Stripe
        previousPage={previousPage}
      />
    );

  }
};

const mapState = (state) => ({

});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(PayoutConfirm);