import gql from 'graphql-tag';
import {
  SET_DEFAULT_PAYOUT_START,
  SET_DEFAULT_PAYOUT_SUCCESS,
  SET_DEFAULT_PAYOUT_ERROR,
} from '../../../constants';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { toastr } from 'react-redux-toastr';
import { getPayouts } from './getPayouts';

export function setDefaultPayout(id) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: SET_DEFAULT_PAYOUT_START,
    });

    dispatch(setLoaderStart('payoutDefault'));

    try {

      let mutation = gql`
        mutation setDefaultPayout($id: Int!){
          setDefaultPayout(id: $id) {
              status
          }
        }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: {
          id
        }
      });

      if (data && data.setDefaultPayout) {

        await dispatch({
          type: SET_DEFAULT_PAYOUT_SUCCESS
        });

        await dispatch(getPayouts());
      }

      toastr.success('Success', 'Default payment method has been set.');

      await dispatch(setLoaderComplete('payoutDefault'));

    } catch (error) {
      dispatch({
        type: SET_DEFAULT_PAYOUT_ERROR
      });

      toastr.error('Oops!', 'Something went wrong. Please try again.');

      dispatch(setLoaderComplete('payoutDefault'));
      return false;
    };

    return true;
  };
};
