import React from 'react';
import Layout from '../../../components/Shop/RestaurantLayout/Layout/Layout';
import NotFound from '../../not-found/NotFound';
import ChangePasswordUsingToken from './ChangePasswordUsingToken';

const title = 'Reset Password';

import messages from '../../../locale/messages';

function action({ store, intl, params, query }) {
  const title = intl.formatMessage(messages.changePassword);
    
  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;
  if (isAuthenticated) return { redirect: '/' };

  if (!query || !query.token && !query.email) {
    return {
      title,
      component: <Layout><NotFound title='Page Not Found' /></Layout>,
      status: 404,
    };
  }

  return {
    title,
    component: <Layout><ChangePasswordUsingToken email={query.email} token={query.token} /></Layout>,
  };
}

export default action;