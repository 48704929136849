import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import { closeDeclineReasonModal } from '../../siteadmin/modalActions';
import getOrderDetailsQuery from '../../../routes/shop/orderDetails/getOrders.graphql';
import { getPendingOrders } from './getPendingOrders';

export default function updateOrderStatus(id, bookingStatus, currentPage, reason, isOrderDetailPage, orderModal) {
    return async (dispatch, getState, { client }) => {
        try {
            let refetchQueries = [];
            let mutation = gql`
                mutation updateOrderStatus($id: Int!, $bookingStatus: String!, $notes: String, $orderModal: Boolean) {
                    updateOrderStatus (id: $id, bookingStatus: $bookingStatus, notes: $notes, orderModal:$orderModal) {
                        status
                        errorMessage
                    }
                }
            `;
            
            if (isOrderDetailPage) {
                refetchQueries = [{
                    query: getOrderDetailsQuery,
                    variables: { id }
                }];
            }
            
            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    bookingStatus,
                    notes: reason,
                    orderModal
                },
                refetchQueries
            });
            if (orderModal) await dispatch(getPendingOrders());
            if(data && data.updateOrderStatus && data.updateOrderStatus.status === 200 ){
                toastr.success('Success', 'The Order status has been updated successfully!');
                dispatch(closeDeclineReasonModal());
            } else {
                toastr.error('Error', data && data.updateOrderStatus && data.updateOrderStatus.errorMessage );
                
            }
  
        } catch(error) {
            toastr.error('Error', error);
            
        }
    }
};