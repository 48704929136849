import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { injectIntl } from 'react-intl';
// style
import s from './AdminDashboard.css';
// helper
import messages from '../../../locale/messages';
// components
import AdminDashboardUserCard from './AdminDashboardUserCard';

export class AdminDashboard extends Component {
    render() {
        const { formatMessage } = this.props.intl;
        const { orders, earnings, users, drivers, shops, toCurrency } = this.props;
    
        return (
            <div>
                <Container fluid>
                    <Row>
                        <AdminDashboardUserCard
                            data={users}
                            heading={formatMessage(messages.shoppers)}
                            link={'/siteadmin/users'}
                        />
                        <AdminDashboardUserCard
                            data={shops}
                            heading={formatMessage(messages.vendors)}
                            link={'/siteadmin/shop/list'}
                        />
                        <AdminDashboardUserCard
                            data={drivers}
                            heading={formatMessage(messages.drivers)}
                            link={'/siteadmin/drivers'}
                        />
                        <AdminDashboardUserCard
                            data={orders}
                            heading={formatMessage(messages.bookings)}
                            link={'/siteadmin/bookings'}
                        />
                        <AdminDashboardUserCard
                            data={earnings}
                            heading={formatMessage(messages.earnings)}
                            link={'/siteadmin/admin-earnings'}
                            displayAmount={true}
                            largeBox={true}
                            toCurrency={toCurrency}
                        />
                    </Row>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    toCurrency: state.siteSettings && state.siteSettings.data && state.siteSettings.data.currency
});

const mapDispatchToProps = {};

export default injectIntl(withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)));