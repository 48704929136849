import React, { Component } from 'react';
import EditCategoryForm from '../../../components/SiteAdmin/Category/EditCategoryForm';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getCategory from './getCategory.graphql';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EditCategory.css';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';


export class EditCategory extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired
    }
    static defaultProps = {
        data: {
            loading: true
        }
    }
    render() {
        const {title, data: { loading, getCategory }, data, id } = this.props;

        let initialValues = {};

        if (!loading && getCategory && getCategory.category) {
            initialValues = {
                id: getCategory.category.id,
                categoryName: getCategory.category.categoryName,
                categoryImage: getCategory.category.categoryImage,
                isActive: getCategory.category.isActive,
                description: getCategory.category.description
            }
        }
        if (loading) {
            return <div><Loader type={"page"} show={loading}></Loader></div>
        } else {
            return (
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.heading}>
                        <FormattedMessage {...messages.EditCuisine} />
                        </div>
                        <div>
                            <EditCategoryForm initialValues={initialValues} />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default compose(withStyles(s), graphql(getCategory, {
    options: (props) => ({
        variables: {
            id: props.id
        },
        fetchPolicy: 'network-only',
        ssr: true
    })
}))(EditCategory)
