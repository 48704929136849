import React from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from './Users.css';

import getAllUsersQuery from './getAllUsers.graphql';

import Loader from '../../../components/Common/Loader/Loader';
import UsersList from '../../../components/SiteAdmin/Users/UsersList';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';


class Users extends React.Component {
  static defaultProps = {
    usersDetails: {
      loading: true
    }
  }

  render() {
    const {usersDetails, usersDetails: { loading } } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.heading}>
            <FormattedMessage {...messages.manageAEaterHeading} />
            </div>
            <div className={s.paddingRoutesSection}>
              {
                !loading && <UsersList usersDetails={usersDetails} />
              }
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllUsersQuery, {
    name: 'usersDetails',
    options: {
      variables: {
        currentPage: 1
      },
      fetchPolicy: 'network-only'
    }
  }))(Users);
