import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import Switch from 'react-switch';
import cx from 'classnames';
import itemAvailable from '../../../actions/shop/menu/itemAvailable';

export const uncheckedIcon = (
    <svg viewBox="0 0 52 52" fill="#000000" fillOpacity="0"
        stroke="#000000" strokeWidth="4"
        role="presentation" strokeLinecap="round" strokeLinejoin="round"
        style={{ position: "absolute", top: 1, height: '33px', width: '33px', display: 'block', transform: 'scale(1.5)' }}>
        <path d="m19.1 19.1 l14 14 m 0 -14 l -14 14"></path>
    </svg>
);

export const checkedIcon = (
    <svg viewBox="0 0 52 52" fill="#000000" fillOpacity="0"
        stroke="#000000" strokeWidth="4"
        role="presentation" strokeLinecap="round" strokeLinejoin="round"
        style={{ position: "absolute", top: 1, height: '33px', width: '33px', display: 'block', transform: 'scale(1.5)' }}>
        <path d="m19.1 25.2 4.7 6.2 12.1-11.2"></path>
    </svg>
);
class SwitchButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static defaultProps = {
        checked: false,
        checkedValue: true,
        unCheckedValue: false,
        offColor: '#dce0e0',
        onColor: '#9D7A62',
        checkedIcon: checkedIcon,
        uncheckedIcon: uncheckedIcon,
        height: 34,
        width: 58,
        boxShadow: 'none',
        activeBoxShadow: '0px 0px 2px 3px #9D7A62',
        isPersonalize: false
    };

    componentDidMount() {
        const { value } = this.props;

        this.setState({
            checked: value == '1' ? true : false
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { value } = nextProps;

        this.setState({
            checked: value == '1' ? true : false
        });

    }

    async handleChange(e) {
        const { handleSwitch, itemAvailable, component } = this.props;
        if (component == 'HeaderAfterLogin') {
            if (e === true) { // Checking items availability
                let { action } = await itemAvailable()

                if (action == 'success') {
                    this.setState({ checked: e });
                    handleSwitch(e)
                }
            } else {
                this.setState({ checked: e });
                handleSwitch(e)
            }
        } else {
            this.setState({
                checked: e
            });
            handleSwitch(e)
        }
    }

    render() {
        const { checked } = this.state;
        const { switchOnLabel, switchOffLabel } = this.props;
        const { offColor, onColor, checkedIcon, uncheckedIcon, height, width, boxShadow } = this.props;

        return (
            <div>
                {String(checked) &&
                    <div>
                        <label className={cx('siwtchLapelTextHeader', 'siwtchLapelTextHeaderRTL')}>{checked ? switchOnLabel : switchOffLabel}</label>
                        <Switch
                            id="open-type"
                            checked={checked}
                            onChange={this.handleChange}
                            offColor={offColor}
                            onColor={onColor}
                            handleDiameter={30}
                            checkedIcon={checkedIcon}
                            uncheckedIcon={uncheckedIcon}
                            height={height}
                            width={width}
                            boxShadow={boxShadow}
                            className={'siwtchLapel'}
                        />
                    </div>
                }
            </div>
        )
    }
}

const mapState = (state) => ({});

const mapDispatch = {
    change,
    itemAvailable
};

export default connect(mapState, mapDispatch)(SwitchButton);