import gql from 'graphql-tag';
import {
    DELETE_DELIVERY_VEHICLE_SUCCESS,
    DELETE_DELIVERY_VEHICLE_START,
    DELETE_DELIVERY_VEHICLE_ERROR
} from '../../../constants';
import { toastr } from 'react-redux-toastr';
import { api } from '../../../config';

export default function deleteDeliveryVehicle(id, fileName) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: DELETE_DELIVERY_VEHICLE_START
        });

        try {
            let errorMessage;


            let mutation = gql`
            mutation($id: Int) {
                deleteDeliveryVehicle(id: $id) {
                    status
                    errorMessage
                }
            }
            `;

            const { data } = await client.mutate({
                mutation,
                variables : {
                    id
                }
            });
            
            if (data && data.deleteDeliveryVehicle && data.deleteDeliveryVehicle.status === 200) {
                dispatch({
                    type: DELETE_DELIVERY_VEHICLE_SUCCESS
                });

                toastr.success('Success', `The vehicle has been deleted.`);
                
                if (fileName) {
                    removeDeliveryVehicleImage(fileName); // Remove image from the directory
                }

                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: DELETE_DELIVERY_VEHICLE_ERROR
                });

                errorMessage = (data && data.deleteDeliveryVehicle && data.deleteDeliveryVehicle.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
                return {
                    status: 400
                };
            }
        } catch(err) {
            toastr.error('Error!', err);
            dispatch({
                type: DELETE_DELIVERY_VEHICLE_ERROR
            });
            return {
                status: 400
            };
        }

    }
};

async function removeDeliveryVehicleImage(fileName) {
    try {
      const url = api.apiEndpoint + "/deleteDeliveryVehicleImage";
      const resp = await fetch(url, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName }),
      });
  
      const { status } = await resp.json();
  
      if (status) {
        return { 
          status
        };
    }
  
    } catch (err) {
      console.log(err);
    }
  }