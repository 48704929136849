exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AQRSO {\n    margin: 0 0 0 auto;\n}\n.YBQwn {\n    display: grid;\n    grid-template-columns: repeat(2,1fr);\n}\n._2Cfgw {\n    position: relative;\n    top: -15px;\n    height: 25px;\n    display: inline-block !important;\n    min-width: 25px;\n    padding: 0 3px !important;\n    border-radius: 50% !important;\n    font-weight: 700 !important;\n    font-size: 12px !important;\n    line-height: 27px !important;\n    color: #fff;\n    border: 1px solid #9D7A62;\n    background: #9D7A62;\n}\n@media screen and (max-width: 1200px) {\n    .YBQwn {\n        grid-template-columns: repeat(1,1fr);\n    }\n    .AQRSO {\n        margin: 0;\n    }\n}", ""]);

// exports
exports.locals = {
	"searchInput": "AQRSO",
	"titleSection": "YBQwn",
	"badge": "_2Cfgw"
};