import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EditFoodItem.css';
import AddItemForm from '../../../components/Shop/ManageMenu/ItemList/AddItemForm/AddItemForm';


class EditFoodItem extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div>
        <AddItemForm />
      </div>
    );
  }
}

export default withStyles(s)(EditFoodItem);
