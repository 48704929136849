import {
  ADD_UPDATE_DELIVERY_VEHICLE_START,
  ADD_UPDATE_DELIVERY_VEHICLE_SUCCESS,
  ADD_UPDATE_DELIVERY_VEHICLE_ERROR
} from '../../../constants';

import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';

const addMutation = gql`
mutation addDeliveryVehicle(
  $vehicleName: String,
  $vehicleImage: String,
  $description: String,
  $isActive: Boolean
) {
  addDeliveryVehicle(
      vehicleName: $vehicleName,
      vehicleImage: $vehicleImage,
      description: $description,
      isActive: $isActive
  ) {
      status
      errorMessage
  }
}
`;

const updateMutation = gql`
mutation(
  $id: Int, 
  $vehicleName: String,
  $description: String,
  $isActive: Boolean
) {
  updateDeliveryVehicle(
      id: $id,
      vehicleName: $vehicleName,
      description: $description,
      isActive: $isActive
  ){
      status
      errorMessage
  }
}
`;

export function addUpdateDeliveryVehicle(
  id, 
  vehicleName,
  vehicleImage,
  description,
  isActive
) {
  return async (dispatch, getState, { client }) => {
    let successMessage;
    
    try {
      dispatch({
        type: ADD_UPDATE_DELIVERY_VEHICLE_START,
        payload: {
          pricingLoading: true
        }
      });

      dispatch(setLoaderStart('DeliveryVehicleForm'));

      let mutation = (id) ? updateMutation : addMutation;
      
      let variables = {
        id, 
        vehicleName,
        vehicleImage,
        description,
        isActive: (isActive == true || isActive == 'true') ? 1 : 0
      };
      
      const { data } = await client.mutate({
        mutation, 
        variables
      });

      const response = id ? data.updateDeliveryVehicle : data.addDeliveryVehicle;



      if (response && response.status === 200) {
        successMessage = `The vehicle type has been added successfully.`;
        if (id) { 
          successMessage = `The vehicle type has been updated successfully.`; 
        }

        history.push('/siteadmin/vehicle-type/list');

        toastr.success('Success', successMessage);

        await dispatch({
          type: ADD_UPDATE_DELIVERY_VEHICLE_SUCCESS,
          payload: {
            pricingLoading: false
          }
        });

        dispatch(setLoaderComplete('DeliveryVehicleForm'));

        return await {
          status: 200
        };
      } else {
        toastr.error('Error!', response && response.errorMessage);

        await dispatch({
          type: ADD_UPDATE_DELIVERY_VEHICLE_ERROR,
          payload: {
            pricingLoading: false,
            error: response && response.errorMessage
          }
        });

        dispatch(setLoaderComplete('DeliveryVehicleForm'));

        return await {
          status: 400
        };
      }
    } catch (error) {
      toastr.error('Error!', "Something went wrong! " + error);

      await dispatch({
        type: ADD_UPDATE_DELIVERY_VEHICLE_ERROR,
        payload: {
          pricingLoading: false,
          error: "Something went wrong! " + error
        }
      });

      dispatch(setLoaderComplete('DeliveryVehicleForm'));

      return await {
        status: 400
      };
    }
  }
}