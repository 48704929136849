import {
    SHOP_SIGNUP_ERROR,
    SHOP_SIGNUP_START,
    SHOP_SIGNUP_SUCCESS
} from '../../../constants';

import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { setRuntimeVariable } from '../../runtime';
import { loadAccount } from '../../shop/userAccount/userAccount';
import { getAllShopModifier } from '../modifier/getAllShopModifier';

export function shopSignup(values) {
    return async (dispatch, getState, { client }) => {
        let status, errorMessage = 'Oops! something went wrong! Please try again.';

        try {
            dispatch({
                type: SHOP_SIGNUP_START,
                payload: {
                    restaurantSignupLoading: true
                }
            });

            dispatch(setLoaderStart('RestaurantSignup'));

            const mutation = gql`
            mutation (
              $shopName: String!
              $firstName: String!,
              $lastName: String!,
              $email: String!,
              $password: String!,
              $phoneDialCode: String!,
              $phoneNumber: String!,
              $phoneCountryCode: String!,
              $address: String!,
                $lat: Float!,
                $lng: Float!,
                $city: String,
                $state:String,
                $zipcode: String,
              $country: String,
              $categoryType: Int!
          ) {
              createShopUser (
                  shopName: $shopName,
                  firstName: $firstName,
                  lastName: $lastName,
                  email: $email,
                  password: $password,
                  phoneDialCode: $phoneDialCode,
                  phoneNumber: $phoneNumber,
                  phoneCountryCode: $phoneCountryCode,
                  address: $address,
                  lat: $lat,
                  lng: $lng,
                  city: $city,
                  state: $state,
                  zipcode: $zipcode,
                  country: $country,
                  categoryType: $categoryType
              ) {
                  status
                  errorMessage
                  result {
                      id
                      email
                  }
                       
              }
          }`;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    shopName: values && values.shopName,
                    firstName: values && values.firstName,
                    lastName: values && values.lastName,
                    email: values && values.email,
                    password: values && values.password,
                    phoneDialCode: values && values.phoneDialCode,
                    phoneNumber: values && values.phoneNumber,
                    phoneCountryCode: values && values.phoneCountryCode,
                    address: values && values.address,
                    lat: values && values.lat,
                    lng: values && values.lng,
                    city: values && values.city,
                    state: values && values.state,
                    zipcode: values && values.zipcode,
                    country: values && values.country,
                    categoryType: values && values.categoryType
                }
            });

            if (data && data.createShopUser && data.createShopUser.status == 200) {
                toastr.success('Success', `You have successfully signed up.`);
                dispatch({
                    type: SHOP_SIGNUP_SUCCESS,
                    payload: {
                        restaurantSignupLoading: false
                    }
                });
                dispatch(setRuntimeVariable({
                    name: 'isRestaurantAuthenticated',
                    value: true,
                }));
                dispatch(getAllShopModifier());
                dispatch(setLoaderComplete('RestaurantSignup'));
                dispatch(loadAccount());
                history.push('/shop/dashboard');
            } else {
                errorMessage = data && data.createShopUser && data.createShopUser.errorMessage
                toastr.error('Error!', errorMessage);

                dispatch({
                    type: SHOP_SIGNUP_ERROR,
                    payload: {
                        restaurantSignupLoading: false,
                        error: errorMessage
                    }
                });
                dispatch(setLoaderComplete('RestaurantSignup'));
            }
        } catch (error) {
            errorMessage = "Something went wrong! " + error;

            toastr.error('Error!', errorMessage);

            dispatch({
                type: SHOP_SIGNUP_ERROR,
                payload: {
                    restaurantSignupLoading: false,
                    error: errorMessage
                }
            });
            dispatch(setLoaderComplete('RestaurantSignup'));
        }
    }
};