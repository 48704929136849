import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './RestaurantSlider.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
	Button, Row, Col, Container
} from 'react-bootstrap';
import cx from 'classnames';

//Locale
import messages from '../../../../locale/messages';
import { api, partnerHomepageUploadDir } from '../../../../config'
import Swiper from 'react-id-swiper';
import Loader from '../../../Common/Loader';
import { isRTL } from '../../../../helpers/formatLocale';
import nextArrow from '../../../../../public/Icons/right.png';
import prevArrow from '../../../../../public/Icons/left.png';

const nextArrowStyle = {
	position: 'absolute',
	right: '-60px',
	background: '#fff',
	color: '#00B0CD',
	zIndex: '5',
	width: '50px',
	height: '50px',
	top: '30%',
	fontSize: '40px',
	cursor: 'pointer',
	borderRadius: '50%',
	textAlign: 'center',
	boxShadow: '0px 0px 9px 3px #eeebeb',
	lineHeight: '47px'
};

const prevArrowStyle = {
	position: 'absolute',
	left: '-95px',
	background: '#fff',
	color: '#00B0CD',
	zIndex: '5',
	width: '50px',
	height: '50px',
	top: '30%',
	fontSize: '40px',
	cursor: 'pointer',
	borderRadius: '50%',
	textAlign: 'center',
	boxShadow: '0px 0px 9px 3px #eeebeb',
	lineHeight: '47px'
};

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			onClick={onClick}
			style={nextArrowStyle}
		>
			<img src={nextArrow} />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={prevArrowStyle}
			onClick={onClick}
		>
			<img src={prevArrow} />
		</div>
	);
}

class RestaurantSlider extends React.Component {

	static defaultProps = {
		arrow: true
	};

	constructor(props) {
		super(props);
		this.state = {
			load: false
		};
		this.swiper = null;
		this.goNext = this.goNext.bind(this);
		this.goPrev = this.goPrev.bind(this);
		this.renderSliderItem = this.renderSliderItem.bind(this);
	}


	componentDidMount() {
		this.setState({
			load: true
		});
	}

	componentDidUpdate(prevProps) {
		const { locale } = this.props.intl;
		const { locale: prevLocale } = prevProps.intl;
		if (locale !== prevLocale) {
			this.setState({
				load: false
			});
			clearTimeout(this.loadSync);
			this.loadSync = null;
			this.loadSync = setTimeout(() => {
				this.setState({
					load: true
				})
			}, 450);
		}
	}

	goNext() {
		this.swiper.slideNext();
	}

	goPrev() {
		this.swiper.slidePrev();
	}


	renderSliderItem(data, index) {
		return (
			<div className={s.displayGrid}>
				<div className={s.svgImage}>
					<img src={api.apiEndpoint + partnerHomepageUploadDir + data['sliderImage' + index]} alt={data['sliderTitle' + index]} />
				</div>
				<div className={cx(s.content, 'contentAnimation')}>

					<h2>{data['sliderTitle' + index]}</h2>
					<p>{data['sliderContent' + index]}</p>
				</div>
			</div>
		);
	}

	render() {

		const { homepage, intl: { locale }, arrow } = this.props;
		const { load } = this.state;
		const params = {
			slidesPerView: 1,
			loop: true,
			freeMode: 'true',
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			breakpoints: {
				768: {
					slidesPerView: 1,
				},
				640: {
					slidesPerView: 1,
					centeredSlides: true,
					loop: true,
				}
			}
		};
		let th = this;
		if (arrow === true) {
			params.loop = true
		} else {
			params.loop = true
		}
		let sliders = [1, 2, 3];

		return (
			<div className={cx(s.root)}>
				<Container fluid>
					<Row>
						<Col lg={12} md={12} sm={12} xs={12} className={s.noPadding}>
							<div className={cx(s.minHeight, 'listYourPlace')}>

								{
									!load && <div>
										<Loader type="text" />
									</div>
								}
								{
									load && <div className={s.positionRelative}><Swiper {...params} ref={node => th.swiper = node !== null ? node.swiper : null} rtl={isRTL(locale)} className={cx('row', s.noMargin)} >

										{
											sliders && sliders.map((item, key) => {
												return (<div key={key}>{this.renderSliderItem(homepage, item)}</div>);
											})
										}
									</Swiper>
										{arrow && arrow == true && <div className={cx(s.arrowPosition, 'arrowPositionRestaurantRTL')}>
											<SamplePrevArrow className={cx('hidden-xs hidden-sm', s.displayInline, s.displayInlineLeft)} onClick={this.goPrev} />
											<SampleNextArrow className={cx('hidden-xs hidden-sm', s.displayInline, s.displayInlineRight)} onClick={this.goNext} />
										</div>
										}
									</div>}
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default injectIntl(withStyles(s)(RestaurantSlider));