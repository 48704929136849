import React, { Component } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from '!isomorphic-style-loader!css-loader!./filepicker.css';
import { siteUrl } from '../../../config';
import cx from 'classnames';
import { toastr } from 'react-redux-toastr';
import { change } from 'redux-form';
import updateFavIcon from '../../../actions/siteadmin/updateFavIcon';
export class FaviconDropzone extends Component {

	constructor(props) {
		super(props);
		this.error = this.error.bind(this);
		this.success = this.success.bind(this);
		this.addedfile = this.addedfile.bind(this);
		this.dropzone = null;
	}

	componentDidUpdate() {
		const isBrowser = typeof window !== 'undefined';
		const isDocument = typeof document !== undefined;
		if (isBrowser && isDocument) {
			document.querySelector(".dz-hidden-input").style.visibility = 'visible';
			document.querySelector(".dz-hidden-input").style.opacity = '0';
			document.querySelector(".dz-hidden-input").style.height = '100%';
			document.querySelector(".dz-hidden-input").style.width = '100%';
			document.querySelector(".dz-hidden-input").style.cursor = 'pointer';
		}
	}

	async success(file, fromServer) {
		const { id, change, updateFavIcon } = this.props;
		const fileName = fromServer.fileName;
		await change("SiteSettingsForm", 'favicon', fileName);
		await updateFavIcon(fileName);
	}

	async error(file, message) {
		toastr.error('Error!', message);
	}

	addedfile(file, fromServer) {
		const { maxUploadSize } = this.props;
		if (file && file.size > (1024 * 1024 * parseInt(maxUploadSize))) {
			this.dropzone.removeFile(file);
			return;
		}
	}

	render() {
		const { defaultMessage, className, subTextClass, subText, maxUploadSize } = this.props;

		const djsConfig = {
			dictDefaultMessage: '',
			addRemoveLinks: false,
			uploadMultiple: false,
			maxFilesize: parseInt(maxUploadSize),
			acceptedFiles: 'image/png',
			dictMaxFilesExceeded: 'Remove the existing image and try upload again',
			previewsContainer: false,
			hiddenInputContainer: '.dzInputContainerLogo',
			timeout: 300000
			// maxFiles: 1
		};

		var componentConfig = {
			iconFiletypes: ['.png'],
			multiple: false,
			showFiletypeIcon: false,
			postUrl: siteUrl + '/uploadFavicon'
		};

		const eventHandlers = {
			init: dz => this.dropzone = dz,
			success: this.success,
			addedfile: this.addedfile,
			error: this.error,
		};


		return (
			<div className={cx('listPhotoContainer')}>
				<div className={cx('dzInputContainerLogo', 'dzInputContainerLogoOpacity')}>
					<div className={className}>
						<DropzoneComponent
							config={componentConfig}
							eventHandlers={eventHandlers}
							djsConfig={djsConfig}
						>
							{defaultMessage}
						</DropzoneComponent>
					</div>
				</div>
				<p className={cx(subTextClass, 'droupText')}>
					{subText}: {maxUploadSize}MB
				</p>
			</div>
		)
	}
}

const mapState = state => ({
	maxUploadSize: state.siteSettings.data.maxUploadSize
});

const mapDispatch = {
	change,
	updateFavIcon
};

export default withStyles(s)(connect(mapState, mapDispatch)(FaviconDropzone));
