
import React from 'react';
import Layout from '../../../components/Shop/RestaurantLayout/Layout/Layout';
import Signup from './Signup';
import messages from '../../../locale/messages';

function action({ store, params, intl }) {
  const title = intl.formatMessage(messages.signup);

  return {
    title,
    component: (
      <Layout>
        <Signup title={title} />
      </Layout>
    ),
  };
};

export default action;
