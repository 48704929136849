import { toastr } from 'react-redux-toastr';
import {
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  ADD_PAYOUT_ERROR,
} from '../../../constants';
import { processStripePayment } from '../../../core/payment/stripe/processStripePayment';
import { setLoaderComplete, setLoaderStart } from '../../loader/loader';
import checkPaymentMethod from '../../../components/Shop/Payout/PayoutForm/getPaymentMethods.graphql';

export function addPayout(
  payEmail,
  address1,
  address2,
  city,
  state,
  country,
  zipcode,
  currency,
  firstname,
  lastname,
  accountNumber,
  routingNumber,
  ssn4Digits,
  businessType,
  bankCode,
  branchCode,
  bankName,
  branchName,
  accountOwnerName,
  transitNumber,
  institutionNumber,
  bsbNumber,
  sortCode) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: ADD_PAYOUT_START
    });


    try {

      const { data } = await client.query({
        query: checkPaymentMethod,
        fetchPolicy: 'network-only'
      });

      if (data && data.getPaymentMethods && data.getPaymentMethods.length > 0) {
        if (!data.getPaymentMethods[0].isEnable) {
          dispatch({
            type: ADD_PAYOUT_ERROR,
          });
          toastr.warning('Error!', 'This feature is currently not available. Please contact admin for more information.');
          return;
        }
      };

      dispatch(setLoaderStart('AddPayoutForm'));

      let userDetails = {
        payEmail
      };

      let bankDetails = {
        payEmail,
        address1,
        address2,
        city,
        state,
        country,
        zipcode,
        currency,
        firstname,
        lastname,
        accountNumber,
        routingNumber,
        ssn4Digits,
        businessType,
        bankCode,
        branchCode,
        bankName,
        branchName,
        accountOwnerName,
        transitNumber,
        institutionNumber,
        bsbNumber,
        sortCode
      };

      const { status, errorMessage, accountId } = await processStripePayment(
        'addPayout',
        userDetails,
        bankDetails
      );

      if (status == '200' && accountId) {

        dispatch({
          type: ADD_PAYOUT_SUCCESS
        });

        dispatch(setLoaderComplete('AddPayoutForm'));

      } else {

        toastr.error('Failed!', errorMessage);

        dispatch({
          type: ADD_PAYOUT_ERROR
        });

        dispatch(setLoaderComplete('AddPayoutForm'));

      }

    } catch (error) {

      dispatch({
        type: ADD_PAYOUT_ERROR
      });

      dispatch(setLoaderComplete('AddPayoutForm'));

    }
  };
};