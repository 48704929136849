import {
	SHOP_MODIFIER_UPDATE_START,
	SHOP_MODIFIER_UPDATE_SUCCESS,
	SHOP_MODIFIER_UPDATE_ERROR
} from '../../../constants/index';
import { toastr } from 'react-redux-toastr';
import { getAllShopModifier } from './getAllShopModifier';
import getAllModifierQuery from '../../../routes/shop/modifierGroups/getAllModifier.graphql';
import updateModifierStatusMutation from './updateModifierStatus.graphql';

export default function updateModifierStatus(id, status, currentPage, searchList) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: SHOP_MODIFIER_UPDATE_START,
			payload: {
				updateLoading: true
			}
		});

		try {
			let errorMessage;
			const { data: { updateModifierStatus } } = await client.mutate({
				mutation: updateModifierStatusMutation,
				variables: {
					id,
					status: status == 'false' ? 0 : 1
				},
				refetchQueries: [{ query: getAllModifierQuery, variables: { currentPage, searchList } }]
			});
			if (updateModifierStatus && updateModifierStatus.status === 200) {
				dispatch({
					type: SHOP_MODIFIER_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				toastr.success('Success', `The Modifier status has been updated successfully.`);
			} else {
				dispatch({
					type: SHOP_MODIFIER_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});

				errorMessage = (updateModifierStatus && updateModifierStatus.errorMessage) || "Oops! Something went wrong. Please try again.";
				toastr.error('Error!', errorMessage);
			}
			dispatch(getAllShopModifier());

		} catch (err) {
			toastr.error('Error!', err);
			dispatch({
				type: SHOP_MODIFIER_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};
