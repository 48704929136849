import React from 'react';
import {
  Modal,
} from 'react-bootstrap';
import { closeItemModal } from '../../../../../actions/siteadmin/modalActions';
import { connect } from 'react-redux';
import messages from '../../../../../locale/messages';
import ModifierGroupForm from '../../../ModifierGroups/ModifierGroupForm/ModifierGroupForm';
import { FormattedMessage, injectIntl } from 'react-intl';

class AddModifierModal extends React.Component {
  static defaultProps = {
    setShow: false
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      setShow: false
    };

  }

  componentDidMount() {
    const { isItemModalOpen } = this.props;
    if (isItemModalOpen === true) {
      this.setState({ setShow: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isItemModalOpen } = nextProps;

    if (isItemModalOpen === true) {
      this.setState({ setShow: true });
    } else {
      this.setState({ setShow: false });
    }
  }

  render() {
    const { closeItemModal, menuId, subMenuId, from } = this.props;
    const { setShow } = this.state;
    let initialValues = {
      menuId,
      subMenuId,
      modifierItems: [{
        modifierItemName: null,
        modifierItemPrice: null
      }],
      isActive: "true",
      modifierType: "1"
    }
    return (
      <div>
        <Modal size={'xl'} show={setShow} onHide={closeItemModal} className={'listItemModal', 'addModifierGroupModal'}>
          <Modal.Header closeButton>
            {/* <h4><FormattedMessage {...messages.newModifierGroupsModal} /></h4> */}
          </Modal.Header>
          <Modal.Body>
            <ModifierGroupForm from={from} initialValues={initialValues} />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapState = (state) => ({
  isItemModalOpen: state.modalStatus.isItemModalOpen,
  menuId: state.modalStatus.menuId,
  subMenuId: state.modalStatus.subMenuId,
});

const mapDispatch = {
  closeItemModal,
};

export default injectIntl(connect(mapState, mapDispatch)(AddModifierModal));
