import React from 'react';
import Layout from '../../../components/Shop/RestaurantLayout/Layout/Layout';
import Login from './Login';
import messages from '../../../locale/messages';

function action({ store, intl }) {
  const title = intl.formatMessage(messages.login);
    
    // From Redux Store
    let isAuthenticated = store.getState().user.restaurant;

    if (isAuthenticated) {
        return { redirect: '/' };
    }

    return {
        title,
        component: <Layout><Login title={title}/></Layout>
    }
}

export default action;