import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import messages from '../../../../locale/messages';
import { change } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './DocumentForm.css';
import rs from '../../../restaurantCommon.css';
import {
    Col,
    Row,
    Form,
    Button,
    Container
} from 'react-bootstrap';
import validate from './validate';
import submit from './submit';
import Loader from '../../../Common/Loader/Loader';
import Dropzone from '../../../Shop/Dropzone/Dropzone';
import { api, restaurantDocumentUploadDir } from '../../../../config';
import NoLogo from '../../../../../public/RestaurantIcon/documentUpload.svg'
import closeIcon from '../../../../../public/RestaurantIcon/closeIconRes.png';
import Link from '../../../Link/Link';

class DocumentForm extends React.Component {

    constructor(props) {
        super(props);

        this.handleDropzone = this.handleDropzone.bind(this);
    }

    static defaultProps = {
        loading: false
    }



    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={s.errorMessage}>{error.defaultMessage}</span>}
            </Form.Group>
        )
    };

    renderDocument = ({ fields, meta: { touched, error } }) => {
        const { documentList } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div>
                {fields && fields.map((document, index) => {
                    let documentName = fields.get(index) && fields.get(index).fileName
                    let fieldLength = fields.length - 1;
                    return (
                        <div className={s.displayInlineBock}>
                            <div>
                                <Col lg={12} md={12} sm={12} xs={12} className={rs.space2}>
                                    <Form.Group className={s.formGroup}>
                                        <div className={cx(rs.profileImgSection)}>
                                            <label className={rs.space3}>{formatMessage(messages.documentImage)}</label>
                                            {
                                                documentName ?
                                                    <div className={rs.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + restaurantDocumentUploadDir + documentName})` }} />
                                                    :
                                                    <div className={rs.backgroundImg} style={{ backgroundImage: `url(${NoLogo})` }}></div>
                                            }
                                            <div className={'siteadminProfileBtn'}>
                                                <Dropzone
                                                    className={cx(s.btnSecondary, s.profileNoPadding)}
                                                    subTextClass={s.subText}
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    inputContainer={'.dzInputContainerRestaurantDocument'}
                                                    inputContainerClass={'dzInputContainerRestaurantDocument'}
                                                    url={'/uploadRestaurantDocument'}
                                                    fieldName={`${document}.fileName`}
                                                    handleDropzone={this.handleDropzone}
                                                />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                    <Form.Group className={s.formGroup}>
                                        <div>
                                            <Field
                                                name={`${document}.fileType`}
                                                type="text"
                                                placeholder={formatMessage(messages.documentName)}
                                                component={this.renderField}
                                                fieldClass={rs.formControlInputRestaurant}
                                                label={formatMessage(messages.documentName)}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                {index != 0 && <div className={s.removeSection}>
                                    <Button
                                        variant="primary"
                                        onClick={() => fields.remove(index)}
                                        className={s.removeBtnSection}
                                    >
                                        <img src={closeIcon} />
                                    </Button>
                                </div>}
                                {/* {  fieldLength == index && <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.space2, rs.alignRightText)}>
                                    <div>
                                        <Button
                                            variant="primary"
                                            className={cx(rs.button, rs.btnPrimaryBorder)}
                                            onClick={() => fields.push({ fileType: '', fileName: '' })}
                                        >
                                            {formatMessage(messages.add)}
                                        </Button>
                                    </div>
                                </Col>} */}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    componentWillMount() {
        // const { array, initialValues } = this.props

        // initialValues.documentList && initialValues.documentList.length > 0 ? '' : array.push('documentList', {})
    }

    handleDropzone(fileName, fieldName) {
        const { change } = this.props
        change(fieldName, fileName)
    }



    render() {
        const { loading, handleSubmit, submitting, initialValues } = this.props;
        const { formatMessage } = this.props.intl;
        let documentExist = initialValues && initialValues.documentList &&
            initialValues.documentList.length == '0' ? false : true;

        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col xl={10} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h1 className={s.titleTextAdmin}>{formatMessage(messages.restaurantDocuments)}</h1>
                                </Col>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12} className={s.noPadding}>
                                                <FieldArray
                                                    name="documentList"
                                                    rerenderOnEveryChange={true}
                                                    component={this.renderDocument}
                                                />
                                            </Col>
                                            {!documentExist && <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.space4)}>
                                                <span><FormattedMessage {...messages.noDocument} /></span>
                                            </Col>}
                                            <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.alignRightText, 'textAlignLeftRTL')}>
                                                <Form.Group className={s.space2}>
                                                    <Link to={"/siteadmin/shop/list"} className={cx(s.btnSecondary, s.linkBtnInline)}>{formatMessage(messages.goBack)}</Link>
                                                    {documentExist && <div className={s.displayInlineBlock}>
                                                        <Loader
                                                            type={"button"}
                                                            label={formatMessage(messages.update)}
                                                            show={loading}
                                                            buttonType={'submit'}
                                                            className={cx(s.button, s.btnPrimary, s.backBtn, 'rtlBtnLoader')}
                                                            disabled={submitting || loading}
                                                            isSuffix={true}
                                                        />
                                                    </div>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

DocumentForm = reduxForm({
    form: 'DocumentForm',
    onSubmit: submit,
    validate,
    enableReinitialize: true
})(DocumentForm);

const selector = formValueSelector('DocumentForm');

const mapState = (state) => ({
    documentList: selector(state, 'documentList')
});

const mapDispatch = {
    change
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(DocumentForm)));