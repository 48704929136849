import React, { Component } from 'react';
import s from './DriverSettingsForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
	Form,
	Col,
	Card,
	Row,
	FormGroup,
	FormControl,
	Container
} from 'react-bootstrap';
import Link from '../../Link/Link';
import messages from '../../../locale/messages';
import validate from './validate';
import Dropzone from './Dropzone.js'
import Loader from '../../Common/Loader/Loader';
import { api, homepageUploadDir } from '../../../config';

export class DriverSettingsForm extends Component {

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
				{touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<div>
				<FormGroup className={s.space3}>
					<div>
						<label className={s.labelText} >{label}</label>
					</div>
					<div>
						<FormControl
							{...input}
							className={className}
							placeholder={label}
							as="textarea"
							rows="4"
						>
							{children}
						</FormControl>
						{touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
					</div>
				</FormGroup>
			</div>
		);
	}

	render() {
		const { handleSubmit, safetyGridImage4, safetyGridImage5, safetyGridImage6, loading, submitting } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<div className={cx(s.space5, s.spaceTop5)}>
				<Container fluid>
					<Row>
						<Col xl={10} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
							<div className={s.blackCenterSection}>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
									<Col lg={12} md={12} sm={12} xs={12}>
										<h1 className={s.titleTextAdmin}>{formatMessage(messages.signupSectionSettings)}</h1>
									</Col>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Row>
											<Col lg={4} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<label>{formatMessage(messages.signupImage3)}</label><br />
														{
															safetyGridImage6 &&
															<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir}medium_${safetyGridImage6})` }} />
														}
														<div>
															<Dropzone
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																fieldName={'safetyGridImage6'}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																inputContainer={'.dzInputContainerSafetyImage3'}
																inputContainerClass={'dzInputContainerSafetyImage3'}
															/>
														</div>
													</div>
												</Form.Group>
											</Col>
											<Col lg={4} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<label>{formatMessage(messages.safetyImage1)}</label><br />
														{
															safetyGridImage4 &&
															<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir}medium_${safetyGridImage4})` }} />
														}
														<div>
															<Dropzone
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																fieldName={'safetyGridImage4'}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																inputContainer={'.dzInputContainerSafetyImage1'}
																inputContainerClass={'dzInputContainerSafetyImage1'}
															/>
														</div>
													</div>
												</Form.Group>
											</Col>
											<Col lg={4} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<label>{formatMessage(messages.safetyImage2)}</label><br />
														{
															safetyGridImage5 &&
															<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir}medium_${safetyGridImage5})` }} />
														}
														<div>
															<Dropzone
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																fieldName={'safetyGridImage5'}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																inputContainer={'.dzInputContainerSafetyImage2'}
																inputContainerClass={'dzInputContainerSafetyImage2'}
															/>
														</div>
													</div>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field name="safetyGridTitle2" type="text" component={this.renderField} label={formatMessage(messages.driverAppTitle)} />
													</div>
												</Form.Group>
											</Col>
										</Row>

										<Row>
											<Col lg={12} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field name="safetyGridContent2" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.driverAppContent)} />
													</div>
												</Form.Group>
											</Col>
										</Row>

										<Row>
											<Col lg={6} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field name="safetyGridLink3" type="text" component={this.renderField} label={formatMessage(messages.playStoreLink)} />
													</div>
												</Form.Group>
											</Col>
											<Col lg={6} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field name="safetyGridLink4" type="text" component={this.renderField} label={formatMessage(messages.appStoreLink)} />
													</div>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
												<Form.Group className={s.noMargin}>
													<div className={s.displayInlineBlock}>
														<Loader
															type={"button"}
															label={formatMessage(messages.submitButton)}
															show={loading}
															buttonType={'submit'}
															className={cx(s.button, s.btnPrimary)}
															disabled={submitting || loading}
															isSuffix={true}
														/>
													</div>
												</Form.Group>
											</Col>
										</Row>
									</Col>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

DriverSettingsForm = reduxForm({
	form: 'DriverSettingsForm',
	onSubmit: submit,
	validate
})(DriverSettingsForm);

const selector = formValueSelector('DriverSettingsForm')
const mapState = (state) => ({
	safetyGridImage4: selector(state, 'safetyGridImage4'),
	safetyGridImage5: selector(state, 'safetyGridImage5'),
	safetyGridImage6: selector(state, 'safetyGridImage6'),
	loading: state.loader.DriverSettingsForm
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(DriverSettingsForm)));