import React, { Component } from 'react';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import {
	Button,
	Form,
	Col,
	Card,
	Row,
	FormGroup,
	FormControl,
	Container
} from 'react-bootstrap';
// Component
import Loader from '../../Common/Loader';
import PlacesSuggest from '../PlacesSuggest';
import CustomCheckbox from '../../Common/CustomCheckbox';
import Dropzone from '../Dropzone';
import PhoneNumberVerification from './PhoneNumberVerification/PhoneNumberVerification';
// Helper
import messages from '../../../locale/messages';
import validate from './validate';
import submit from './submit';
import { api, restaurantImageUploadDir } from '../../../config';
// Images
import NoLogo from '../../../../public/RestaurantIcon/defaultRestaurant.svg';
import TrashIcon from '../../../../public/RestaurantIcon/dlty.png'
// css
import s from './EditProfileForm.css';
import rs from '../../restaurantCommon.css';

export class EditProfileForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			phoneCountryCode: '',
			phoneDialCode: '',
			isReadOnly: true,
			phoneStatus: '',
			defaultPreparationTime: 55
		};
	}

	static defaultProps = {
		loading: false
	};

	componentWillMount() {
		const { initialValues, phoneStatus, change } = this.props;
		const { defaultPreparationTime } = this.state;
		let preparationTime = initialValues && initialValues.shopEstimatedTime ? initialValues.shopEstimatedTime : defaultPreparationTime;

		change('isPhoneVerified', phoneStatus);
		this.setState({
			phoneCountryCode: initialValues && initialValues.phoneCountryCode,
			phoneDialCode: initialValues && initialValues.phoneDialCode,
			phoneStatus: phoneStatus,
			defaultPreparationTime: preparationTime
		});
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		const { phoneNumber, isPhoneVerified, isTakeAway, change } = newProps;
		if (!phoneNumber) this.setState({ isReadOnly: false, phoneStatus: false })
		this.setState({ isReadOnly: isPhoneVerified })
		if (!isTakeAway) change('shopPickupInstruction', '');
	}


	handleLocationData = (location) => {
		const { change } = this.props;

		let addressLabel = location && location.label;
		let addressPosition = location && location.location;
		let locationArray = addressLabel && addressLabel.split(',').slice(-3).reverse();

		change('address', addressLabel)
		change('city', locationArray && locationArray[2] && locationArray[2].trim());
		change('state', locationArray && locationArray[1] && locationArray[1].trim());
		change('country', locationArray && locationArray[0] && locationArray[0].trim());
		change('lat', addressPosition && addressPosition.lat);
		change('lng', addressPosition && addressPosition.lng);
	};

	handleCountryChange = (country) => {
		const { change } = this.props;
		this.setState({
			phoneCountryCode: country.countryCode,
			phoneDialCode: country.dialCode
		});
		change('phoneCountryCode', country.countryCode)
		change('phoneDialCode', country.dialCode)
	};

	handleDropzone = (fileName, fieldName) => {
		const { change } = this.props
		change(fieldName, fileName)
	}

	handleDefaultPreparationTime = (action) => {
		const { defaultPreparationTime } = this.state;
		const { change } = this.props;

		let preparationTime = action == 'add' ? defaultPreparationTime + 5 : defaultPreparationTime - 5;

		if (preparationTime <= 55 && preparationTime >= 5) {
			this.setState({ defaultPreparationTime: preparationTime })
			change('shopEstimatedTime', preparationTime)
		}
	};

	handleDeleteImage = () => {
		const { change } = this.props;
		change('picture', null)
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(s.formGroup, rs.displayGridPrifile, rs.alignItemCenter)}>
				<div>
					<Form.Label className={rs.restaurantLabelText}>{label}</Form.Label>
				</div>
				<div>
					<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} disabled={disabled} />
					{touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
				</div>
			</Form.Group>
		)
	};

	renderFieldPhoneNumber = ({ input, label, writeOnce, type, meta: { touched, error }, fieldClass, placeholder, className }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(s.formGroup, rs.displayGridPrifile, rs.alignItemCenter)}>
				<div>
					<label className={rs.restaurantLabelText}>{label}</label>
				</div>
				<div>
					<Form.Control {...input} readOnly={writeOnce} placeholder={placeholder} type={type} className={fieldClass} />
					{touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
				</div>
			</Form.Group>
		)
	};

	renderFormControlTextArea = ({ input, label, placeholder, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<div>
				<FormGroup className={cx(s.formGroup, rs.displayGridPrifile)}>
					<div>
						<label className={rs.restaurantLabelText}>{label}</label>
					</div>
					<div>
						<FormControl
							{...input}
							className={cx(s.inputNoRadius, className)}
							placeholder={placeholder ? placeholder : label}
							as="textarea"
							rows="4"
						>
							{children}
						</FormControl>
						{touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
					</div>
				</FormGroup>
			</div>
		);
	}

	checkboxGroup = ({ label, name, options, input, meta: { touched, error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<FormGroup className={cx(s.formGroup, rs.displayGridPrifile)}>
				<div>
					<label className={rs.restaurantLabelText}>{label}</label>
				</div>
				<div>
					<ul className={s.listStyle}>
						{
							options && options.map((option, index) => {
								if (option && option.isActive) {
									return (
										<li className={s.listSectionWidth} key={index}>
											<span className={s.checkBoxSection}>
												<CustomCheckbox
													key={index}
													className={'icheckbox_square-green'}
													name={`${input.name}[${index}]`}
													value={option.id}
													checked={input.value.indexOf(option.id) !== -1}
													onChange={event => {
														const newValue = [...input.value]
														if (event === true) {
															newValue.push(option.id);
														} else {
															newValue.splice(newValue.indexOf(option.id), 1);
														}
														input.onChange(newValue);
													}
													}
												/>
											</span>
											<span className={cx(s.checkBoxLabel)}>
												{option.categoryName}
											</span>
										</li>
									)
								}
							})
						}
					</ul>
					{error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
				</div>
			</FormGroup>
		);
	}

	renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div className={cx(s.formGroup, rs.displayGridPrifile, rs.alignItemCenter)}>
				<div>
					<label className={rs.restaurantLabelText}>{label}</label>
				</div>
				<div>
					<FormControl as="select" {...input} className={className} >
						{children}
					</FormControl>
					{touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
				</div>
			</div>
		)
	}

	checkboxHorizontalGroupOne = ({ label, input, checked, meta: { error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<div className={s.listSectionWidthTwo}>
					<span className={s.checkBoxSection}>
						<CustomCheckbox
							className={'icheckbox_square-green'}
							onChange={event => {
								input.onChange(event);
							}}
							checked={checked}
							value={true}
						/>
					</span>
					<span className={cx(s.checkBoxLabel)}>
						{label}
					</span>
				</div>

				{error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
			</div>
		)
	};

	checkboxHorizontalGroupTwo = ({ label, input, checked, meta: { error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<div className={cx(s.listSectionWidthTwo, s.listSectionWidthTwoMb)}>
					<span className={s.checkBoxSection}>
						<CustomCheckbox
							className={'icheckbox_square-green'}
							onChange={event => {
								input.onChange(event);
							}}
							checked={checked}
							value={true}
						/>
					</span>
					<span className={cx(s.checkBoxLabel)}>
						{label}
					</span>
				</div>

				{error && <span className={cx(rs.errorMessage, s.errorPosition)}>{formatMessage(error)}</span>}
			</div>
		)
	};


	render() {
		const { handleSubmit, loading, submitting, getCuisine, initialValues, getDietary } = this.props;
		const { picture, phoneStatus, shopEstimatedTime, error, isDoorDelivery, isTakeAway } = this.props
		const { phoneCountryCode, phoneDialCode, isReadOnly, defaultPreparationTime } = this.state;
		const { formatMessage } = this.props.intl;
		let phoneData = {
			phoneCountryCode: phoneCountryCode,
			phoneDialCode: phoneDialCode,
			phoneNumber: initialValues ? initialValues.phoneNumber : '',
			phoneStatus: phoneStatus,
			userId: initialValues ? initialValues.id : ''
		};

		return (
			<div className={cx('mainContainer')}>
				<div className={cx(s.editProfileContainer)}>
					<Container fluid>
						<Row>
							<Col md={12} lg={12} sm={12} xs={12}>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
									<div>
										<h1 className={rs.restaurantTitleText}>{formatMessage(messages.editProfile)}</h1>
									</div>
									<Row>
										<Col lg={12} md={12} sm={12} xs={12}>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="shopName"
														type="text"
														placeholder={formatMessage(messages.shopName)}
														component={this.renderField}
														label={formatMessage(messages.shopName)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputRestaurant}
													/>
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="description"
														type="text"
														placeholder={formatMessage(messages.restaurantDescription)}
														component={this.renderFormControlTextArea}
														label={formatMessage(messages.restaurantDescription)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputRestaurant}
													/>
												</div>
											</Form.Group>
											<Form.Group className={cx(s.formGroup, rs.displayGridPrifile)}>
												<div>
													<label className={rs.restaurantLabelText}>{formatMessage(messages.restaurantImage)}</label>
												</div>
												<div className={cx(rs.profileImgWidth, rs.restaurantProfileImgSection, s.droupZoneGrid)}>
													<div className={s.positionRelative}>
														{
															picture ?
																<div className={cx(rs.restaurantBackgroundImg, s.editZoneImgWidth)} style={{ backgroundImage: `url(${api.apiEndpoint + restaurantImageUploadDir + picture})` }} />
																:
																<div className={cx(rs.restaurantBackgroundImg, s.editZoneImgWidth)} style={{ backgroundImage: `url(${NoLogo})` }}></div>
														}
														{
															picture ?
																<Button onClick={this.handleDeleteImage} className={cx(s.btnTrash, 'btnTrashRTL')}>
																	<img src={TrashIcon} alt='Delete' />
																</Button>
																:
																''
														}
													</div>
													<div className={'retaurantDroupZone'}>
														<Dropzone
															className={cx(rs.profileButton)}
															subTextClass={s.subText}
															subText={formatMessage(messages.maximumUploadSizeLabel)}
															defaultMessage={formatMessage(messages.chooseFile)}
															inputContainer={'.dzInputContainerRestaurantImage'}
															inputContainerClass={'dzInputContainerRestaurantImage'}
															url={'/uploadRestaurantImage'}
															fieldName={'picture'}
															handleDropzone={this.handleDropzone}
															deleteEnabled={true}
														/>
													</div>
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="firstName"
														type="text"
														placeholder={formatMessage(messages.firstName)}
														component={this.renderField}
														label={formatMessage(messages.firstName)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputRestaurant}
													/>
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="lastName"
														type="text"
														placeholder={formatMessage(messages.lastName)}
														component={this.renderField}
														label={formatMessage(messages.lastName)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputRestaurant}
													/>
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="email"
														type="text"
														placeholder={formatMessage(messages.email)}
														component={this.renderField}
														label={formatMessage(messages.email)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputRestaurant}
														disabled
													/>
												</div>
											</Form.Group>
											<Form.Group className={cx(s.formGroup, rs.displayGridPrifile)}>
												<div>
													<label className={rs.restaurantLabelText}>{formatMessage(messages.phoneNumber)}</label>
												</div>
												<div>
													<PhoneNumberVerification phoneData={phoneData}
													/>
												</div>
											</Form.Group>
											<Form.Group className={cx(s.formGroup, rs.displayGridPrifile, rs.alignItemCenter)}>
												<div >
													<label className={rs.restaurantLabelText}>{formatMessage(messages.address)}</label>
												</div>
												<div className={'editProfile'}>
													<PlacesSuggest
														label={formatMessage(messages.address)}
														className={s.formGroup}
														handleData={this.handleLocationData}
														defaultValue={initialValues && initialValues.address}
													/>
													{error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="zipcode"
														type="text"
														placeholder={formatMessage(messages.zipcode)}
														component={this.renderField}
														label={formatMessage(messages.zipcode)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputRestaurant}
													/>
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<Field name="priceRange"
													className={cx(rs.formControlSelect, rs.formControlInputRestaurant)}
													component={this.renderFormControlSelect}
													placeholder={formatMessage(messages.priceRange)}
													label={formatMessage(messages.priceRange)}
												>
													<option value="">{formatMessage(messages.choosePriceRange)}</option>
													<option value={1}>$</option>
													<option value={2}>$$</option>
													<option value={3}>$$$</option>
													<option value={4}>$$$$</option>
												</Field>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<Field name="dietaryType" className={cx(rs.formControlSelect, rs.formControlInputRestaurant)}
													component={this.renderFormControlSelect}
													placeholder={formatMessage(messages.dietaryType)}
													label={formatMessage(messages.dietaryType)}
												>
													<option value="">{formatMessage(messages.chooseDietary)}</option>
													{
														getDietary && getDietary.settings && getDietary.settings.map((item) =>
															<option value={item.id}>{item.itemName}</option>
														)
													}
												</Field>
											</Form.Group>
											<div className={cx(s.formGroup, rs.displayGridPrifile)}>
												<label className={rs.restaurantLabelText}>{formatMessage(messages.DeliveryTypeText)}</label>
												<div className={cx(s.deliveryGrid)}>
													<Form.Group className={s.noMargin}>
														<Field name="isDoorDelivery"
															component={this.checkboxHorizontalGroupTwo}
															label={formatMessage(messages.isDoorDelivery)}
															checked={isDoorDelivery}
														/>
													</Form.Group>
													<Form.Group className={s.noMargin}>
														<Field name="isTakeAway"
															component={this.checkboxHorizontalGroupOne}
															label={formatMessage(messages.isTakeAway)}
															checked={isTakeAway}
														/>
													</Form.Group>
												</div>
											</div>
											{isTakeAway && <Form.Group className={s.formGroup}>
												<div>
													<Field
														name="shopPickupInstruction"
														type="text"
														placeholder={formatMessage(messages.ShopInstructionPlaceHolder)}
														component={this.renderFormControlTextArea}
														label={formatMessage(messages.shopPickupInstruction)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputRestaurant}
													/>
												</div>
											</Form.Group>}
											<Form.Group className={s.formGroup}>
												<div className={s.deliveryLine}>
													<Field name="categoryType"
														component={this.checkboxGroup}
														options={getCuisine && getCuisine.result}
														label={formatMessage(messages.category)}
													/>
												</div>
											</Form.Group>
										</Col>
										<Col lg={12} md={12} sm={12} xs={12} className={cx(rs.alignRightText, rs.spaceTop3, 'textAlignLeftRTL')}>
											<Form.Group className={s.formGroup}>
												<div className={s.displayInlineSection}>
													<Loader
														type={"button"}
														label={formatMessage(messages.update)}
														show={loading}
														buttonType={'submit'}
														className={cx(rs.button, rs.btnPrimary, 'rtlBtnLoader')}
														disabled={submitting || loading}
														isSuffix={true}
													/>
												</div>
											</Form.Group>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		)
	}
}

EditProfileForm = reduxForm({
	form: 'EditProfileForm',
	onSubmit: submit,
	validate
})(EditProfileForm);

const selector = formValueSelector('EditProfileForm');

const mapState = state => ({
	picture: selector(state, 'picture'),
	loading: state.loader.EditProfile,
	phoneNumber: selector(state, 'phoneNumber'),
	isPhoneVerified: selector(state, 'isPhoneVerified'),
	isDoorDelivery: selector(state, 'isDoorDelivery'),
	isTakeAway: selector(state, 'isTakeAway'),
	availableCurrencies: state.currency.availableCurrencies
});

const mapDispatch = {
	change
};


export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(EditProfileForm)));