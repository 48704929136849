exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2AWDi{\n    text-align: right;\n}\n.e7Oh6 {\n    text-align: center;\n}\n.WaL0p {\n    padding: 12px 27px;\n  }\n._1tG0x {\n    display: block;\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n    margin: 0;\n    padding: 13px 16px;\n    width: 100%;\n    outline: 0;\n    border: 1px solid #9D7A62 !important;\n    border-radius: 0px;\n    background: #9D7A62 !important;\n    color: #fff;\n    text-align: center;\n    text-decoration: none;\n    font-size: 16px;\n    line-height: 1.3333333;\n    cursor: pointer;\n    font-weight: 600;\n  }\n._20CVZ {\n    border-color: #9D7A62;\n    color: #fff;\n    background-color: #9D7A62;\n}\n._20CVZ:hover, ._20CVZ:focus {\n    border-color: #9d7a62cf;\n    color: #fff;\n    background-color: #9d7a62cf;\n}\n._6Zsfk{\n  cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"textAlignRight": "_2AWDi",
	"textAlignCenter": "e7Oh6",
	"btnLarge": "WaL0p",
	"button": "_1tG0x",
	"btnPrimary": "_20CVZ",
	"modalCaptionLink": "_6Zsfk"
};