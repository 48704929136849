import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AddPayoutContainer.css';
import PayoutForm from '../../../../components/Shop/Payout/PayoutForm/PayoutForm';

class PayoutContainer extends React.Component {

  render() {
    const { title, initialData } = this.props;

    return (
      <div>
        <div>
          <PayoutForm initialValues={initialData} />
        </div>
      </div>
    );
  }

};

export default withStyles(s)(PayoutContainer);
