import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';

let query = gql`
{
    itemAvailable {
        status
        errorMessage
    }
}
`;

export default function itemAvailable(id, currentPage, menuId) {
    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.query({
                query,
                fetchPolicy: 'network-only'
            });

            if (data && data.itemAvailable && data.itemAvailable.status === 200) {
                return {
                    action: 'success'
                }
            } else {
                toastr.error('Oops!', data && data.itemAvailable && data.itemAvailable.errorMessage)
                return {
                    action: 'failed'
                }
            }
        } catch (err) {
            return {
                action: 'failed'
            }
        }
    }
};
