import React, { Component } from 'react';
import s from './SliderForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector, FieldArray } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
    Form,
    Col,
    Row,
    FormControl,
    FormGroup,
    Container
} from 'react-bootstrap';
import Link from '../../../Link/Link';
import messages from '../../../../locale/messages';
import validate from './validate';
import { api, partnerHomepageUploadDir } from '../../../../config';
import Dropzone from '../../../Common/Dropzone';
import Loader from '../../../Common/Loader/Loader';

export class SliderForm extends Component {

    constructor(props) {
        super(props)

        this.handleDropzone = this.handleDropzone.bind(this);
    }

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }



    handleDropzone(fileName, fieldName) {
        const { change } = this.props

        change(fieldName, fileName)
    };

    render() {
        const { handleSubmit, sliderImage1, sliderImage2, sliderImage3, loading, submitting } = this.props;
        const { formatMessage } = this.props.intl;
        return (

            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col xl={10} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <h1 className={s.titleTextAdmin}>{formatMessage(messages.sliderSection)}</h1>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            {/* <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <Field name="sliderTitle4" type="text" component={this.renderField} label={'Title'} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <Field name="sliderContent4" type="text" component={this.renderFormControlTextArea} label={'Description'} />
                                                </Form.Group>
                                            </Col> */}
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <Field name="sliderTitle5" type="text" component={this.renderField} label={formatMessage(messages.contactUsTitle)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4} md={12} sm={12} xs={12} className={s.space1}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <label className={s.space1}>{formatMessage(messages.sliderImage1Label)}</label>
                                                        {
                                                            sliderImage1 &&
                                                            <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir}medium_${sliderImage1})` }} />
                                                        }
                                                        <div>
                                                            <Dropzone
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                inputContainer={'.dzInputContainerRestaurantImage'}
                                                                inputContainerClass={'dzInputContainerRestaurantImage'}
                                                                url={'/uploadPartnerHomepageImage'}
                                                                fieldName={'sliderImage1'}
                                                                handleDropzone={this.handleDropzone}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="sliderTitle1" type="text" component={this.renderField} label={formatMessage(messages.sliderTitle1Label)} />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="sliderContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.sliderDescription1Label)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={12} sm={12} xs={12} className={s.space1}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <label className={s.space1}>{formatMessage(messages.sliderImage2Label)}</label>
                                                        {
                                                            sliderImage2 &&
                                                            <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir}medium_${sliderImage2})` }} />
                                                        }
                                                        <div>
                                                            <Dropzone
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                inputContainer={'.dzInputContainerRestaurantImage'}
                                                                inputContainerClass={'dzInputContainerRestaurantImage'}
                                                                url={'/uploadPartnerHomepageImage'}
                                                                fieldName={'sliderImage2'}
                                                                handleDropzone={this.handleDropzone}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <Field name="sliderTitle2" type="text" component={this.renderField} label={formatMessage(messages.sliderTitle2Label)} />
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <Field name="sliderContent2" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.sliderDescription2Label)} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={12} sm={12} xs={12} className={s.space1}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <label className={s.space1}>{formatMessage(messages.sliderImage3Label)}</label>
                                                        {
                                                            sliderImage3 &&
                                                            <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir}medium_${sliderImage3})` }} />
                                                        }
                                                        <div>
                                                            <Dropzone
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                inputContainer={'.dzInputContainerRestaurantImage'}
                                                                inputContainerClass={'dzInputContainerRestaurantImage'}
                                                                url={'/uploadPartnerHomepageImage'}
                                                                fieldName={'sliderImage3'}
                                                                handleDropzone={this.handleDropzone}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <Field name="sliderTitle3" type="text" component={this.renderField} label={formatMessage(messages.sliderImage3Label)} />
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <Field name="sliderContent3" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.sliderDescription3Label)} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                                <Form.Group className={s.noMargin}>
                                                    <div className={s.displayInlineBlock}>
                                                        <Loader
                                                            type={"button"}
                                                            label={formatMessage(messages.submitButton)}
                                                            show={loading}
                                                            buttonType={'submit'}
                                                            className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                                                            disabled={submitting || loading}
                                                            isSuffix={true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

SliderForm = reduxForm({
    form: 'SliderForm',
    onSubmit: submit,
    validate
})(SliderForm);

const selector = formValueSelector('SliderForm')

const mapState = (state) => ({
    sliderImage1: selector(state, 'sliderImage1'),
    sliderImage2: selector(state, 'sliderImage2'),
    sliderImage3: selector(state, 'sliderImage3'),
    loading: state.loader.UpdatePartnerHomepage
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(SliderForm)));