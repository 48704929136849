import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './RestaurantPartners.css'
import {
    Row,
    Col,
    Container
} from 'react-bootstrap';

//Images
import OrderImage from '../../../../../public/SiteImages/vector-1.png';
import FoodPreparImage from '../../../../../public/SiteImages/vector-2.png';
import DelivaryArraiveImage from '../../../../../public/SiteImages/vector-3.png';
import RestaurantPartnerSection from './RestaurantPartnerSection';

//Locale
import messages from '../../../../locale/messages';
import { api, partnerHomepageUploadDir } from '../../../../config'

class RestaurantPartners extends React.Component {

    render() {
        const { formatMessage } = this.props.intl;
        const { homepage } = this.props;
        let partners = [1, 2, 3];

        return (
            <div className={s.root}>
                <Container fluid>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className={s.noPadding}>
                            <div className={s.positionRelative}>
                                <div className={cx(s.textCenter, s.headerMargin)}>
                                    <h1 className={s.headerText}>
                                        {homepage.infoTitle4}
                                    </h1>
                                    <p className={s.headerSubText}>
                                        {homepage.infoContent4}
                                    </p>
                                </div>
                                <div className={s.orderDisplayGrid}>
                                    {
                                        partners && partners.map((item, key) => {
                                            return <RestaurantPartnerSection
                                                key={key}
                                                title={homepage['infoTitle' + item]}
                                                Image={homepage['infoImage' + item]}
                                            />
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        );
    }
}

export default injectIntl(withStyles(s)(RestaurantPartners));