import { editRider } from '../../../actions/siteadmin/editRider';

async function submit(values, dispatch) {
    await dispatch(
        editRider(
            values.id,
            values.firstName,
            values.lastName,
            values.email,
            values.password ? values.password : undefined,
            values.phoneDialCode,
            values.phoneNumber,
            values.userStatus,
            values.isBan,
            values.phoneCountryCode
        )
    )

}

export default submit;