import React from 'react';
import AdminLayout from '../../../../components/Layout/AdminLayout';
import EditPricing from './EditPricing';
import messages from '../../../../locale/messages';

function action({ store, params, intl }) {
  const title = intl.formatMessage(messages.editFare);

  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;

  if (!isAdminAuthenticated) {
    return { redirect: '/siteadmin/login' };
  }

  return {
    title,
    component: (
      <AdminLayout>
        <EditPricing title={title} id={params.id} />
      </AdminLayout>
    ),
  };
}

export default action;
