import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import s from './Signup.css';
import SignupForm from '../../../components/Shop/SignupForm/SignupForm';
import getCuisineCategories from './getCuisineCategories.graphql';
import Loader from '../../../components/Common/Loader/Loader';

class Signup extends Component {


  render() {
    const { getCuisine: { loading, getActiveCategories } } = this.props;

    return (
      <div className={s.root}>
        <div className={s.container}>
          <h1>{this.props.title}</h1>
          <Loader type={"page"} show={loading}>
            {!loading && <SignupForm getCuisine={getActiveCategories} />}
          </Loader>
        </div>
      </div>
    );
  }
};

export default compose(
  withStyles(s),
  graphql(getCuisineCategories, {
    name: 'getCuisine',
    options: {
      ssr: true
    }
  })
)(Signup);
