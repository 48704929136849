import React from 'react';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './NewsBox.css';
import {
  Row,
  Col,
  Container
} from 'react-bootstrap';

import { api, partnerHomepageUploadDir } from '../../../../config';

class NewsBox extends React.Component {

  render() {
    const { homepage } = this.props;

    return (
      <div className={s.root}>
        <Container fluid>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12} className={s.noPadding}>
              <div className={s.positionRelative}>
                <div className={s.tapBannerImage} style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir + homepage.sliderImage5})` }} >
                  <div className={s.contentSection}>
                    <h3 className={s.title}>
                      {homepage.sliderTitle5}
                    </h3>
                    <a href={homepage.buttonLink} target="_blank" className={cx(s.btn, s.btnPrimary)}>
                      {homepage.buttonName}
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(NewsBox));