import gql from 'graphql-tag';
import histroy from '../../../history';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';

export function editCategory(id, categoryName, isActive, description) {

  return async (dispatch, getState, { client }) => {
    const mutation = gql`
      mutation updateCategory(
        $id: Int, 
        $categoryName: String,
        $description: String,
        $isActive: Boolean
      ) {
        updateCategory(
          id: $id,
          categoryName: $categoryName,
          description: $description,
          isActive: $isActive
        ){
          status
          errorMessage
        }
      }
    `;

    dispatch(setLoaderStart('EditCategory'));

    const { data } = await client.mutate({
      mutation,
      variables: {
        id,
        categoryName,
        description,
        isActive: (isActive === true || isActive === 'true') ? 1 : 0
      }
    });

    dispatch(setLoaderComplete('EditCategory'));

    if (data && data.updateCategory && data.updateCategory.status === 200) {
      toastr.success('Success!', 'The cuisine has been updated successfully.');
      histroy.push('/siteadmin/cuisine');
    } else if (data && data.updateCategory && data.updateCategory.status !== 200) {
      toastr.error('Error!', data.updateCategory.errorMessage);
    } else {
      toastr.error('Error!', "Something went wrong. Please try again.");
    }
  }
}