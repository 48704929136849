import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './HomePartners.css'
import Link from '../../Link/Link';

class HomePartnersCommon extends React.Component {

    render() {
        const { title, Image, Url } = this.props;
        return (
            <div className={s.marignBottom}>
                <a href={Url} target='_blank' title='Links' className={s.linkColor}>
                    <div className={s.mainSection}>
                        <div>
                            <img className={s.partnerImage} src={Image} alt="PartnerImages" />
                        </div>
                    </div>
                    <div className={s.spaceTop5}>
                        <p className={s.titleText}>
                            {title}
                        </p>
                    </div>
                </a>
            </div>
        );
    }
}


export default injectIntl(withStyles(s)(HomePartnersCommon));