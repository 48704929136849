import messages from '../../../locale/messages';
const validate = values => {

    const errors = {}

    if (values.email) {
        if (!/^(([^<>()[\]\\.,;.!-#$&%*+/=?:{|}~-\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
            errors.email = messages.emailInvalid;
        }
    }

    if (!values.password) {
        errors.password = messages.passwordRequired;
    } else if (values.password.length < 8) {
        errors.password = messages.minimumPassword;
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = messages.required;
    } else if (values.confirmPassword.trim() == '') {
        errors.confirmPassword = messages.required;
    } else if (values.password != values.confirmPassword) {
        errors.confirmPassword = messages.mismatchPassword;        
    }

    return errors
}

export default validate;