import gql from 'graphql-tag';
import { api } from '../../../config';
import { toastr } from 'react-redux-toastr';
import {
  UPDATE_DELIVERY_VEHICLE_PHOTO_START,
  UPDATE_DELIVERY_VEHICLE_PHOTO_SUCCESS,
  UPDATE_DELIVERY_VEHICLE_PHOTO_ERROR
} from '../../../constants';

export default function updateDeliveryVehicleImage(id, fileName, oldPicture) {

  return async (dispatch, getState, { client }) => {
    try {

      dispatch({
        type: UPDATE_DELIVERY_VEHICLE_PHOTO_START,
        payload: {
          imageUploadLoading: true
        }
      })

      const mutation = gql`
      mutation($id: Int, $fileName: String) {
        updateDeliveryVehicleImage(id: $id, fileName: $fileName) {
            status
        }
      }`;

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          fileName
        }
      });

      if (data.updateDeliveryVehicleImage && data.updateDeliveryVehicleImage.status === 200) {
        dispatch({
          type: UPDATE_DELIVERY_VEHICLE_PHOTO_SUCCESS,
          payload: {
            imageUploadLoading: false
          }
        });
        toastr.success('Success', 'The vehicle type image has been uploaded!');

        if (oldPicture !== null) {
          removeDeliveryVehicleImage(oldPicture);
        };
      } else {
        dispatch({
          type: UPDATE_DELIVERY_VEHICLE_PHOTO_ERROR,
          payload: {
            imageUploadLoading: false
          }
        })
      }
      
      
    } catch (err) {
      dispatch({
        type: UPDATE_DELIVERY_VEHICLE_PHOTO_ERROR,
        payload: {
          imageUploadLoading: false
        }
      })
    }
  }
}

async function removeDeliveryVehicleImage(fileName) {
  try {
    const url = api.apiEndpoint + "/deleteDeliveryVehicleImage";
    const resp = await fetch(url, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName }),
    });

    const { status } = await resp.json();

    if (status) {
      return { 
        status
      };
  }

  } catch (err) {
    console.log(err);
  }
}