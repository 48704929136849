import React from 'react';
import { graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';

import PaymentGatewayList from '../../../components/SiteAdmin/PaymentGatewayList';
import Loader from '../../../components/Common/Loader/Loader';

import s from './PaymentGateway.css';

import getPaymentMethodsQuery from './getPaymentMethods.graphql';

import messages from '../../../locale/messages';
class PaymentGateway extends React.Component {
  static defaultProps = {
    paymentMethods: {
      loading: true
    }
  }

  render() {
    const { paymentMethods: { loading, getAllPaymentMethods } } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.heading}>
              {formatMessage(messages.managePaymentGateWay)}
            </div>
            <div className={s.paddingRoutesSection}>
              <PaymentGatewayList getPaymentMethods={getAllPaymentMethods} />
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  injectIntl,
  withStyles(s),
  graphql(getPaymentMethodsQuery, {
    name: 'paymentMethods',
    options: {
      ssr: true,
      fetchPolicy: 'network-only'
    }
  }))(PaymentGateway);
