exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._224Ys {\n     padding: 7px 10px !important;\n}\n._2Zjvc {\n    width: 100%;\n    max-width: 20px;\n    margin-right: 3px;\n}\n._89d8k {\n    color: #9D7A62 !important;\n    text-decoration: none !important;\n}\n.OXEv3 {\n    opacity: 0.5;\n}\n._3Qr5x {\n    text-align: center !important;\n}", ""]);

// exports
exports.locals = {
	"btnPadding": "_224Ys",
	"iconWidth": "_2Zjvc",
	"linkColor": "_89d8k",
	"inactive": "OXEv3",
	"alignCenter": "_3Qr5x"
};