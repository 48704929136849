import uploadShopDocument from '../../../actions/shop/documentUpload/uploadShopDocument'
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {

    let documentError = false;

    values.documentList && values.documentList.map((item) => {
        item.fileName ? documentError = false : documentError = true
    })

    let documentList = values.documentList && values.documentList.length > 0
        ?
        JSON.stringify(values.documentList) : JSON.stringify([]);

    if (documentError) {
        return toastr.error('Error', 'Please upload document.')
    } else {
        await dispatch(
            uploadShopDocument(documentList)
        )
    }

}

export default submit;