import messages from '../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.firstName) {
        errors.firstName = messages.required;
    } else if (values.firstName.trim() == "") {
        errors.firstName = messages.required;
    } else if(values.firstName.length > 100) {
        errors.firstName = messages.exceedLimit100;
    }

    if (!values.lastName) {
        errors.lastName = messages.required;
    } else if (values.lastName.trim() == "") {
        errors.lastName = messages.required;
    } else if(values.lastName.length > 100) {
        errors.lastName = messages.exceedLimit100;
    }

    if (!values.email) {
        errors.email = messages.required;
    } else if (!/^(([^<>()[\]\\.,;.!-#$&%*+/=?:{|}~-\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
        errors.email = messages.emailInvalid;
    }

    if (!values.password) {
        // errors.password = messages.required;
    } else if (values.password.trim() == "") {
        errors.password = messages.required;
    } else if (values.password.length < 8) {
        errors.password = messages.passwordInvalid;
    }

    if (!values.phoneNumber) {
        errors.phoneNumber = messages.required;
    } else if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(values.phoneNumber)) {
        errors.phoneNumber = messages.phoneError;
    }

    if (!values.phoneDialCode) {
        errors.phoneDialCode = messages.required;
    }

    if (!values.vehicleName) {
        errors.vehicleName = messages.required;
    } else if (values.vehicleName.trim() == "") {
        errors.vehicleName = messages.required;
    }

    if (!values.vehicleNumber) {
        errors.vehicleNumber = messages.required;
    } else if (values.vehicleNumber.trim() == "") {
        errors.vehicleNumber = messages.required;
    }

    if (!values.vehicleType) {
        errors.vehicleType = messages.required;
    } else if (values.vehicleType.trim() == "") {
        errors.vehicleType = messages.required;
    }

    return errors;
};

export default validate