import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import {
    HOMEPAGE_SETTINGS_UPLOAD_START,
    HOMEPAGE_SETTINGS_UPLOAD_ERROR,
    HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index'
import { setLoaderStart,  setLoaderComplete } from '../loader/loader'

export default function updateHomepageSettingsDriver(values) {

    return async (dispatch, getState, { client }) => {

        dispatch({
            type: HOMEPAGE_SETTINGS_UPLOAD_START
        })

        try {
            
            const mutation = gql`
            mutation updateHomePageDriver (
                $safetyGridImage4: String
                $safetyGridImage5: String
                $safetyGridImage6: String
                $safetyGridTitle2: String
                $safetyGridContent2: String
                $safetyGridLink3: String
                $safetyGridLink4: String
              ) {
                updateHomePageDriver (
                    safetyGridImage4: $safetyGridImage4
                    safetyGridImage5: $safetyGridImage5
                    safetyGridImage6: $safetyGridImage6
                    safetyGridTitle2: $safetyGridTitle2
                    safetyGridContent2: $safetyGridContent2
                    safetyGridLink3: $safetyGridLink3
                    safetyGridLink4: $safetyGridLink4
                ) {
                  status
                }
              }
            `
            dispatch(setLoaderStart('DriverSettingsForm'))
            const { data } = await client.mutate({
                mutation,
                variables: {
                    safetyGridImage4: values && values.safetyGridImage4,
                    safetyGridImage5: values && values.safetyGridImage5,
                    safetyGridImage6: values && values.safetyGridImage6,
                    safetyGridTitle2: values && values.safetyGridTitle2,
                    safetyGridContent2: values && values.safetyGridContent2,
                    safetyGridLink3: values && values.safetyGridLink3,
                    safetyGridLink4: values && values.safetyGridLink4
                }
            })

            dispatch(setLoaderComplete('DriverSettingsForm'))
            if(data && data.updateHomePageDriver && data.updateHomePageDriver.status == 200) {
                
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
                })
                toastr.success('Success', 'Homepage settings changes have been successfully applied')
            } else {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
                })
                toastr.error('Oops!', 'Something went wrong')
            }
        } catch(err) {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
            })
            toastr.error('Oops!', 'Something went wrong')
        }
        
    }
}