import {
    GET_PAYOUT_START,
    GET_PAYOUT_SUCCESS,
    GET_PAYOUT_ERROR,
} from '../../../constants';
import gql from 'graphql-tag';

let query = gql`
query getPayouts($currentAccountId: String, $country:String) {
    getPayouts(currentAccountId: $currentAccountId, country: $country) {
      id
      methodId
      paymentMethod{
        id
        name
      }
      userId
      payEmail
      address1
      address2
      city
      state
      country
      zipcode
      currency
      default
      createdAt
      status
      last4Digits
      isVerified
      firstName
      lastName
    }
  }
`

export function getPayouts(currentAccountId, country) {

    return async (dispatch, getState, { client }) => {

        await dispatch({
            type: GET_PAYOUT_START,
            payload: {
                getPayoutLoading: true
            }
        });

        try {

            const { data } = await client.query({
                query,
                variables: {
                    currentAccountId,
                    country
                },
                fetchPolicy: 'network-only'
            });

            if (data && data.getPayouts && data.getPayouts.length >= 0) {

                await dispatch({
                    type: GET_PAYOUT_SUCCESS,
                    payload: {
                        payoutData: data.getPayouts,
                        getPayoutLoading: false
                    }
                });

            } else {

                await dispatch({
                    type: GET_PAYOUT_ERROR,
                    payload: {
                        error: 'No records found.',
                        getPayoutLoading: false
                    }
                });

            }

        } catch (error) {

            await dispatch({
                type: GET_PAYOUT_ERROR,
                payload: {
                    error,
                    getPayoutLoading: false
                }
            });
            return false;

        }

        return true;
    };
};
