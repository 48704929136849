import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/withStyles';
// style
import s from './StaticPage.css';
// components
import StaticPageManagement from '../../../components/SiteAdmin/StaticPageManagement';
import Loader from '../../../components/Common/Loader';
// graphql
import getStaticPagesQuery from './getStaticPages.graphql';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

export class StaticPage extends Component {
  static defaultProps = {
    staticPages: {
      loading: true
    }
  };

  render() {
    const { title, staticPages, staticPages: { loading } } = this.props;

    return (
      <Loader type={'page'} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.heading}>
            <FormattedMessage {...messages.StaticContentManagement} />
            </div>
            <div className={s.paddingRoutesSection}>
              {
                !loading && staticPages && <StaticPageManagement data={staticPages && staticPages.getStaticPages} />
              }
            </div>
          </div>
        </div>
      </Loader>
    )
  }
}

export default compose(
  withStyles(s),
  graphql(getStaticPagesQuery, {
    name: 'staticPages',
    options: (props) => ({
      fetchPolicy: 'network-only'
    })
  })
)(StaticPage);