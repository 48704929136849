import fetch from 'node-fetch';
import { setRuntimeVariable } from '../../runtime';
import { closeLoginModal } from '../../modalActions';
import { getAllShopModifier } from '../modifier/getAllShopModifier';

import {
    SHOP_USER_LOGIN_START,
    SHOP_USER_LOGIN_SUCCESS,
    SHOP_USER_LOGIN_ERROR
} from '../../../constants';

import { loadAccount } from '../userAccount/userAccount';

const query = `
        query (
            $email: String!,
            $password: String!
        ) {
            shopUserLogin (
                email: $email,
                password: $password
            ) {
                status
                errorMessage
                result {
                    id
                    email
                }
            }
        }`;

function userLogin(email, password) {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_USER_LOGIN_START
            });
            const response = await fetch('/graphql', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query,
                    variables: {
                        email: email,
                        password: password
                    }
                }),
                credentials: 'include'
            });

            const { data } = await response.json();

            if (data.shopUserLogin.status == 200) {
                await dispatch(loadAccount())
                await dispatch(setRuntimeVariable({
                    name: 'isRestaurantAuthenticated',
                    value: true,
                }));
                await dispatch(getAllShopModifier());
                await dispatch(closeLoginModal());
                dispatch({ type: SHOP_USER_LOGIN_SUCCESS })
                return {
                    status: 200
                }
            } else {
                dispatch({ type: SHOP_USER_LOGIN_ERROR })
                return {
                    status: 400,
                    errorMessage: data.shopUserLogin.errorMessage
                }
            }
        } catch (error) {
            dispatch({ type: SHOP_USER_LOGIN_ERROR })

            return false;
        }

    }




}

export default userLogin;