import React from 'react';
import PreparationTimeContainer from './PreparationTimeContainer';
import DashboardLayout from '../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action({ store, params, intl }) {
    const title = intl.formatMessage(messages.preparationTime);

    let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;
    
    if (!isRestaurantAuthenticated) {
        return { redirect: '/signup' };
    };

    return {
        title,
        component: (
            <DashboardLayout>
                <PreparationTimeContainer title={title} />
            </DashboardLayout>
        )
    }
}

export default action;