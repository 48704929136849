import React from 'react';
import ConfigSettings from './ConfigSettings';
import AdminLayout from '../../../components/Layout/AdminLayout';
import { restrictUrls } from '../../../helpers/adminPrivileges';

const title = 'Manage Site Configuration'
function action({ store }) {

    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }
    // Admin restriction
    if (!restrictUrls('/siteadmin/settings/config', adminPrivileges, privileges)) {
        return { redirect: '/siteadmin' };
    }

    return {
        title,
        component: (
            <AdminLayout>
                <ConfigSettings title={title} />
            </AdminLayout>
        ),
    }
}
export default action;