import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import Col from 'react-bootstrap/Col';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
// style
import s from './AdminDashboard.css';
// helper
import messages from '../../../locale/messages';
// components
import Link from '../../Link/Link';
import CurrencyConverter from '../../CurrencyConverter';

export class AdminDashboardUserCard extends Component {
    static defaultProps = {
        data: {
            total: 0,
            today: 0,
            week: 0,
            month: 0,
            currency: 'USD'
        },
        link: '/'
    };

    constructor(props) {
        super(props);

        this.renderAmount = this.renderAmount.bind(this);
    }

    renderAmount(amount, from) {
        const { toCurrency } = this.props;
        if (String(amount) && amount >= 0 && from && toCurrency) {
            return (
                <div>
                    <CurrencyConverter from={from} amount={amount} to={toCurrency} />
                </div>
            );
        }
        return 0;
    }

    render() {
        const { data, link, heading, displayAmount, largeBox } = this.props;
    
        return (
            <Col xl={largeBox ? 6 : 4} lg={12} md={12} sm={12} xs={12}>
                <Link to={link} className={s.noTextDecoration}>
                    <div className={s.box}>
                        <h2 className={s.titleFontSize}>{heading}</h2>
                        <div className={s.boxInner}>
                            <div className={s.displayTable}>
                                <div className={s.displayTableRow}>
                                    <div className={cx(s.displayTableCell, s.widthText)}>
                                        <div><FormattedMessage {...messages.today} /></div>
                                        <div className={s.priceCircle}>{displayAmount === true ? this.renderAmount(data.today, data.currency) : data.today}</div>
                                    </div>
                                    <div className={cx(s.displayTableCell, s.widthText)}>
                                        <div><FormattedMessage {...messages.weekly} /></div>
                                        <div className={s.priceCircle}>{displayAmount === true ? this.renderAmount(data.week, data.currency) : data.week}</div>
                                    </div>
                                    <div className={cx(s.displayTableCell, s.widthText)}>
                                        <div><FormattedMessage {...messages.montly} /></div>
                                        <div className={s.priceCircle}>{displayAmount === true ? this.renderAmount(data.month, data.currency) : data.month}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.totalCss}>
                            <div className={s.displayTable}>
                                <div className={s.displayTableRow}>
                                    <div className={cx(s.displayTableCell, s.widthText, s.textCenterDashboard)}><FormattedMessage {...messages.overAll} /></div>
                                    <div className={cx(s.displayTableCell, s.textRight, 'textAlignLeftRTL')}>{displayAmount === true ? this.renderAmount(data.total, data.currency) : data.total}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </Col>  
        )
    }
}

const mapStateToProps = (state) => ({ });

const mapDispatchToProps = {};

export default injectIntl(withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(AdminDashboardUserCard)));