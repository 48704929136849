import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import GetLocation from './GetLocation';
import messages from '../../../locale/messages';


function action({ store, intl }) {
    const title = intl.formatMessage(messages.location);

    //From Redux Store
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }

    return {
        title,
        component: (
            <AdminLayout>
                <GetLocation title={title} />
            </AdminLayout>
        ),
    }
}

export default action;