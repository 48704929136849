import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
// Graphql
import getShopProfile from './getShopProfile.graphql';
import getCuisineCategories from './getCuisineCategories.graphql';
import getCommonSettings from './getCommonSettings.graphql';
// Component
import Loader from '../../../components/Common/Loader/Loader';
import EditProfileForm from '../../../components/Shop/EditProfileForm/EditProfileForm';
// CSS
import s from './EditProfile.css';

export class EditProfile extends Component {

	static defaultProps = {
		data: {
			loading: true
		},
		settingData: {
			loading: true
		}
	}

	render() {
		const { data: { loading, getShopProfile }, getCuisine: { getActiveCategories } } = this.props;
		const { settingData: { getAllCommonSettingsData, loading: settingLoading } } = this.props;

		let initialValues = {};

		let phoneStatus = getShopProfile && getShopProfile.userVerifiedInfo && getShopProfile.userVerifiedInfo.isPhoneVerified;

		if (!loading) {
			initialValues = {
				id: getShopProfile.id,
				email: getShopProfile.email,
				phoneDialCode: getShopProfile.phoneDialCode,
				phoneCountryCode: getShopProfile.phoneCountryCode,
				phoneNumber: getShopProfile.phoneNumber,
				...getShopProfile.profile,
				priceRange: getShopProfile.profile && getShopProfile.profile.priceRangeId,
				categoryType: getShopProfile && getShopProfile.shopCategory.map((item) => item.categoryId),
			}
		}

		return (
			<Loader type={"page"} show={loading} >
				<div className={s.paddingRoutesSection}>
					{
						!loading && initialValues && <EditProfileForm
							initialValues={initialValues}
							getCuisine={getActiveCategories}
							getDietary={!settingLoading && getAllCommonSettingsData && getAllCommonSettingsData.results && getAllCommonSettingsData.results.length > 0 && getAllCommonSettingsData.results[0]}
							phoneStatus={phoneStatus}
						/>
					}
				</div>
			</Loader>
		)
	}
}

export default compose(withStyles(s),
	graphql(getShopProfile, {
		options: (props) => ({
			variables: {
				id: props.id
			},
			fetchPolicy: 'network-only',
			ssr: true
		})
	}),
	graphql(getCommonSettings, {
		name: 'settingData',
		options: (props) => ({
			variables: {
				typeId: 1
			},
			fetchPolicy: 'network-only',
			ssr: true
		})
	}),
	graphql(getCuisineCategories, {
		name: 'getCuisine',
		options: {
			fetchPolicy: 'network-only',
			ssr: true
		}
	})
)(EditProfile)