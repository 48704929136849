import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash'

// Redux Form
import { reduxForm, change, formValueSelector } from 'redux-form';
// Redux
import { connect } from 'react-redux';

import {
  Button,
  Form,
  Row,
  Col,
  Container
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from '../Payout.css';
import rs from '../../../restaurantCommon.css';

// Graphql
import getPaymentMethodsQuery from './getPaymentMethods.graphql';

// Locale
import messages from '../../../../locale/messages';

import Loader from '../../../Common/Loader';

class PayoutMethods extends Component {
  static defaultProps = {
    PaymentMethodsData: {
      loading: true,
      getPaymentMethods: []
    }
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { PaymentMethodsData: { loading, getPaymentMethods } } = this.props;
    const { change, paymentMethodId } = this.props;
    if (!loading && getPaymentMethods != null && getPaymentMethods.length > 0) {
      change('methodId', getPaymentMethods && getPaymentMethods[0].id);
      change('paymentType', getPaymentMethods && getPaymentMethods[0].paymentType);
      change('currency', getPaymentMethods && getPaymentMethods[0].currency);
      change('isEnable', getPaymentMethods && getPaymentMethods[0].isEnable);
    }
  }

  handleChange(methodId, paymentType, currency, isEnable) {
    const { change } = this.props;
    change('methodId', methodId);
    change('paymentType', paymentType);
    change('currency', currency);
    change('isEnable', isEnable);

  }

  render() {
    const { error, handleSubmit, previousPage } = this.props;
    const { PaymentMethodsData: { loading, getPaymentMethods } } = this.props;
    const { paymentMethodId } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={'mainContainer'}>
        <div className={s.container}>
          <Container fluid>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12} className={s.responsiveNoPadding}>
                <div>
                  <h1 className={rs.restaurantTitleText}>
                    <FormattedMessage {...messages.addPayout} />
                  </h1>
                </div>
                <Form className={s.fullWidth} onSubmit={handleSubmit}>
                  <div className={s.panelBody}>
                    <p className={s.payoutIntro}>
                      <FormattedMessage {...messages.payoutIntro1} />
                    </p>
                    {
                      loading && <div><Loader containerClass={'text-center'} /></div>
                    }
                    {
                      !loading && getPaymentMethods != undefined && getPaymentMethods.length > 0 && <div className={cx(s.tableCss, 'tableCss', 'restauranttableSticky', 'restaurantTableCss', 'NewResponsiveTable')}>
                        <table className={cx('table', s.noBorder)}>
                          <thead>
                            <tr className={cx(s.rowBorder, s.sectionTitleLight, s.textTruncate)}>
                              <th className={s.noBorder} scope="col" />
                              <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle} /></th>
                              <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle1} /></th>
                              <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle2} /></th>
                              <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle3} /></th>
                              <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle4} /></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              getPaymentMethods.map((item, index) => {
                                let checked = false;
                                if (item.id === paymentMethodId) {
                                  checked = true;
                                }
                                return (
                                  <tr className={cx(s.sectionTitleLight)} key={index}>
                                    <td>
                                      <input name="methodId" type="radio" checked={checked} value={item.id} onChange={() => this.handleChange(item.id, item.paymentType, item.currency, item.isEnable)} />
                                    </td>
                                    <td data-label={formatMessage(messages.payoutTitle)}><label className={s.radioText}>{item.name}</label></td>
                                    <td data-label={formatMessage(messages.payoutTitle1)}>{item.processedIn}</td>
                                    <td data-label={formatMessage(messages.payoutTitle2)}>{item.fees}</td>
                                    <td data-label={formatMessage(messages.payoutTitle3)}>{item.currency}</td>
                                    <td data-label={formatMessage(messages.payoutTitle4)}>{item.details}</td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    }
                    {
                      !loading && getPaymentMethods != undefined && getPaymentMethods.length === 0 && <div> <FormattedMessage {...messages.noPaymentFound} /> </div>
                    }

                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12} className={cx(s.spaceTop2, rs.alignRightText, 'textAlignLeftRTL')}>
                        <div className={s.displayInlineBtn}>
                          <Form.Group className={s.formGroup}>
                            <div>
                              <Button onClick={previousPage} className={cx(rs.button, rs.btnPrimaryBorder, s.btnWidthMobile)}>
                                <FormattedMessage {...messages.back} />
                              </Button>
                            </div>
                          </Form.Group>
                        </div>
                        <div className={s.displayInlineBtn}>
                          <Form.Group className={s.formGroup}>
                            <div>
                              <Button type="submit" className={cx(rs.button, rs.btnPrimary, s.btnWidthMobile)}>
                                <FormattedMessage {...messages.next} />
                              </Button>
                            </div>
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

PayoutMethods = reduxForm({
  form: 'PayoutForm', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PayoutMethods);

const selector = formValueSelector('PayoutForm');

const mapState = (state) => ({
  paymentMethodId: selector(state, 'methodId')
});

const mapDispatch = {
  change
};

export default compose(
  injectIntl,
  withStyles(s, rs),
  connect(mapState, mapDispatch),
  graphql(getPaymentMethodsQuery, {
    name: 'PaymentMethodsData',
    options: {
      ssr: false,
    }
  }),
)(PayoutMethods);