import gql from 'graphql-tag';
import {
    CATEGORY_UPDATE_SUCCESS,
    CATEGORY_UPDATE_START,
    CATEGORY_UPDATE_ERROR
} from '../../../constants';
import { toastr } from 'react-redux-toastr';

export default function updateCategoryStatus(id, isActive) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: CATEGORY_UPDATE_START
        });
        
        try {
            let errorMessage;

            let mutation = gql`
            mutation updateCategoryStatus($id: Int, $isActive: Boolean) {
                updateCategoryStatus(id: $id, isActive: $isActive) {
                  status
                  errorMessage
                }
            }
            `;

            const { data } = await client.mutate({
                mutation,
                variables : {
                    id,
                    isActive: isActive == '1' ? 1 : 0
                }
            });
            
            if (data && data.updateCategoryStatus && data.updateCategoryStatus.status === 200) {
                dispatch({
                    type: CATEGORY_UPDATE_SUCCESS
                });
                toastr.success('Success', `The cuisine status has been updated.`);
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: CATEGORY_UPDATE_ERROR
                });

                errorMessage = (data && data.updateCategoryStatus && data.updateCategoryStatus.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
                return {
                    status: 400
                };
            }
        } catch(err) {
            toastr.error('Error!', err);
            dispatch({
                type: CATEGORY_UPDATE_ERROR
            });
            return {
                status: 400
            };
        }

    }
};