import { toastr } from 'react-redux-toastr';
import history from '../../../history';
import {
	SHOP_MODIFIER_UPDATE_START,
	SHOP_MODIFIER_UPDATE_SUCCESS,
	SHOP_MODIFIER_UPDATE_ERROR
} from '../../../constants/index';
import updateModifierMutation from './updateModifier.graphql';
import { getAllShopModifier } from './getAllShopModifier';

function addModifier(values) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: SHOP_MODIFIER_UPDATE_START,
			payload: {
				updateLoading: true
			}
		});
		try {
			if (values.id) {
				const { data: { updateModifierGroup } } = await client.mutate({
					mutation: updateModifierMutation,
					variables: values
				});

				if (updateModifierGroup && updateModifierGroup.status == 200) {
					dispatch({
						type: SHOP_MODIFIER_UPDATE_SUCCESS,
						payload: {
							updateLoading: false
						}
					});
					toastr.success('Success!', 'The modifier changes have been updated successfully.');
					history.push('/shop/menu/modifier-groups');
				} else {
					dispatch({
						type: SHOP_MODIFIER_UPDATE_ERROR,
						payload: {
							updateLoading: false
						}
					});
					toastr.error("Error!", updateModifierGroup.errorMessage);
				}
			} else {
				const { data: { updateModifierGroup } } = await client.mutate({
					mutation: updateModifierMutation,
					variables: values
				});

				if (updateModifierGroup && updateModifierGroup.status === 200) {
					dispatch({
						type: SHOP_MODIFIER_UPDATE_SUCCESS,
						payload: {
							updateLoading: false
						}
					});
					toastr.success("Success!", "The modifier has been created successfully.");
					history.push('/shop/menu/modifier-groups');
				} else {
					dispatch({
						type: SHOP_MODIFIER_UPDATE_ERROR,
						payload: {
							updateLoading: false
						}
					});
					toastr.error("Error!", updateModifierGroup && updateModifierGroup.errorMessage)
				}
			}
			dispatch(getAllShopModifier());

		} catch (error) {
			toastr.error("Error!", error);
			dispatch({
				type: SHOP_MODIFIER_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
}

export default addModifier;