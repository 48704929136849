import {
	SHOP_ITEM_UPDATE_START,
	SHOP_ITEM_UPDATE_SUCCESS,
	SHOP_ITEM_UPDATE_ERROR
} from '../../../constants/index';
import { initialize } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import getItemQuery from './getItem.graphql';

export default function editItem(id) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: SHOP_ITEM_UPDATE_START,
			payload: {
				updateLoading: true
			}
		});

		try {
			let errorMessage;

			const { data: { getItem } } = await client.query({
				query: getItemQuery,
				variables: {
					id
				},
				fetchPolicy: 'network-only',
			});

			if (getItem) {
				dispatch(initialize('AddItemForm', getItem));
				dispatch({
					type: SHOP_ITEM_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
			} else {
				dispatch({
					type: SHOP_ITEM_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});

				errorMessage = (getItem && getItem.errorMessage) || "Oops! Something went wrong. Please try again.";
				toastr.error('Error!', errorMessage);
			}
		} catch (err) {
			toastr.error('Error!', err);
			dispatch({
				type: SHOP_ITEM_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};
