import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ConfirmationPopup.css';
import cx from 'classnames';
import {
	Modal,
	Button
} from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
import rs from '../../restaurantCommon.css';

export class ConfirmationPopup extends Component {

	static defaultProps = {
		setShow: false
	}

	constructor(props) {
		super(props);
		this.state = {
			show: false,
			setShow: false
		};

	}

	componentDidMount() {
		const { modalStatus } = this.props;
		if (modalStatus === true) {
			this.setState({ setShow: true });
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { modalStatus } = nextProps;

		if (modalStatus === true) {
			this.setState({ setShow: true });
		} else {
			this.setState({ setShow: false });
		}
	}

	handleRightButton() {

	}

	render() {
		const { checkBookedModifier, closeModal, title, body, popupButtonLeftName, popupButtonRightName, popupButtonLeftFunction, popupButtonRightFunction, bodyModifier, orderTitle } = this.props;
		const { setShow } = this.state;

		return (
			<div>
				<Modal show={setShow} onHide={closeModal} className={'listItemModal'}>
					<Modal.Header closeButton>
						<Modal.Title>{title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className={cx(rs.title, orderTitle)}>
							{checkBookedModifier && bodyModifier}
							{!checkBookedModifier && body}
						</div>
						<div className={cx(rs.alignRightText, 'textAlignLeftRTL')}>
							<div className={cx(rs.displayInlineBlockRes, rs.btnMarginRight, 'btnMarginRightRTL')}>
								<Button variant="secondary" className={cx(rs.button, rs.btnPrimaryBorder, rs.textOverFlow, rs.responsiveBtn)} onClick={() => popupButtonLeftFunction()}>
									{popupButtonLeftName}
								</Button>
							</div>
							<div className={rs.displayInlineBlockRes}>
								<Button variant="primary" className={cx(rs.button, rs.btnPrimary, rs.textOverFlow, rs.responsiveBtn)} onClick={() => popupButtonRightFunction()}>
									{popupButtonRightName}
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}

const mapState = state => ({

});

const mapDispatch = {
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(ConfirmationPopup)));
