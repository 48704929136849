import { toastr } from 'react-redux-toastr';
import history from '../../../history';
import getAllSubMenuQuery from './getAllSubMenu.graphql';
import updateItemMutation from './updateItem.graphql';

function addItem(values) {
	return async (dispatch, getState, { client }) => {
		try {
			if (values.id) {
				const { data: { updateItem } } = await client.mutate({
					mutation: updateItemMutation,
					variables: values,
					refetchQueries: [{ query: getAllSubMenuQuery, variables: { currentPage: 1, searchList: '', menuId: values.menuId } }]
				});

				if (updateItem && updateItem.status == 200) {
					toastr.success('Success', 'Your changes has been updated successfully.');
					history.push('/shop/menu/' + values.menuId + '/item');
				} else {
					toastr.error('Error', updateItem.errorMessage);
				}
			} else {
				const { data: { updateItem } } = await client.mutate({
					mutation: updateItemMutation,
					variables: values,
					refetchQueries: [{ query: getAllSubMenuQuery, variables: { currentPage: 1, menuId: values.menuId } }]
				});

				if (updateItem && updateItem.status == 200) {
					toastr.success('Success', 'A new food item has been added successfully.');
					history.push('/shop/menu/' + values.menuId + '/item');
				} else {
					toastr.error('Error', updateItem.errorMessage);
				}
			}

		} catch (error) {

		}
	}
}

export default addItem;