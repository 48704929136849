import React from 'react';
// components
import AdminLayout from '../../../components/Layout/AdminLayout';
import Layout from '../../../components/Layout';
import CommonSettings from './CommonSettings';
import NotFound from '../../not-found/NotFound';
import { restrictUrls } from '../../../helpers/adminPrivileges';

function renderNotFound() {
    return {
        title: 'Not Found!',
        component: <Layout><NotFound title={'Not Found!'} /></Layout>,
        status: 404
    };
}

async function action({ params, store }) {
    const typeId = params.typeId;
    let title;
    // From Redux Store
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let commonSettingsTypeData = store.getState().common && store.getState().common.commonSettingsTypes;
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;
  
    if (!isAdminAuthenticated) {
        return { redirect: '/site-admin/commonSettings/1' };
    }

    // Admin restriction
  if (!restrictUrls('/siteadmin/common-settings/1', adminPrivileges, privileges)) {
    return { redirect: '/siteadmin' };
  }

    if (typeId === undefined || isNaN(typeId)) {
        renderNotFound();
        return;
    }

    const typeData = commonSettingsTypeData && commonSettingsTypeData.find((o) => o.id === Number(typeId));
    
    if (typeData) {
        title = typeData.typeLabel;
    } else {
        renderNotFound();
        return;
    }

    return {
        title,
        component: <AdminLayout><CommonSettings title={title} typeData={typeData} typeId={typeId} /></AdminLayout>
    }
}

export default action;