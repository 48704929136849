/*

Supported languages

English - en
Spanish - es
French - fr
Russian - ru
Japanese - ja	
Indonesian - id
Arabic - ar
*/

import { capitalizeFirstLetter } from '../capitalizeFirst';

export async function pushNotificationMessage(type, requestData, lang) {
    let title = '', message = '', requestLang;
    let supportedLang = ['en', 'es', 'fr', 'ru', 'ja', 'id', 'ar'];
    requestLang = lang ? lang : 'en';
    requestLang = (supportedLang.indexOf(requestLang) >= 0) ? requestLang : 'en';

    if (type === 'shopApproved') {
        if (requestLang == 'en') {
            message = `Order Accepted! Hang-tight! ${formatText(requestData['shopDetails']['shopName'])} is working on your order!`;
        } else if (requestLang == 'es') {
            message = `Pedido aceptado! ¡Aguanta! ${formatText(requestData['shopDetails']['shopName'])} está trabajando en su pedido!`;
        } else if (requestLang == 'fr') {
            message = `Commande acceptée ! Tenir fermement! ${formatText(requestData['shopDetails']['shopName'])} travaille sur votre commande !`;
        } else if (requestLang == 'ru') {
            message = `Заказ принят! Держать крепко! ${formatText(requestData['shopDetails']['shopName'])} работает над вашим заказом!`;
        } else if (requestLang == 'ja') {
            message = `注文受付中！しっかりと！ ${formatText(requestData['shopDetails']['shopName'])} あなたの注文に取り組んでいます!`;
        } else if (requestLang == 'id') {
            message = `Pesanan Diterima! Berpegangan kuat! ${formatText(requestData['shopDetails']['shopName'])} sedang mengerjakan pesanan Anda!`;
        } else if (requestLang == 'ar') {
            message = `تم قبول الطلب! تمسك جيدا! ${formatText(requestData['shopDetails']['shopName'])} يعمل على طلبك!`;
        }
    }

    if (type === 'shopDeclined') {
        if (requestLang == 'en') {
            message = `Sorry! ${formatText(requestData['shopDetails']['shopName'])} is unable to process your order(#${requestData['orderId']}) right now. Please try another order or contact support for help.`;
        } else if (requestLang == 'es') {
            message = `¡Lo siento! ${formatText(requestData['shopDetails']['shopName'])} no puede procesar su pedido(#${requestData['orderId']}) ahora mismo. Intente con otro pedido o comuníquese con soporte para obtener ayuda.`;
        } else if (requestLang == 'fr') {
            message = `Désolée! ${formatText(requestData['shopDetails']['shopName'])} n'est pas en mesure de traiter votre commande(#${requestData['orderId']}) tout de suite. Veuillez essayer une autre commande ou contacter l'assistance pour obtenir de l'aide.`;
        } else if (requestLang == 'ru') {
            message = `Извини! ${formatText(requestData['shopDetails']['shopName'])} не может обработать ваш заказ(#${requestData['orderId']}) прямо сейчас. Пожалуйста, попробуйте другой заказ или обратитесь за помощью в службу поддержки.`;
        } else if (requestLang == 'ja') {
            message = `ごめん！ ${formatText(requestData['shopDetails']['shopName'])} 注文を処理できません(#${requestData['orderId']}) たった今。別の注文を試すか、サポートにお問い合わせください。`;
        } else if (requestLang == 'id') {
            message = `Maaf! ${formatText(requestData['shopDetails']['shopName'])} tidak dapat memproses pesanan Anda(#${requestData['orderId']}) sekarang. Silakan coba pesanan lain atau hubungi dukungan untuk bantuan.`;
        } else if (requestLang == 'ar') {
            message = `آسف!${formatText(requestData['shopDetails']['shopName'])}  غير قادر على معالجة طلبك(#${requestData['orderId']}) الآن. يرجى محاولة طلب آخر أو الاتصال بالدعم للحصول على المساعدة.`;
        }
    }

    if (type === 'readyForDelivery') {
        if (requestLang == 'en') {
            message = `Heads-up! Your order is ready! We are finding someone to deliver your order.`;
        } else if (requestLang == 'es') {
            message = `¡Aviso! ¡Tu pedido está listo! Estamos buscando a alguien para entregar su pedido.`;
        } else if (requestLang == 'fr') {
            message = `La tête haute! Votre commande est prête ! Nous recherchons quelqu'un pour livrer votre commande.`;
        } else if (requestLang == 'ru') {
            message = `Берегись! Ваш заказ готов! Мы ищем человека, который доставит ваш заказ.`;
        } else if (requestLang == 'ja') {
            message = `注意喚起！ご注文の準備が完了しました。あなたの注文品を配達してくれる人を探しています。`;
        } else if (requestLang == 'id') {
            message = `Perhatian! Pesanan Anda sudah siap! Kami menemukan seseorang untuk mengirimkan pesanan Anda.`;
        } else if (requestLang == 'ar') {
            message = `انتباه! طلبك جاهز! نعثر على شخص ما لتسليم طلبك.`;
        }
    }

    if (type === 'pickUpRequest') {
        if (requestLang == 'en') {
            message = `Your food is hot and ready! Come grab your order now.`;
        } else if (requestLang == 'es') {
            message = `¡Tu comida está caliente y lista! Ven a tomar tu pedido ahora.`;
        } else if (requestLang == 'fr') {
            message = `Votre plat est chaud et prêt ! Venez vite récupérer votre commande.`;
        } else if (requestLang == 'ru') {
            message = `Ваша еда горячая и готова! Приходите забрать свой заказ прямо сейчас.`;
        } else if (requestLang == 'ja') {
            message = `あなたの食べ物は熱くて準備ができています！ 今すぐ注文を取りに来てください。`;
        } else if (requestLang == 'id') {
            message = `Makanan Anda panas dan siap! Ayo ambil pesananmu sekarang.`;
        } else if (requestLang == 'ar') {
            message = `طعامك ساخن وجاهز! تعال واحصل على طلبك الآن.`;
        }
    }

    if (type === 'deliveryRequest') {
        if (requestLang == 'en') {
            message = `Hey there! You got a new delivery pick-up request! Would you like to accept this order!?`;
        } else if (requestLang == 'es') {
            message = `¡Hola! ¡Tienes una nueva solicitud de recogida de entrega! ¿¡Te gustaría aceptar este pedido!?`;
        } else if (requestLang == 'fr') {
            message = `Salut! Vous avez une nouvelle demande de retrait de livraison ! Souhaitez-vous accepter cette commande ! ?`;
        } else if (requestLang == 'ru') {
            message = `Привет! Вы получили новый запрос на доставку! Хочешь принять этот заказ!?`;
        } else if (requestLang == 'ja') {
            message = `ちょっと、そこ！新たな配達集荷依頼がありました！この注文を受け入れますか?`;
        } else if (requestLang == 'id') {
            message = `Hai! Anda mendapat permintaan penjemputan pengiriman baru! Apakah Anda ingin menerima pesanan ini!?`;
        } else if (requestLang == 'ar') {
            message = `مرحبًا يا من هناك! لقد تلقيت طلبًا جديدًا لاستلام التوصيل! هل ترغب في قبول هذا الطلب !؟`;
        }
    }

    if (type === 'deliveryNotFound') {
        if (requestLang == 'en') {
            message = `Sorry! We are unable to delivery your order(#${requestData['orderId']}) from ${formatText(requestData['shopDetails']['shopName'])}. Please try another order or contact support for help.`;
        } else if (requestLang == 'es') {
            message = `¡Lo siento! No podemos enviar su pedido(#${requestData['orderId']}) desde ${formatText(requestData['shopDetails']['shopName'])}.Intente con otro pedido o póngase en contacto con el servicio de asistencia para obtener ayuda.`;
        } else if (requestLang == 'fr') {
            message = `Pardon! Nous ne pouvons pas livrer votre commande(#${requestData['orderId']}) depuis ${formatText(requestData['shopDetails']['shopName'])}. Veuillez essayer une autre commande ou contacter l'assistance pour obtenir de l'aide.`;
        } else if (requestLang == 'ru') {
            message = `Сожалею! Мы не можем доставить ваш заказ(#${requestData['orderId']}) из ${formatText(requestData['shopDetails']['shopName'])}. Попробуйте другой заказ или обратитесь за помощью в службу поддержки.`;
        } else if (requestLang == 'ja') {
            message = `ごめんなさい！ご注文をお届けすることができません（#${requestData['orderId']}) から ${formatText(requestData['shopDetails']['shopName'])}. 別の注文を試すか、サポートに連絡してください。`;
        } else if (requestLang == 'id') {
            message = `Maaf! Kami tidak dapat mengirimkan pesanan(#${requestData['orderId']}) Anda dari ${formatText(requestData['shopDetails']['shopName'])}. Harap coba pesanan lain atau hubungi dukungan untuk mendapatkan bantuan.`;
        } else if (requestLang == 'ar') {
            message = `آسف! نحن غير قادرين على تسليم طلبك(#${requestData['orderId']}) من عند ${formatText(requestData['shopDetails']['shopName'])}. يرجى محاولة طلب آخر أو الاتصال بالدعم للحصول على المساعدة.`;
        }
    }

    if (type === 'paymentRefundForCard') {
        if (requestLang == 'en') {
            message = `Hey there! Just to inform you, we have refunded ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} to your credit/debit card for the order ID #${requestData['orderId']}.`;
        } else if (requestLang == 'es') {
            message = `¡Hola! Solo para informarle, hemos reembolsado ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} a su tarjeta de crédito/débito para el ID del pedido #${requestData['orderId']}.`;
        } else if (requestLang == 'fr') {
            message = `Salut! Juste pour vous informer, nous avons remboursé ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} sur votre carte de crédit/débit pour l'ID de commande #${requestData['orderId']}.`;
        } else if (requestLang == 'ru') {
            message = `Привет! Просто чтобы сообщить вам, мы вернули ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} на вашу кредитную/дебетовую карту для идентификатора заказа #${requestData['orderId']}.`;
        } else if (requestLang == 'ja') {
            message = `ちょっと、そこ！お知らせしますが、返金させていただきました ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} 注文 ID をクレジットカード/デビットカードに送信します #${requestData['orderId']}.`;
        } else if (requestLang == 'id') {
            message = `Hai! Hanya untuk memberi tahu Anda, kami telah mengembalikan uang ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} ke kartu kredit/debit Anda untuk ID pesanan #${requestData['orderId']}.`;
        } else if (requestLang == 'ar') {
            message = `مرحبًا يا من هناك! فقط لإبلاغك ، قمنا برد الأموال ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} إلى بطاقة الائتمان / الخصم الخاصة بك لمعرف الطلب #${requestData['orderId']}.`;
        }
    }

    if (type === 'paymentRefundForWallet') {
        if (requestLang == 'en') {
            message = `Hey there! Just to inform you, we have credited ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} to your wallet balance for the order ID #${requestData['orderId']}.`;
        } else if (requestLang == 'es') {
            message = `¡Hola! Solo para informarle, hemos acreditado ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} al saldo de su billetera para el ID del pedido #${requestData['orderId']}.`;
        } else if (requestLang == 'fr') {
            message = `Salut! Juste pour vous informer, nous avons crédité ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} au solde de votre portefeuille pour l'ID de commande #${requestData['orderId']}.`;
        } else if (requestLang == 'ru') {
            message = `Привет! Просто чтобы сообщить вам, мы зачислили ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} на баланс вашего кошелька для идентификатора заказа #${requestData['orderId']}.`;
        } else if (requestLang == 'ja') {
            message = `ちょっと、そこ！お知らせしておきますが、私たちはクレジットしています ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} 注文IDのウォレット残高に #${requestData['orderId']}.`;
        } else if (requestLang == 'id') {
            message = `Hai! Hanya untuk memberitahu Anda, kami telah dikreditkan ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} ke saldo dompet Anda untuk ID pesanan #${requestData['orderId']}.`;
        } else if (requestLang == 'ar') {
            message = `مرحبًا يا من هناك! فقط لإبلاغك ، لدينا الفضل ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} إلى رصيد محفظتك لمعرف الطلب #${requestData['orderId']}.`;
        }
    }

    if (type === 'payoutToDriver') {
        if (requestLang == 'en') {
            message = `Congrats! We have transferred ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} to your account for the order #${requestData['orderId']}. Deliver more! Earn More!`;
        } else if (requestLang == 'es') {
            message = `¡Felicidades! Nos hemos transferido ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} a su cuenta para el pedido #${requestData['orderId']}. ¡Entrega más! ¡Ganar mas!`;
        } else if (requestLang == 'fr') {
            message = `Félicitations! Nous avons transféré ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} à votre compte pour la commande #${requestData['orderId']}. Offrez plus! Gagne plus!`;
        } else if (requestLang == 'ru') {
            message = `Поздравляю! Мы перевели ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} на ваш счет для заказа #${requestData['orderId']}. Доставьте больше! Зарабатывать больше!`;
        } else if (requestLang == 'ja') {
            message = `おめでとうございます！移管しました ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} 注文のためにあなたのアカウントに #${requestData['orderId']}. もっとお届けします！もっと稼ぐ！`;
        } else if (requestLang == 'id') {
            message = `Selamat! Kami telah mentransfer ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} ke akun Anda untuk pesanan #${requestData['orderId']}. Berikan lebih banyak! Dapatkan lebih!`;
        } else if (requestLang == 'ar') {
            message = `تهاني! لقد نقلنا ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} إلى حسابك للطلب #${requestData['orderId']}. تقديم المزيد! كسب المزيد!`;
        }
    }

    if (type === 'payoutToShop') {
        if (requestLang == 'en') {
            message = `Congrats! We have transferred ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} to your account for the order #${requestData['orderId']}. Thank you for your service!`;
        } else if (requestLang == 'es') {
            message = `¡Felicidades! Nos hemos transferido ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} a su cuenta para el pedido #${requestData['orderId']}. ¡Gracias por tu servicio!`;
        } else if (requestLang == 'fr') {
            message = `Félicitations! Nous avons transféré ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} à votre compte pour la commande #${requestData['orderId']}. Merci pour votre service!`;
        } else if (requestLang == 'ru') {
            message = `Поздравляю! Мы перевели ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} на ваш счет для заказа #${requestData['orderId']}. Спасибо за ваш сервис!`;
        } else if (requestLang == 'ja') {
            message = `おめでとうございます！移管しました ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} 注文のためにあなたのアカウントに #${requestData['orderId']}. サービスしてくれてありがとう！`;
        } else if (requestLang == 'id') {
            message = `Selamat! Kami telah mentransfer ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} ke akun Anda untuk pesanan #${requestData['orderId']}. Terima kasih atas layanan Anda!`;
        } else if (requestLang == 'ar') {
            message = `تهاني! لقد نقلنا ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} إلى حسابك للطلب #${requestData['orderId']}. أشكركم على خدمتكم!`;
        }
    }

    if (type === 'completeDelivery') {
        if (requestLang == 'en') {
            message = `Knock-Knock! We have delivered your order! Enjoy! Thank you for ordering with PLUGD!`;
        } else if (requestLang == 'es') {
            message = `¡TOC Toc! ¡Hemos entregado tu pedido! ¡Disfrutar! ¡Gracias por ordenar con PLUGD!`;
        } else if (requestLang == 'fr') {
            message = `Toc Toc! Nous avons livré votre commande ! Apprécier! Merci d'avoir commandé avec PLUGD !`;
        } else if (requestLang == 'ru') {
            message = `Тук-тук! Мы доставили ваш заказ! Наслаждаться! Спасибо за заказ с PLUGD!`;
        } else if (requestLang == 'ja') {
            message = `コンコン！ご注文の商品をお届けしました！楽しみ！ PLUGD をご注文いただきありがとうございます。`;
        } else if (requestLang == 'id') {
            message = `Knock-Knock! Kami telah mengirimkan pesanan Anda! Menikmati! Terima kasih telah memesan dengan PLUGD!`;
        } else if (requestLang == 'ar') {
            message = `دق دق! لقد قمنا بتسليم طلبك! يتمتع! شكرًا لك على الطلب مع PLUGD!`;
        }
    }

    return {
        title,
        message
    };
}

export function formatAmount(amount, currency, locale) {
    let convertCurrency = 'USD';
    if (amount) {
        convertCurrency = currency ? currency : convertCurrency;
        return amount.toLocaleString(locale, { style: 'currency', currency: convertCurrency });
    } else {
        return null;
    }
}

export function formatText(text) {
    let capitalizedText = capitalizeFirstLetter(text);
    return capitalizedText ? capitalizedText.trim() : '';
}