import React from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AdminHeader.css';
import { Nav, Navbar } from 'react-bootstrap';
import Link from '../Link';
import messages from '../../locale/messages';
import cx from 'classnames';
import { adminLogout } from '../../actions/siteadmin/logout';
import { connect } from 'react-redux';

// Components
import Logout from '../Common/Logout';
import HeaderModal from '../HeaderModal/HeaderModal';

// Action
import { openHeaderModal } from '../../actions/siteadmin/modalActions';

//Images 
import LogOutIcon from '../../../public/Icons/logout.svg';
import MainSiteIcon from '../../../public/Icons/mainSite.svg';
import languageIcon from '../../../public/Icons/Language-white.svg';

// Helpers
import history from '../../history';
import { formatLocale } from '../../helpers/formatLocale';

class AdminHeader extends React.Component {
  constructor(props) {
    super(props);
    this.navigation = this.navigation.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    const { socket, userId } = this.props;
    if (socket && userId) {
      socket.removeListener(`webAdminUserLogout-${userId}`);
    }
  }

  navigation(URL) {
    if (URL) {
      history.push(URL);
    }
  }

  handleChange(e) {
    const { openHeaderModal } = this.props;
    openHeaderModal('languageModal');
  }

  render() {
    const { adminLogout, currentLocale, socket, userId } = this.props;
    if (socket && userId) {
      socket.on(`webAdminUserLogout-${userId}`, (data) => {
        if (typeof window !== "undefined") window.location.assign('/adminUserLogout');
      });
    }
    return (
      <div className={s.headerBg}>
        <div className={cx(s.logOut, 'logOut', 'floatLeftRTL')}>
          <Nav className="mr-auto">
            <HeaderModal />
            <Link noLink
              onClick={(e) => this.handleChange(e)}
              className={cx(s.siteColor, s.cursurPointer)}>
              <span className={cx(s.displayInlineBlock, s.vtrTop, s.iconWidth)}>
                <img src={languageIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
              </span>
              <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding, 'iconTextPaddingRTL')}>
                {formatLocale(currentLocale)}
              </span>
            </Link>
            <Link to={'/'} onClick={() => this.openClose()} className={cx(s.Sitepadding, s.siteColor)}>
              <span className={cx(s.displayInlineBlock, s.vtrTop, s.iconWidth)}>
                <img src={MainSiteIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
              </span>
              <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding, 'iconTextPaddingRTL')}>
                <FormattedMessage {...messages.goToMainSite} />
              </span>
            </Link>
            <Logout
              actionUrl="/adminLogout"
              buttonClass={s.siteColor}
            >
              <span className={cx(s.displayInlineBlock, s.vtrTop, s.iconWidth)}>
                <img src={LogOutIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
              </span>
              <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding, 'iconTextPaddingRTL')}>
                <FormattedMessage {...messages.logout} />
              </span>
            </Logout>
          </Nav>
        </div>
      </div>
    )
  }
}

const mapState = state => ({
  userId: state.account && state.account.privileges && state.account.privileges.id,
  currentLocale: state.intl.locale
});
const mapDispatch = {
  adminLogout,
  openHeaderModal
};

export default withStyles(s)(connect(mapState, mapDispatch)(AdminHeader));