import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import FailedPayout from './FailedPayout';
import messages from '../../../locale/messages';


function action({ store, intl }) {
    const title = intl.formatMessage(messages.manageFailedPayout);

    //From Redux Store
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }
    
    return {
        title,
        component: (
            <AdminLayout>
                <FailedPayout title={title} />
            </AdminLayout>
        ),
    }
}

export default action;