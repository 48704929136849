exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._32--T {\n  padding-left: 0;\n  padding-right: 0;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  background: #d4d7e5;\n}\n._1hwRO {\n  margin: 0 auto;\n}\n._2rK3p{\n  color: red\n}\n", ""]);

// exports
exports.locals = {
	"root": "_32--T",
	"container": "_1hwRO",
	"errorMessage": "_2rK3p"
};