import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import history from '../../../history';

function addMenu(values) {


	return async (dispatch, getState, { client }) => {

		try {

			let operationHours = values.menuAvailability ? JSON.stringify(values.menuAvailability) : JSON.stringify([]);

			if (values.id) {
				const mutation = gql`mutation ($operationHours: String, $menuName: String, $id: Int, $isEnable: Boolean){
					updateMenu(operationHours: $operationHours, menuName: $menuName, id: $id, isEnable: $isEnable){
							status
							errorMessage
						}
					}`;
				const { data } = await client.mutate({
					mutation,
					variables: {
						operationHours: operationHours,
						menuName: values.menuName,
						id: values.id,
						isEnable: values.isEnable == 'false' ? 0 : 1,
					}
				});

				if (data && data.updateMenu && data.updateMenu.status == 200) {
					toastr.success('Success', 'Your changes has been updated successfully');
					history.push('/shop/menu');
				} else {
					toastr.error('Error', data.updateMenu.errorMessage);
				}
			} else {
				const mutation = gql`mutation ($operationHours: String, $menuName: String, $isEnable: Boolean){
					addMenu(operationHours: $operationHours, menuName: $menuName, isEnable: $isEnable){
							status
							errorMessage
						}
					}`;
				const { data } = await client.mutate({
					mutation,
					variables: {
						operationHours: operationHours,
						menuName: values.menuName,
						isEnable: values.isEnable == 'false' ? 0 : 1,
					}
				});

				if (data && data.addMenu && data.addMenu.status == 200) {
					toastr.success('Success', 'A new Menu added successfully');
					history.push('/shop/menu');
				} else {
					toastr.error('Error', data.addMenu.errorMessage);
				}
			}

		} catch (error) {

		}
	}
}

export default addMenu;