import gql from 'graphql-tag';
import {
    UPDATE_DELIVERY_VEHICLE_STATUS_SUCCESS,
    UPDATE_DELIVERY_VEHICLE_STATUS_START,
    UPDATE_DELIVERY_VEHICLE_STATUS_ERROR
} from '../../../constants';
import { toastr } from 'react-redux-toastr';

export default function updateDeliveryVehicleStatus(id, isActive) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: UPDATE_DELIVERY_VEHICLE_STATUS_START
        });
        
        try {
            let errorMessage;

            let mutation = gql`
            mutation($id: Int, $isActive: Boolean) {
                updateDeliveryVehicleStatus(id: $id, isActive: $isActive) {
                    status
                    errorMessage
                }
            }
            `;

            const { data } = await client.mutate({
                mutation,
                variables : {
                    id,
                    isActive: isActive == '1' ? 1 : 0
                }
            });
            
            if (data && data.updateDeliveryVehicleStatus && data.updateDeliveryVehicleStatus.status === 200) {
                dispatch({
                    type: UPDATE_DELIVERY_VEHICLE_STATUS_SUCCESS
                });
                toastr.success('Success', `The vehicle type status has been updated.`);
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: UPDATE_DELIVERY_VEHICLE_STATUS_ERROR
                });

                errorMessage = (data && data.updateDeliveryVehicleStatus && data.updateDeliveryVehicleStatus.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
                return {
                    status: 400
                };
            }
        } catch(err) {
            toastr.error('Error!', err);
            dispatch({
                type: UPDATE_DELIVERY_VEHICLE_STATUS_ERROR
            });
            return {
                status: 400
            };
        }

    }
};