import React from 'react';
import {
  Modal,
} from 'react-bootstrap';
import { closeTransactionModal } from '../../../actions/siteadmin/modalActions';
import { connect } from 'react-redux';
import messages from '../../../locale/messages';
import { FormattedMessage, injectIntl } from 'react-intl';

class FailedTransactionModal extends React.Component {
  static defaultProps = {
    setShow: false
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      setShow: false
    };

  }

  componentDidMount() {
    const { isTransactionModalOpen } = this.props;
    if (isTransactionModalOpen === true) {
      this.setState({ setShow: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps)  {
    const { isTransactionModalOpen } = nextProps;

    if (isTransactionModalOpen === true) {
      this.setState({ setShow: true });
    } else {
      this.setState({ setShow: false });
    }
  }

  render() {
    const { closeTransactionModal, reason } = this.props;
    const { setShow } = this.state;
   
    return (
      <div>
        <Modal show={setShow} onHide={closeTransactionModal} className={'listItemModal'}>
          <Modal.Header closeButton>
            Failure
          </Modal.Header>
          <Modal.Body>
            Reason: {reason}
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapState = (state) => ({
  isTransactionModalOpen: state.modalStatus.isTransactionModalOpen,
});

const mapDispatch = {
  closeTransactionModal,
};

export default injectIntl(connect(mapState, mapDispatch)(FailedTransactionModal));
