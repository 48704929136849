import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag'
import { flowRight as compose } from 'lodash'
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';

// Style
import {
  FormGroup,
  Col,
  FormControl,
  Row,
  Container
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ChangeAdminForm.css';
import Loader from '../../Common/Loader';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { injectIntl } from 'react-intl';
import messages from '../../../locale/messages'

import validate from './validate';

class ChangeAdminForm extends Component {


  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  static defaultProps = {
    loading: false
  };

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, note }) => {
    const { formatMessage } = this.props.intl
    return (
      <FormGroup className={s.space3}>
        <div>
          <Col xs={12} sm={12} md={12} lg={12}>
            <label className={s.labelText} >{label}</label>
            <FormControl {...input} placeholder={label} type={type} className={className} />
            {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            {
              note && <p className={s.subtext}>{note}</p>
            }
          </Col>
        </div>
      </FormGroup>
    );
  }

  async submitForm(values, dispatch) {

    dispatch(setLoaderStart('changePassword'));

    const { mutate } = this.props;
    const { data } = await mutate({ variables: values });

    if (data && data.changeAdminUser) {
      if (data.changeAdminUser.status == '200') {
        toastr.success("Changed Successfully!", "Admin details changed successfully!");
      } else if (data.changeAdminUser.status === 'email') {
        toastr.error("Failed to update!", "Oops! this email address is already exist.");
      } else {
        toastr.error("Oops!", "Your changes to admin details failed!");
      }
    }

    dispatch(setLoaderComplete('changePassword'));
    dispatch(reset('ChangeAdminForm'));

  }

  render() {

    const { error, handleSubmit, submitting, dispatch, title, isSuperAdmin, loading } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx(s.space5, s.spaceTop5)}>
        <Container fluid>
          <Row>
            <Col lg={6} md={12} sm={12} xs={12} className={s.blackCenter}>
              <div className={s.blackCenterSection}>
                <form onSubmit={handleSubmit(this.submitForm)}>
                  {error && <strong>{error}</strong>}
                  {
                    <Field
                      name="email"
                      type="text"
                      component={this.renderFormControl}
                      label={formatMessage(messages.email)}
                      note={formatMessage(messages.emailNotes)}
                      className={s.formControlInput}
                    />
                  }
                  <Field name="password" type="password" component={this.renderFormControl} label={formatMessage(messages.password)} className={s.formControlInput} />
                  <Field name="confirmPassword" type="password" component={this.renderFormControl} label={formatMessage(messages.confirmPassword)} className={s.formControlInput} />
                  <FormGroup className={s.noMargin}>
                    <Col xs={12} sm={12} md={12} lg={12} className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                      <div className={s.displayInlineBlock}>
                        <Loader
                          type={"button"}
                          label={formatMessage(messages.submitButton)}
                          show={loading}
                          buttonType={'submit'}
                          className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                          disabled={submitting || loading}
                          isSuffix={true}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

}

ChangeAdminForm = reduxForm({
  form: 'ChangeAdminForm',
  validate
})(ChangeAdminForm);

const mapState = (state) => ({
  isSuperAdmin: state.runtime.isSuperAdmin,
  loading: state.loader.changePassword
});

const mapDispatch = {};

export default injectIntl(compose(
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(gql`
    mutation changeAdminUser($email: String, $password: String!) {
      changeAdminUser (email: $email, password: $password) {
        status
      }
    }
  `),
)(ChangeAdminForm));