import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import setOperationHours from './setOperationHours';

function updateOperationHours(values) {
    let query = gql `query getOperationHours {
        getOperationHours{
            results{
                id
                shopId
                operationDay,
                isOpen,
                isAllHours
                startTime,
                endTime
            }
        }
    }`;

    const mutation = gql `mutation ($operationHours: String){
        updatePartnerOperatingHours(operationHours: $operationHours){
            status
            errorMessage
        }
    }`;

    return async (dispatch, getState, { client }) => {
        try {
            let errorMessage = 'Oops! Something went wrong. Unable to update the operating hours and try again.';
            const { data } = await client.mutate({
                mutation,
                variables: {
                    operationHours: values
                },
                refetchQueries: [{ query }]
            });

            if (data && data.updatePartnerOperatingHours && data.updatePartnerOperatingHours.status == 200) {
                let operationHours = JSON.parse(values);
                dispatch(setOperationHours(operationHours));
                toastr.success('Success!', 'The operating hours have been updated successfully.');
            } else {
                errorMessage = (data && data.updatePartnerOperatingHours && data.updatePartnerOperatingHours.errorMessage) ? data.updatePartnerOperatingHours.errorMessage : errorMessage;
                toastr.error('Error!', errorMessage);
            }
        } catch (error) {
            let catchErrorMessage = 'Oops! Something went wrong. ' + error;
            toastr.error('Error!', catchErrorMessage);
        }
    }
}

export default updateOperationHours;