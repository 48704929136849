import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import history from '../../../history';

export function updatePassword(email, newPassword, token) {

  return async (dispatch, getState, { client }) => {

    try {

      let mutation = gql`
      mutation updateForgotPassword($email: String!, $token: String!, $newPassword: String!) {
        updateForgotPassword (email:$email, token: $token, newPassword: $newPassword) {
          status
          errorMessage
        }
      }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: {
          email,
          token,
          newPassword
        }
      });

      if (data && data.updateForgotPassword) {
        if (data.updateForgotPassword.status === 200) {
          toastr.success("Change Password Successfully", "Your password has changed, you can try login now!");
          history.push('/signup');
        } else {
          toastr.error("Change Password Failed", "Something went wrong, please try again later. " + data.updateForgotPassword.errorMessage);
          return false;
        }
      } else {
        toastr.error("Change Password Failed", "Something went wrong, please try again later.");
        return false;
      }

    } catch (error) {
      toastr.error("Change Password Failed", "Something went wrong, please try again later. " + error);
      return false;
    }

    return true;
  };
}