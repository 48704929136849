import React from 'react';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './HomeSlider.css';
import cx from 'classnames';
import Swiper from 'react-id-swiper';
import HomeItem from '../HomeItem';
import Loader from '../../Common/Loader';
import { isRTL } from '../../../helpers/formatLocale';
import nextArrow from '../../../../public/Icons/Right-arow.png';
import prevArrow from '../../../../public/Icons/Left-arrow.png';


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={nextArrow} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={prevArrow} />
    </div>
  );
}

class HomeSlider extends React.Component {
  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
    this.swiper = null;
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
  }

  componentDidMount() {
    this.setState({
      load: true
    });
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    const { locale } = this.props.intl;
    const { locale: prevLocale } = prevProps.intl;

    if (locale !== prevLocale) {
      this.setState({
        load: false
      });
      clearTimeout(this.loadSync);
      this.loadSync = null;
      this.loadSync = setTimeout(() => {
        this.setState({
          load: true
        })
      }, 100);
    }
  }

  goNext() {
    this.swiper.slideNext();
  }

  goPrev() {
    this.swiper.slidePrev();
  }


  render() {
    const { data, intl: { locale } } = this.props;
    const { load } = this.state;
    const params = {
      slidesPerView: 4,
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 1,
          centeredSlides: true,
          loop: true,
        }
      }
    };

    return (
      <div className={s.root}>
        <div className={cx(s.paddingTopBottom, 'homeSlickSlider', s.sliderMain)}>
          {
            !load && <div>
              <Loader type="text" />
            </div>
          }
          {
            load && data &&
            data.result &&
            data.result.length > 0 && <div className={s.positionRelative}><Swiper {...params} ref={node => this.swiper = node !== null ? node.swiper : null} rtl={isRTL(locale)} className={cx('row', s.noMargin)} >
              {
                data.result.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className='swiperSliderMobielWidth'>
                        <HomeItem
                          key={index}
                          categoryName={item.categoryName}
                          categoryImage={item.categoryImage}
                        />
                      </div>
                    </div>
                  );
                })}
            </Swiper>
              <div className={s.arrowPosition}>
                <SamplePrevArrow className={cx(s.prevArrow, 'prevArrowRTL', 'd-none d-lg-block d-xl-block')} onClick={this.goPrev} />
                <SampleNextArrow className={cx(s.nextArrow, 'nextArrowRTL', 'd-none d-lg-block d-xl-block')} onClick={this.goNext} />
              </div>
            </div>}
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(HomeSlider))

