import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  Button,
  FormControl
} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AdminRolesManagement.css';
import bt from '../../../components/commonStyle.css';

// Redux Actions
import { openAdminRolesModal } from '../../../actions/siteadmin/modalActions';
import { deleteAdminRole } from '../../../actions/siteadmin/AdminRoles/manageAdminRoles';

// Components
import AdminRolesModal from '../AdminRolesModal';
import Link from '../../Link';
import CustomPagination from '../../CustomPagination/CustomPagination';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
import debounce from '../../../helpers/debounce';
// Images
import EditIcon from '../../../../public/Icons/edit.png';
import TrashIcon from '../../../../public/Icons/bin.svg';

class AdminRolesManagement extends Component {

  static defaultProps = {
    data: []
  };

  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      searchList: ''
    };
    this.paginationData = this.paginationData.bind(this);
    this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this));
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleKeywordSearch(searchList) {
    const { adminRoles: { refetch } } = this.props;
    let variables = {
      currentPage: 1,
      searchList,
    };
    this.setState({ searchList, currentPage: 1 });
    refetch(variables);
  }


  paginationData(currentPage) {
    const { adminRoles: { refetch } } = this.props;
    const { searchList } = this.state;
    let variables = { currentPage, searchList };
    this.setState({ currentPage });
    refetch(variables);
  };

  async handleDelete(id) {
    const { adminRoles: { loading, refetch }, deleteAdminRole } = this.props;
    const { searchList, currentPage } = this.state;
    let variables = { currentPage, searchList };
    const response = await deleteAdminRole(id);
    this.setState({ currentPage });
    refetch(variables);
  }


  render() {
    const { openAdminRolesModal, deleteAdminRole } = this.props;
    const { adminRoles, adminRoles: { getAllAdminRoles } } = this.props;

    const { formatMessage } = this.props.intl;
    const { currentPage } = this.state;

    let paginationLabel = formatMessage(messages.roles);

    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div>
          <AdminRolesModal paginationData={this.paginationData} />
          <div className={s.container}>
            <div className={cx(s.padding2, s.paddingTop2, s.tabelExport)}>
              <div className={s.searchInput}>
                <FormControl type='text' placeholder={formatMessage(messages.search)}
                  onChange={(event) => this.handleKeywordSearch(event.target && event.target.value)}
                  className={s.formControlInput} />
              </div>
              <Button
                className={cx(s.addlinkBtn, 'floatLeftRTL')}
                onClick={() => openAdminRolesModal('add')}
              >
                <FormattedMessage {...messages.addNewLabel} />
              </Button>
            </div>
              <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
                <Table className="table">
                  <thead>
                    <tr>
                      <th scope="col"><FormattedMessage {...messages.idLabel} /></th>
                      <th scope="col"><FormattedMessage {...messages.name} /></th>
                      <th scope="col"><FormattedMessage {...messages.descriptionAdminLabel} /></th>
                      <th scope="col"><FormattedMessage {...messages.edit} /></th>
                      <th scope="col"><FormattedMessage {...messages.delete} /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      adminRoles && getAllAdminRoles && getAllAdminRoles.count > 0 && getAllAdminRoles.results && getAllAdminRoles.results.map((value, key) => {
                        return (
                          <tr key={key}>
                            <td data-label={formatMessage(messages.idLabel)}>{value.id}</td>
                            <td data-label={formatMessage(messages.name)}>{value.name}</td>
                            <td data-label={formatMessage(messages.descriptionAdminLabel)}>{value.description}</td>
                            <td data-label={formatMessage(messages.edit)}>
                              <Link noLink onClick={() => openAdminRolesModal('edit', value)} className={s.cursorPointer}>
                                <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                                <span className={s.vtrMiddle}>
                                  <FormattedMessage {...messages.edit} />
                                </span>
                              </Link>
                            </td>
                            <td data-label={formatMessage(messages.delete)}>
                              <Link noLink onClick={() => this.handleDelete(value.id)} className={s.cursorPointer}>
                                <img src={TrashIcon} className={cx(s.editIcon, 'trashIconRTL', 'editIconRTL')} />
                                <span className={s.vtrMiddle}>
                                  <FormattedMessage {...messages.delete} />
                                </span>
                              </Link>
                            </td>
                          </tr>
                        )
                      })
                    }
                    {
                      adminRoles && getAllAdminRoles && getAllAdminRoles.count === 0 && <tr>
                        <td colSpan={4}>
                          <FormattedMessage {...messages.noResult} />
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </div>
          </div>
          {
            adminRoles && getAllAdminRoles && getAllAdminRoles.count > 0 && <div className={cx(s.space5, s.spaceTop5)}>
              <CustomPagination
                total={getAllAdminRoles.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={paginationLabel}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({});

const mapDispatch = {
  openAdminRolesModal,
  deleteAdminRole
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(AdminRolesManagement)));