import gql from 'graphql-tag';
import histroy from '../../../history';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';

export function addCategory(categoryName, isActive, categoryImage, description) {

  return async (dispatch, getState, { client }) => {
    const mutation = gql`
    mutation addCategory(
        $categoryName: String,
        $isActive: Boolean,
        $categoryImage: String,
        $description: String
    ) {
        addCategory(
            categoryName: $categoryName,
            isActive: $isActive,
            categoryImage: $categoryImage,
            description: $description
        ) {
            status
            errorMessage
        }
    }`;

    dispatch(setLoaderStart('AddCategory'));
    
    const { data } = await client.mutate({
      mutation,
      variables: {
        categoryName,
        isActive: (isActive === true || isActive === 'true') ? 1 : 0,
        categoryImage,
        description
      }
    });
    
    dispatch(setLoaderComplete('AddCategory'));

    if (data && data.addCategory && data.addCategory.status === 200) {
      toastr.success('Success!', 'The cuisine has been added successfully.');
      histroy.push('/siteadmin/cuisine');
    } else if (data && data.addCategory && data.addCategory.status !== 200){
      toastr.error('Error!', data.addCategory.errorMessage);
    } else {
      toastr.error('Error!', "Something went wrong. Please try again.");
    }
  }
}