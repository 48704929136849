
import React from 'react';
import ModifierGroups from './modifierGroups';
import DashboardLayout from '../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action({ store, intl }) {
  const title = intl.formatMessage(messages.modifierGroup);

  let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;

  if (!isRestaurantAuthenticated) {
    return { redirect: '/signup' };
  };

  return {
    title,
    component: <DashboardLayout><ModifierGroups title={title} /></DashboardLayout>,
  };
}

export default action;
