import messages from '../../../../locale/messages';

const validate = (values) =>{
    const error = {};

    if (!values.itemName) {
        error.itemName = messages.required;
    } else if(values.itemName && values.itemName.toString().trim() === '') {
        error.itemName = messages.required;
    }

    return error;
}

export default validate;