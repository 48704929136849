import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import Autosuggest from 'react-autosuggest';
import {
    Button
} from 'react-bootstrap';

// Style
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ModifierCommonType.css';
import cx from 'classnames';
import rs from '../../../../../components/restaurantCommon.css'

import { match } from '../../../../../helpers/match';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../../../locale/messages';
import getExistingModifier from '../../../../../actions/shop/modifier/getExistingModifier';
import { openItemModal } from '../../../../../actions/siteadmin/modalActions';



const parse = require('autosuggest-highlight/parse');

const getSuggestionValue = suggestion => suggestion.label;

class ModifierCommonType extends React.Component {
    static propTypes = {
        change: PropTypes.func,
        formName: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            suggestions: [],
            noSuggestions: false,
            alwaysRenderSuggestions: false,
            isFocused: false,

        };
        this.getSelectedValue = this.getSelectedValue.bind(this);
        this.addSelectedValue = this.addSelectedValue.bind(this);

    }

    escapeRegexCharacters(str) {
        if (str) {
            return (str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).toLowerCase();
        } else {
            return '';
        }
    }

    getSuggestions(value) {
        const { modifierCommon, modifiers } = this.props;
        const escapedValue = this.escapeRegexCharacters(value.trim());
        let getResult = modifierCommon.map((x) => { return x });
        let getIndex, filteredOption = [];
        modifiers && modifiers.length > 0 && modifiers.map((data, index1) => {
            data && data.modifierGroups.length > 0 && data.modifierGroups.map((item, index2) => {
                if (item && item.modifierCommonId) {
                    getIndex = modifierCommon.length > 0 && modifierCommon.findIndex(o => (item.modifierCommonId === o.id));
                    if (getIndex !== -1) {
                        let resultIndex = getResult.findIndex(o => (item.modifierCommonId === o.id));
                        if (resultIndex !== -1) getResult.splice(resultIndex, 1);
                    }
                }
            });
        });
        if (getResult && getResult.length > 0) {
            getResult = getResult.filter(i => i.isActive === "true");
            getResult.map((item, index) => {
                filteredOption.push({
                    value: item.id,
                    label: item.modifierName
                });
            });
        }

        if (escapedValue === '') {
            filteredOption = filteredOption.sort(() => 0.5 - Math.random()).slice(0, 5);
            return (filteredOption);
        }

        const regex = new RegExp(escapedValue, 'i');
        return filteredOption.filter(modifier => regex.test(modifier.label));
    }

    renderSuggestion(suggestions, { query }) {
        const suggestionText = `${suggestions.label}`;
        const matches = match(suggestionText, query, true);
        const parts = parse(suggestionText, matches);

        return (
            <div>
                {
                    parts.map((part, index) => {
                        return part.highlight ? <span className={s.highlight} key={index}>{part.text}</span> : <span key={index}>{part.text}</span>;
                    })
                }
            </div>
        );
    }

    renderSuggestionsContainer({ containerProps, children, query, name }) {
        return (
            <div>
                <div {...containerProps}>
                    {children}
                </div>
            </div>
        );
    }

    getSuggestionValue(suggestion) {
        return suggestion.label;
    }

    async getSelectedValue(event, { suggestion }) {
        const { change } = this.props;
        await change('modifierGroupId', suggestion.value);
    }

    async addSelectedValue() {
        const { modifierGroupId, getExistingModifier, change, openItemModal, menuId, subMenuId } = this.props;

        if (modifierGroupId) {
            await getExistingModifier(modifierGroupId);
            this.setState({ value: "" });
            await change('modifierGroupId', '');
        } else {
            openItemModal(menuId, subMenuId)
        }

    }

    onSuggestionsFetchRequested = ({ value }) => {
        let suggestions = this.getSuggestions(value);
        const isInputBlank = value.trim() === '';
        const noSuggestions = !isInputBlank && suggestions.length === 0;
        this.setState({
            suggestions,
            noSuggestions
        });
    }

    onSuggetionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    }

    shouldRenderSuggestions(value) {
        if (value) {
            return true;
        } else {
            return value.length >= 0;
        }

    }

    onChange = (event, { newValue, method }) => {
        const shouldUpdateSelectedValue = method === "type" || method === "enter" || method === "click";
        shouldUpdateSelectedValue && this.setState({
            value: {
                label: newValue
            },
        });

    };

    onFocus = (event) => {
        const { input } = this.props;
        this.setState({
            isFocused: true
        });

        if (input) {
            input.onFocus(event);
        }
    }

    storeInputReference = autosuggest => {
        if (autosuggest !== null) {
            this.input = autosuggest.input;
        }
    }


    renderAutoSuggest = ({
        input,
        suggestions,
        type,
        inputProps,
        className,
        meta: { touched, error },
    }) => {
        const { noSuggestions } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <div className='autosuggest'>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    inputProps={inputProps}
                    onSuggestionSelected={this.getSelectedValue}
                    focusInputOnSuggestionClick={false}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                    ref={this.storeInputReference}
                />
                {
                    noSuggestions && <div className={s.noResult}>
                        <FormattedMessage {...messages.noResult} />
                    </div>
                }
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </div>

        );
    }
    render() {
        const { value, suggestions } = this.state;
        const { loading } = this.props;
        const { formatMessage } = this.props.intl;
        const inputProps = {
            placeholder: formatMessage(messages.searchHere),
            value: value && value.label != undefined ? value.label : '',
            onChange: this.onChange,
            onFocus: this.onFocus,
            disabled: loading
        };

        return (
            <div>
                <Field
                    type="text"
                    component={this.renderAutoSuggest}
                    name="modifierCommonType"
                    suggestions={suggestions}
                    inputProps={inputProps}
                />
                <span className={cx(s.displayInline, 'displayInlineMModifierRTL')}>
                    <Button className={cx(rs.button, rs.btnPrimaryBorder, s.displayInlineBlock, s.modifierGroupBtn, s.marginLeft)} onClick={() => this.addSelectedValue()}>
                        <FormattedMessage {...messages.addLabel} />
                    </Button>
                </span>
            </div>

        );
    }
}

ModifierCommonType = reduxForm({
    form: 'AddItemForm',
    destroyOnUnmount: false
})(ModifierCommonType);

const selector = formValueSelector('AddItemForm');

const mapState = (state) => ({
    modifierCommon: state.common.modifier,
    modifiers: selector(state, 'modifiers'),
    modifierGroupId: selector(state, 'modifierGroupId'),
    menuId: selector(state, 'menuId'),
    subMenuId: selector(state, 'subMenuId'),
    loading: state.loader && state.loader.updateLoading
});

const mapDispatch = {
    change,
    getExistingModifier,
    openItemModal,

};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(ModifierCommonType)));
