/* global google */
import React, { Component } from "react";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon
} from "react-google-maps";

import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import { change } from 'redux-form';
import { connect } from 'react-redux';
import Autocomplete from 'react-google-autocomplete';
import s from './ManageLocation.css';


class DrawPolygon extends Component {

  constructor(props) {
    super(props);
    this.state = {
      paths: [],
      boundry: [],
      lat: 37.0902,
      lng: -95.7129,
      addPolygon: true,
      shapes: []
    };
    
    this.shapes = [];
    this.handleOverlayComplete = this.handleOverlayComplete.bind(this);
    this.deletePolygon = this.deletePolygon.bind(this);
  }

  handleOverlayComplete(e) {
    const shape = e.overlay;
    shape.type = e.type;
    shape.setEditable(true);
    this.shapes.push(shape);
  };

  toggleSelection(shape) {
    if (shape.getEditable() === true) shape.setEditable(false);
    else shape.setEditable(true);
  }

  deletePolygon(e) {
    const { change } = this.props;
    this.shapes.forEach(shape => shape.setMap(null));
    this.setState({
      addPolygon: true
    });
    change("ManageLocationForm", 'path', '');
  }

  getCoordinates(polygon, polygonComplete) {

    const { change } = this.props;
    let self =this;

    this.setState({
      addPolygon: false
    });
    
    if (polygon && polygon.getPath()) {

      //polygon finish drawing
      if (polygonComplete == 'yes') {

        let getLatlng = polygon.getPath().getArray();
        let latlng = [];
        

        if (getLatlng && getLatlng.length > 0) {
          
          getLatlng.map((item, index) => {
            latlng.push({ lat: item.lat(), lng: item.lng() });
            return latlng;
          });
          this.setState({
            boundry: latlng
          });
          change("ManageLocationForm", 'path', latlng);
        }
      }

      //new lat and lng
      google.maps.event.addListener(polygon.getPath(), 'insert_at', async function (
        index,
        obj
      ) {

        let getLatlng = polygon.getPath().getArray();
        let latlng = [];
        if (getLatlng && getLatlng.length > 0) {
          
          getLatlng.map((item, index) => {
            latlng.push({ lat: item.lat(), lng: item.lng() });
            return latlng;
          });
          self.setState({
            boundry: latlng
          });
          change("ManageLocationForm", 'path', latlng);

        }
      })

      //update lat and lng
      google.maps.event.addListener(polygon.getPath(), 'set_at', function (
        index,
        obj
      ) {
        let getLatlng = polygon.getPath().getArray();
        let latlng = [];
        if (getLatlng && getLatlng.length > 0) {
          
          getLatlng.map((item, index) => {
            latlng.push({ lat: item.lat(), lng: item.lng() });
            return latlng;
          });
          self.setState({
            boundry: latlng
          });
          change("ManageLocationForm", 'path', latlng);

        }
      });

      //undo lat and lng
      google.maps.event.addListener(polygon.getPath(), 'remove_at', function (
        index,
        obj
      ) {

        let getLatlng = polygon.getPath().getArray();
        let latlng = [];
        if (getLatlng && getLatlng.length > 0) {
         
          getLatlng.map((item, index) => {
            latlng.push({ lat: item.lat(), lng: item.lng() });
            return latlng;
          });
          self.setState({
            boundry: latlng
          });
          change("ManageLocationForm", 'path', latlng);
        }

      });

    }

  };

  render() {

    const { paths, addPolygon, lat, lng } = this.state;

    return (

      <GoogleMap
        defaultZoom={this.props.zoom}
        defaultCenter={{ lat: lat, lng: lng }}
      >
        {/* <Autocomplete
          style={{width: '90%'}}
          onPlaceSelected={(place) => {
            this.autoSearch(place.geometry.location.lat(),place.geometry.location.lng())
            
          }}
      /> */}
      <a onClick={this.deletePolygon} className={'link'}>Remove Location</a>

        <Polygon
          ref={(ref) => { this.ref = ref; }}
          path={paths}
          key={1}
          editable={true}
          draggable={true}
          options={{
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: "blue",
            fillOpacity: 0.35,
          }}
          onMouseUp={(polygon) => this.getCoordinates(this.ref)}
          onDragEnd={(polygon) => this.getCoordinates(this.ref)}
        />
        {addPolygon &&
          <DrawingManager
            defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
            defaultOptions={{
              drawingControl: true,
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.POLYGON]
              },

              polygonOptions: {
                fillColor: "#FF0000",
                strokeColor: "#FF0000",
                fillOpacity: 0.35,
                strokeWeight: 1,
                clickable: true,
                editable: true,
                draggable: true,
                zIndex: 1,
              },
            }}
           
            onOverlayComplete={(e) => {this.handleOverlayComplete(e)}}
            onPolygonComplete={(polygon) => this.getCoordinates(polygon, 'yes')}
          />
        }
      </GoogleMap>
    );
  }
}

const mapState = state => ({
  // path: state
});

const mapDispatch = { change };

export default connect(mapState, mapDispatch)(withScriptjs(withGoogleMap(DrawPolygon)));

