import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import { closeSubMenuModal } from '../../siteadmin/modalActions';
import { loadAccount } from '../userAccount/userAccount';

function addSubMenu(values) {

	let query = gql`
	query($currentPage: Int, $searchList: String, $menuId: Int) {
		getAllSubMenu(currentPage: $currentPage, searchList: $searchList, menuId: $menuId){
		  status
		  errorMessage
		  menuName
		  count
		  results {
			id
			shopId
			menuId
			name
			isEnable
			item {
				id
				subMenuId
				menuId
				itemName
				itemDescription
				price
				tax
				image
				itemType
				isEnable
			}
		  }
		}  
	  }
	  `;

	return async (dispatch, getState, { client }) => {

		try {

			if (values.id) {

				const mutation = gql`mutation ($id: Int, $menuId: Int, $name: String, $isEnable: String){
					updateSubMenu(id: $id, menuId: $menuId, name: $name, isEnable: $isEnable){
							status
							errorMessage
						}
                    }`;

				const { data } = await client.mutate({
					mutation,
					variables:
						values,
					refetchQueries: [{ query, variables: { currentPage: 1, searchList: '', menuId: values.menuId } }]
				});

				if (data && data.updateSubMenu && data.updateSubMenu.status == 200) {
					toastr.success('Success', 'Your changes has been updated successfully');
					dispatch(closeSubMenuModal());
					dispatch(loadAccount())

				} else {
					toastr.error('Error', data.updateSubMenu.errorMessage);
				}

			} else {

				const mutation = gql`mutation ($menuId: Int, $name: String, $isEnable: String){
					addSubMenu(menuId: $menuId, name: $name, isEnable: $isEnable){
							status
							errorMessage
						}
                    }`;

				const { data } = await client.mutate({
					mutation,
					variables:
						values,
					refetchQueries: [{ query, variables: { currentPage: 1, searchList: '', menuId: values.menuId } }]

				});

				if (data && data.addSubMenu && data.addSubMenu.status == 200) {
					toastr.success('Success', 'A new Sub Menu added successfully');
					dispatch(closeSubMenuModal());
				} else {
					toastr.error('Error', data.addSubMenu.errorMessage);
				}
			}

		} catch (error) {
			console.log(error)
			toastr.error('Error', error);
		}
	}
}

export default addSubMenu;