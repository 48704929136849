import gql from 'graphql-tag';
import {
    SHOP_USER_STATUS_CHANGE_START,
    SHOP_USER_STATUS_CHANGE_SUCCESS,
    SHOP_USER_STATUS_CHANGE_ERROR
} from '../../../constants/index';
import { loadAccount } from './userAccount';
import { toastr } from 'react-redux-toastr';

export default function shopUserActivityChange(isActive) {
    return async (dispatch, getState, { client }) => {

        const mutation = gql`
        mutation ($isActive: Boolean) {
            shopUserActivityChange (isActive: $isActive) {
                status
                errorMessage
            }
          }
        `;

        dispatch({
            type: SHOP_USER_STATUS_CHANGE_START
        })

        try {

            const { data } = await client.mutate({
                mutation,
                variables: {
                    isActive : isActive
                },
            })

            if (data && data.shopUserActivityChange && data.shopUserActivityChange.status === 200) {
                dispatch({
                    type: SHOP_USER_STATUS_CHANGE_SUCCESS
                })

                dispatch(loadAccount())

            } else {
                dispatch({
                    type: SHOP_USER_STATUS_CHANGE_ERROR
                });
                dispatch(loadAccount())
                toastr.error('Error!', data && data.shopUserActivityChange && data.shopUserActivityChange.errorMessage);

            }

        } catch (error) {
            dispatch({
                type: SHOP_USER_STATUS_CHANGE_ERROR
            });
        }
    }
}