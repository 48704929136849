import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import cx from 'classnames';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';
// style
import s from './CommonSettingsManagement.css';
// components
import CommonSettingModel from './CommonSettingModel/CommonSettingModel';
import CustomPagination from '../../CustomPagination';
// images
import TrashIcon from '../../../../public/Icons/bin.svg';
// redux actions
import { openCommonSettingsModal } from '../../../actions/siteadmin/modalActions';
import { updateCommonSettingStatus, deleteCommonSetting } from '../../../actions/siteadmin/CommonSettings/commonSettingFormAction';
// helpers
import messages from '../../../locale/messages';

class CommonSettingsManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1
        };
        
        this.paginationData = this.paginationData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleStatusUpdate = this.handleStatusUpdate.bind(this);
    }

    paginationData(currentPage) {
        const { settingsData: { loading, refetch } } = this.props;  
        if (!loading) {
            refetch({
                currentPage
            });

            this.setState({ currentPage });
        }
    }

    async handleDelete(id, typeId) {
        const { settingsData: { loading, refetch }, deleteCommonSetting } = this.props;    
        const deleteSettingResponse = await deleteCommonSetting(id, typeId);
        
        if (!loading && deleteSettingResponse && deleteSettingResponse.status === 200) {
            refetch({
                currentPage: 1
            });

            this.setState({ currentPage: 1 });
        }
    }

    async handleStatusUpdate(id, typeId, isEnable) {
        const { settingsData: { loading, refetch }, updateCommonSettingStatus } = this.props;
        const settingStatusUpdateResponse = await updateCommonSettingStatus(id, typeId, isEnable === 'true');
        
        if (!loading && settingStatusUpdateResponse && settingStatusUpdateResponse.status === 200) {
            this.setState({ currentPage: 1 });

            await refetch({
                currentPage: 1
            });
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { typeData, settingsData: { loading, getAdminCommonSettingData }, openCommonSettingsModal } = this.props;
        const { currentPage } = this.state;
 
        return (
            <div className={cx(s.widthInner, s.tableSection, 'widthInnerNoBorder', 'whiteDropdown')}>
                <CommonSettingModel paginationData={this.paginationData} />
                <div className={s.container}>
                    <div className={cx(s.padding2, s.paddingTop2, s.textAlignRight, s.tabelExport, 'textAlignLeftRTL')}>
                        <Button className={cx(s.addlinkBtn)} onClick={() => openCommonSettingsModal('CommonSettingsForm', { typeId: typeData.id, typeLabel: typeData.typeLabel })}>
                            <FormattedMessage {...messages.addNewLabel} />
                        </Button>
                    </div>
                    <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
                        <Table className="table">
                            <thead>
                                <tr>
                                    <th scope="col"><FormattedMessage {...messages.idLabel} /></th>
                                    <th scope="col"><FormattedMessage {...messages.itemName} /></th>
                                    <th scope="col"><FormattedMessage {...messages.status} /></th>
                                    <th scope="col"><FormattedMessage {...messages.action} /></th>
                                    <th scope="col"><FormattedMessage {...messages.deleteAction} /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getAdminCommonSettingData && getAdminCommonSettingData.results && getAdminCommonSettingData.count > 0 
                                        && getAdminCommonSettingData.results.settings && getAdminCommonSettingData.results.settings.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td data-label={formatMessage(messages.idLabel)}>{item.id}</td>
                                                <td data-label={formatMessage(messages.itemName)}>{item.itemName}</td>
                                                <td data-label={formatMessage(messages.status)}>
                                                    <select value={String(item.isEnable)} onChange={(e) => { this.handleStatusUpdate(item.id, item.typeId, e.target && e.target.value) }} className={s.selectInput}>
                                                        <option value={'true'}>{formatMessage(messages.enable)}</option>
                                                        <option value={'false'}>{formatMessage(messages.disable)}</option>
                                                    </select>
                                                </td>
                                                <td data-label={formatMessage(messages.action)}>
                                                    <Button variant="link" className={s.iconBtn} onClick={() => openCommonSettingsModal('CommonSettingsForm', { ...item, typeLabel: typeData.typeLabel })}>
                                                        <FormattedMessage {...messages.editAction} />
                                                    </Button>
                                                </td>
                                                <td className={s.trashPadding} data-label={formatMessage(messages.deleteAction)}>
                                                    <Button className={s.iconBtn} onClick={() => this.handleDelete(item.id, item.typeId)}>
                                                        <img src={TrashIcon} className={cx(s.editIcon, 'trashIconRTL')} />
                                                        <span className={s.vtrMiddle}>
                                                            <FormattedMessage {...messages.deleteAction} />
                                                        </span>
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    getAdminCommonSettingData && getAdminCommonSettingData.count === 0 && <tr>
                                        <td colSpan={4}>
                                            <FormattedMessage {...messages.noResult} />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                    {
                        !loading && getAdminCommonSettingData && getAdminCommonSettingData.count > 0 && <div className={cx(s.space5, s.spaceTop5)}>
                            <CustomPagination
                                total={getAdminCommonSettingData && getAdminCommonSettingData.count}
                                currentPage={currentPage}
                                defaultCurrent={1}
                                defaultPageSize={10}
                                change={this.paginationData}
                                paginationLabel={formatMessage(messages.settings)}
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapState = (state) => ({});

const mapDispatch = {
    openCommonSettingsModal,
    updateCommonSettingStatus,
    deleteCommonSetting
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(CommonSettingsManagement)));