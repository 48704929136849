import React from 'react';
import PropTypes from 'prop-types';

import withStyles from 'isomorphic-style-loader/withStyles';
import s from './LoginModal.css';
import { Modal } from 'react-bootstrap';

import { connect } from 'react-redux';
import { closeLoginModal } from '../../../actions/modalActions';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import LoginForm from '../LoginForm/LoginForm';

//Locale
import messages from '../../../locale/messages';

class LoginModal extends React.Component {
    static propTypes = {
        closeLoginModal: PropTypes.func,
        loginModal: PropTypes.bool,
        formatMessage: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            loginModalStatus: false
        }
    }

    componentDidMount() {
        const { loginModal } = this.props;
        if (loginModal) {
            this.setState({ loginModalStatus: true })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps)  {
        const { loginModal } = nextProps;
        if (loginModal) {
            this.setState({ loginModalStatus: true })
        } else {
            this.setState({ loginModalStatus: false })
        }
    }

    handleClick() {
        const { closeLoginModal } = this.props;
        dispatch(closeLoginModal());
    }

    render() {
        const { closeLoginModal, siteName } = this.props;
        const { loginModalStatus } = this.state;
        return (
            <div >
                <Modal show={loginModalStatus} animation={false} onHide={closeLoginModal} className={cx('eatsLoginModal', 'logonMargin')}>
                    <div className={s.carImage}></div>
                    <div className={s.sectionBlock}>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <div>
                                <h1 className={s.titleText}>
                                    {siteName}
                                </h1>
                            </div>
                            <LoginForm />
                        </Modal.Body>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapState = state => ({
    loginModal: state.modalStatus.isLoginModalOpen,
    siteName: state.siteSettings.data.siteName
})

const mapDispatch = {
    closeLoginModal
};

export default withStyles(s)(connect(mapState, mapDispatch)(LoginModal))