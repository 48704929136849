import gql from 'graphql-tag';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { toastr } from 'react-redux-toastr';
import { closeCommonSettingsModal } from '../modalActions';

export default function addCommonSettings(values) {
    return async (dispatch, getState, { client }) => {
        dispatch(setLoaderStart('CommonSettings'));

        const mutation = gql`
            mutation addCommonSettings($id: Int, $itemName: String!, $typeId: Int!) {
                addCommonSettings(id: $id, itemName: $itemName, typeId: $typeId) {
                    status
                    errorMessage
                }
            }          
        `;

        try {
            let errorMessage;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    itemName: values.itemName,
                    typeId: values.typeId,
                    id: values.id
                }
            });

            if (data && data.addCommonSettings && data.addCommonSettings.status === 200) {
                dispatch(closeCommonSettingsModal('CommonSettings'));
                if (values && values.id) {
                    toastr.success('Success!', 'The setting has been updated successfully.');
                } else {
                    toastr.success('Success!', 'The setting has been created successfully.');
                }
                dispatch(setLoaderComplete('CommonSettings'))
                return {
                    status: 200
                };
            } else {
                errorMessage = data.addCommonSettings.errorMessage || 'Oops! Something went wrong.';
                dispatch(setLoaderComplete('CommonSettings'));
                toastr.error('Error!', errorMessage);
                return {
                    status: 400
                };
            }
        } catch (error) {
            dispatch(setLoaderComplete('CommonSettings'));
            toastr.error('Error', error);
            return {
                status: 400
            };
        }
    }
}


export function updateCommonSettingStatus(id, typeId, isEnable) {
    return async (dispatch, getState, { client }) => {
        const mutation = gql `
            mutation updateCommonSettingStatus($id: Int!, $typeId: Int!, $isEnable: Boolean) {
                updateCommonSettingStatus(id: $id, typeId: $typeId, isEnable: $isEnable) {
                    status
                    errorMessage
                }
            }
       `;
            console.log('id, typeId, isEnable', id, typeId, isEnable)
        try {
            let errorMessage;
            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    typeId,
                    isEnable
                }
            });

            if (data && data.updateCommonSettingStatus && data.updateCommonSettingStatus.status === 200) {
                toastr.success('Success!', 'The setting status has been updated successfully!');
                return {
                    status: 200
                };
            } else {
                errorMessage = data.updateCommonSettingStatus.errorMessage || 'Oops! Something went wrong! Please try again.';
                toastr.error("Error!", errorMessage);
                return {
                    status: 400
                };
            }
        } catch (error) {
            toastr.error('Error', error)
            return {
                status: 400
            };
        }

    }
}

export function deleteCommonSetting(id, typeId) {
    return async ( dispatch, getState, { client }) => {
        const mutation = gql`
            mutation ($id: Int!, $typeId: Int!){
                deleteCommonSetting(id: $id, typeId: $typeId) {
                status
                errorMessage
                }
            }
        `;

        try {
            let errorMessage;
            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    typeId
                }
            });

            if (data && data.deleteCommonSetting && data.deleteCommonSetting.status === 200) {
                toastr.success('Success!', 'The setting has been deleted Successfully!');
                return {
                    status: 200
                };
            } else {
                errorMessage = data.deleteCommonSetting.errorMessage || 'Oops! Something went wrong! Please try again.';
                toastr.error("Error!", errorMessage);
                return {
                    status: 400
                };
            }
        } catch (error) {
            toastr.error('Error!', error);
            return {
                status: 400
            };
        }
    }
}
