import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import {
	UPDATE_SITE_SETTINGS_START,
	UPDATE_SITE_SETTINGS_ERROR,
	UPDATE_SITE_SETTINGS_SUCCESS
} from '../../constants/index';

export default function updateFavIcon(favicon) {

	return async (dispatch, getState, { client }) => {

		dispatch({
			type: UPDATE_SITE_SETTINGS_START
		})

		try {
			const mutation = gql`
            mutation updateFavIcon(
                $favicon: String
                ) {
					updateFavIcon(
                     favicon: $favicon
                    ){
                        status
                        errorMessage
                    }
                }
            `;

			const { data } = await client.mutate({
				mutation,
				variables: {
					favicon
				}
			})

			if (data && data.updateFavIcon && data.updateFavIcon.status == 200) {

				dispatch({
					type: UPDATE_SITE_SETTINGS_SUCCESS
				})
				// await dispatch(siteSettings())
				toastr.success('Success', 'Site settings changes have been successfully applied')
			} else {
				dispatch({
					type: UPDATE_SITE_SETTINGS_ERROR
				})
				toastr.error('Oops!', 'Something went wrong')
			}
		} catch (err) {
			dispatch({
				type: UPDATE_SITE_SETTINGS_ERROR
			})
			toastr.error('Oops!', 'Something went wrong')
		}

	}
}