exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3NjWI {\n    display: inline-block;\n    margin-right: 12px;\n    position: relative;\n    top: 9px;\n}\n._1mavW {\n    text-align: right;\n}\n.KojO6 {\n    font-size: 16px;\n    color: #484848;\n}\n._36O0r{\n    display: inline-block;\n    position: relative;\n    top: 9px;\n}\n@media screen and (max-width: 1200px) {\n    ._1mavW {\n        text-align: center;\n    }\n    ._3NjWI {\n        display: block;\n        margin-right: 0px;\n        margin-bottom: 24px;\n    }\n    ._267vT {\n        padding: 12px 15px !important;\n    }\n}", ""]);

// exports
exports.locals = {
	"verifyBtn": "_3NjWI",
	"textVrify": "_1mavW",
	"labelColor": "KojO6",
	"displayInine": "_36O0r",
	"btnPadding": "_267vT"
};