import gql from 'graphql-tag';
import {
    USER_DELETE_SUCCESS,
    USER_DELETE_START,
    USER_DELETE_ERROR
} from '../../constants/index';
import { toastr } from 'react-redux-toastr';

export default function deleteUser(id, userType) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: USER_DELETE_START,
            payload: {
                deleteLoading: true
            }
        });
        
        try {
            let errorMessage;

            let mutation = gql`
                mutation deleteUser($id: String!) {
                    deleteUser(id: $id) {
                        status
                        errorMessage
                    }
                }
            `;

            const { data } = await client.mutate({
                mutation,
                variables : {
                    id
                }
            });
            
            if (data && data.deleteUser && data.deleteUser.status === 200) {
                dispatch({
                    type: USER_DELETE_SUCCESS,
                    payload: {
                        deleteLoading: false
                    }
                });
                toastr.success('Success', `${userType === 2 ? 'The driver' : 'The shopper'} has been deleted successfully.`);
                return {
                    status: data.deleteUser.status
                }
            } else {
                dispatch({
                    type: USER_DELETE_ERROR,
                    payload: {
                        deleteLoading: false
                    }
                });
                errorMessage = (data && data.deleteUser && data.deleteUser.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
                return {
                    status: 400
                }
            }
        } catch(err) {
            toastr.error('Error!', err);
            dispatch({
                type: USER_DELETE_ERROR,
                payload: {
                    deleteLoading: false
                }
            });
            return {
                status: 400
            }
        }

    }
};