import React from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import s from './ConfigSettings.css'
import withStyles from 'isomorphic-style-loader/withStyles';
//components
import Loader from '../../../components/Common/Loader/Loader';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import ConfigSettingsForm from '../../../components/SiteAdmin/ConfigSettingsForm/ConfigSettingsForm';
import getSiteSettings from './getSiteSettings.graphql';

class ConfigSettings extends React.Component {

    render() {
        const { getSiteSettings: { loading, getSiteSettings, refetch }, title } = this.props;
        let siteSettingsCollection = {}

        if (!loading) {
            getSiteSettings.map((item) => {
                siteSettingsCollection[item.name] = item.value
            })
            return (
                <div className={s.root}>
                    <div className={cx(s.container, 'paddingBottomLg')}>
                        <div className={s.heading}>
                            <FormattedMessage {...messages.manageSiteConfig} />
                        </div>
                        <div className={s.spaceTop5}>
                            <ConfigSettingsForm refetch={refetch} initialValues={siteSettingsCollection} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return <Loader type={"page"} show={loading} />
        }
    }
}

export default compose(
    withStyles(s),
    graphql(getSiteSettings, {
        name: 'getSiteSettings',
        options: (props) => ({
            variables: {
                name: 'config_settings',
            },
            fetchPolicy: 'network-only'
        })
    }
    )
)(ConfigSettings)