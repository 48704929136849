import gql from 'graphql-tag';
import {
	SHOP_ITEM_UPDATE_START,
	SHOP_ITEM_UPDATE_SUCCESS,
	SHOP_ITEM_UPDATE_ERROR
} from '../../../constants/index';
import { initialize } from 'redux-form';
import { openSubMenuModal } from '../../../actions/siteadmin/modalActions';
import { toastr } from 'react-redux-toastr';

let query = gql`
query getSubMenu($id: Int!){
	getSubMenu( id: $id){
	   id
	   menuId
	   name
	   isEnable
	}
  }`;

export default function editItem(id) {
	return async (dispatch, getState, { client }) => {
		try {
			let errorMessage;

			const { data } = await client.query({
				query,
				variables: {
					id
				},
				fetchPolicy: 'network-only'
			});

			if (data && data.getSubMenu) {
				dispatch(initialize('AddSubMenuForm', data.getSubMenu));
				dispatch(openSubMenuModal());

			} else {

				errorMessage = (data && data.getSubMenu && data.getSubMenu.errorMessage) || "Oops! Something went wrong. Please try again.";
				toastr.error('Error!', errorMessage);
			}
		} catch (err) {
			toastr.error('Error!', err);
		}
	}
};
