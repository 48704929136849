import { addUpdateDeliveryVehicle } from '../../../../actions/siteadmin/DeliveryVehicle/addUpdateDeliveryVehicle';
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {
    if (!values.vehicleImage) {
        toastr.error('Error!', "Vehicle type image is required.");
        return;
    }

    await dispatch(
        addUpdateDeliveryVehicle(
            values.id, 
            values.vehicleName,
            values.vehicleImage,
            values.description,
            values.isActive
        )
    );
}

export default submit;