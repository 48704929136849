import { reset } from 'redux-form';

import fetch from 'node-fetch';

import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {
    const query = `
    mutation (
        $currentPassword: String,
        $newPassword: String,
        $confirmNewPassword: String    
    ) {
        changePassword (
            currentPassword: $currentPassword,
            newPassword: $newPassword,
            confirmNewPassword: $confirmNewPassword
        ){
            status
            errorMessage
        }
    }`;


    const response = await fetch('/graphql', {
        method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      variables: {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          confirmNewPassword: values.confirmNewPassword
      }
    }),
    credentials: 'include',
    });

    const { data } = await response.json();

    if (data && data.changePassword && data.changePassword.status === 200) {
        toastr.success("Password", "Password is updated successfully")
        dispatch(reset('ChangePassword'));
    } else {
        toastr.error("Password",data && data.changePassword && data.changePassword.errorMessage)
    }
}

export default submit;