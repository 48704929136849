import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import s from './ManageServiceFee.css'

import {
    Row,
    FormGroup,
    Col,
    Form,
    InputGroup,
    Container
} from 'react-bootstrap';
import cx from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages'
import Loader from '../../Common/Loader/Loader';
import validate from './validate';
import submit from './submit';
import { getCurrencySymbol } from '../../../helpers/currencyConvertion';


class ManageServiceFee extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            feeType: 'flatFee'
        }
    }

    static defaultProps = {
        loading: false
    };
    componentDidMount() {
        const { feeType } = this.props;
        this.setState({
            feeType
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps)  {
        const { feeType } = nextProps;
        this.setState({
            feeType
        })
    }


    renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={s.space3}>
                <Form.Label>{label}</Form.Label>
                <Form.Control as="select" {...input} placeholder={placeholder} className={cx(fieldClass, s.formControlInput)}>
                    {children}
                </Form.Control>
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }
    handleFeeType(e) {
        let feeType = e.target.value;
        this.setState({
            feeType
        });
    }

    renderFieldDiscount = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, isPrice, currency, maxlength }) => {
        const { currentLocale } = this.props;
        const { formatMessage } = this.props.intl
        return (
            <div className={cx('inputFormAddon', 'addonBorder')}>
                <Form.Group className={s.space3}>
                    <Form.Label>{label}</Form.Label>
                    <InputGroup>
                        {
                            isPrice == 1 && <InputGroup.Append>
                                <InputGroup.Text>
                                    {
                                        getCurrencySymbol(currency, currentLocale)
                                    }
                                </InputGroup.Text>
                            </InputGroup.Append>
                        }
                        <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength} />
                        {
                            isPrice != 1 && <InputGroup.Append>
                                <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                        }
                    </InputGroup>
                    {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                </Form.Group>
            </div>
        )
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { handleSubmit, loading, submitting, currency, distanceUnit } = this.props;
        const { feeType } = this.state;

        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col xl={5} lg={8} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <form onSubmit={handleSubmit(submit)}>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Field
                                                    name="feeType"
                                                    placeholder={formatMessage(messages.feeType)}
                                                    component={this.renderSelectField}
                                                    label={formatMessage(messages.feeType)}
                                                    labelClass={s.labelText}
                                                    fieldClass={cx(s.formControlSelect)}
                                                    onChange={(e) => this.handleFeeType(e)}
                                                >
                                                    <option value={"flatFee"}>{formatMessage(messages.flatFee)}</option>
                                                    <option value={"distanceBasedFee"}>{formatMessage(messages.distanceBasedFee)}</option>
                                                </Field>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Field
                                                    name="deliveryFee"
                                                    type="text"
                                                    label={feeType == 'distanceBasedFee' ? formatMessage(messages.pickupFee) : formatMessage(messages.deliveryFee)}
                                                    placeholder={0}
                                                    component={this.renderFieldDiscount}
                                                    maxlength={8}
                                                    labelClass={s.labelText}
                                                    className={cx(s.formControlSelect, s.formControlInput)}
                                                    isPrice={1}
                                                    currency={currency}
                                                />
                                            </Col>

                                            {feeType == 'distanceBasedFee' &&
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <Field
                                                        name="dropFee"
                                                        type="text"
                                                        label={formatMessage(messages.dropFee)}
                                                        placeholder={0}
                                                        component={this.renderFieldDiscount}
                                                        maxlength={8}
                                                        labelClass={s.labelText}
                                                        className={cx(s.formControlSelect, s.formControlInput)}
                                                        isPrice={1}
                                                        currency={currency}
                                                    />
                                                </Col>
                                            }
                                            {feeType == 'distanceBasedFee' &&
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <Field
                                                        name="distanceFee"
                                                        type="text"
                                                        label={`${formatMessage(messages.distanceFee)} ${distanceUnit == 'km' ? "(per Km)" : "(per Mile)"}`}
                                                        placeholder={0}
                                                        component={this.renderFieldDiscount}
                                                        maxlength={8}
                                                        labelClass={s.labelText}
                                                        className={cx(s.formControlSelect, s.formControlInput)}
                                                        isPrice={1}
                                                        currency={currency}
                                                    />
                                                </Col>
                                            }

                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Field
                                                    name="bookingFee"
                                                    type="text"
                                                    label={formatMessage(messages.bookingFee)}
                                                    placeholder={"%"}
                                                    component={this.renderFieldDiscount}
                                                    labelClass={s.labelText}
                                                    className={cx(s.formControlSelect, s.formControlInput)}
                                                    isPrice={2}
                                                    maxlength={2}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Field
                                                    name="shopFee"
                                                    type="text"
                                                    label={formatMessage(messages.shopFee)}
                                                    placeholder={"%"}
                                                    component={this.renderFieldDiscount}
                                                    labelClass={s.labelText}
                                                    className={cx(s.formControlSelect, s.formControlInput)}
                                                    isPrice={2}
                                                    maxlength={2}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Field
                                                    name="driverFee"
                                                    type="text"
                                                    label={formatMessage(messages.driverFee)}
                                                    placeholder={"%"}
                                                    component={this.renderFieldDiscount}
                                                    labelClass={s.labelText}
                                                    className={cx(s.formControlSelect, s.formControlInput)}
                                                    isPrice={2}
                                                    maxlength={2}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                                                <FormGroup className={s.noMargin}>
                                                    <div>
                                                        <Loader
                                                            type={"button"}
                                                            label={formatMessage(messages.submitButton)}
                                                            show={loading}
                                                            buttonType={'submit'}
                                                            className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                                                            disabled={submitting || loading}
                                                            isSuffix={true}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

}

ManageServiceFee = reduxForm({
    form: 'ServiceFeeForm',
    validate,
    onSubmit: submit
})(ManageServiceFee)

const selector = formValueSelector('ServiceFeeForm');

const mapState = state => ({
    feeType: selector(state, 'feeType'),
    currency: state.siteSettings.data.currency,
    distanceUnit: state.siteSettings.data.distanceUnits,
    currentLocale: state.intl.locale,
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ManageServiceFee)));