import gql from 'graphql-tag';
import {
    CHECK_USER_AVAILABLE_START,
    CHECK_USER_AVAILABLE_SUCCESS,
    CHECK_USER_AVAILABLE_ERROR
} from '../../constants';

const query = gql`
query ($id:Int!) {
    checkUserAvailability (id:$id) {
      status        
    }
  }
`;

export function checkUserAvailability(id) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: CHECK_USER_AVAILABLE_START,
        });
        try {

            const { data } = await client.query({
                query,
                variables: { id },
                fetchPolicy: 'network-only',
            });

            if (data && data.checkUserAvailability && data.checkUserAvailability.status == 200) {
                dispatch({
                    type: CHECK_USER_AVAILABLE_SUCCESS,
                });
                return await true;
            } else {
                dispatch({
                    type: CHECK_USER_AVAILABLE_ERROR,
                });
                return await false;
            }

        } catch (error) {
            dispatch({
                type: CHECK_USER_AVAILABLE_ERROR,

            });
            return await false;
        }
    };
}