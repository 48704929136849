import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './TransactionContainer.css';
import TransactionManagement from '../../../components/Shop/TransactionManagement/TransactionManagement';

class TransactionContainer extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {

    const { type } = this.props;

    return (
      <div className={s.root}>
        <div className={s.container}>
          <TransactionManagement type={type}/>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(TransactionContainer);
