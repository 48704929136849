import { editCategory } from '../../../../actions/siteadmin/Category/editCategory';

async function submit(values, dispatch) {
    
    await dispatch(
        editCategory(
            values.id,
            values.categoryName,
            values.isActive,
            values.description
        )
    )
}

export default submit;