import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
// components
import AddCommonSettingForm from '../AddCommonSettingForm/AddCommonSettingForm';
// redux actions
import { closeCommonSettingsModal } from '../../../../actions/siteadmin/modalActions';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';

class CommonSettingModel extends React.Component {
  static defaultProps = {
    commonSettingModalStatus: false,
    commonSettingTypeLabel: ''
  };

  render() {
    const { closeCommonSettingsModal, commonSettingModalStatus, paginationData } = this.props;

    return (
      <div>
        <Modal show={commonSettingModalStatus} onHide={closeCommonSettingsModal}>
          <Modal.Header closeButton>
          <Modal.Title><FormattedMessage {...messages.dietaryType} /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddCommonSettingForm paginationData={paginationData} />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const selector = formValueSelector('CommonSettingsForm');

const mapState = (state) => ({
  commonSettingModalStatus: state.modalStatus.isCommonSettingModalOpen,
  commonSettingTypeLabel: selector(state, 'typeLabel')
});

const mapDispatch = {
  closeCommonSettingsModal,
};

export default connect(mapState, mapDispatch)(CommonSettingModel);
