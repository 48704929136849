import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl, ButtonToolbar, Button } from 'react-bootstrap';
import s from './ManageLocation.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import messages from '../../../locale/messages';
import CustomPagination from '../../CustomPagination';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import Link from '../../Link/Link';
import EditIcon from '../../../../public/Icons/edit.png';
import TrashIcon from '../../../../public/Icons/bin.svg'
import { connect } from 'react-redux';
import { deleteLocation } from '../../../actions/siteadmin/deleteLocation'

export class LocationList extends Component {

    static propTypes = {
        locationList: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.state = {
            currentPage: 1,
        }
        this.paginationData = this.paginationData.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this)
    }

    

    handleSearchClick(searchList) {
        const { locationList: { refetch }} = this.props
        let variables = {
          currentPage: 1,
          searchList: searchList
        }
        this.setState({ currentPage: 1 })
        refetch(variables)
    }
    
    handleSearchChange(e) {
        let self = this
        if(self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout)
        }
        self.setState({
            searchList: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function() {
            self.handleSearchClick(self.state.searchList)
            }, 450)
        })
    }

    async handleDelete(id, currentPage) {
        const { locationList: { refetch }, deleteLocation } = this.props;
        this.setState({ currentPage: 1 });
        let variables = { currentPage: 1 };
        await deleteLocation(id, currentPage);
        refetch(variables);
    }

    paginationData(currentPage) {
        const { locationList: { refetch } } = this.props;
        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
    }

    render() {
        const { locationList, locationList: { getLocationList } } = this.props;
        const { currentPage } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            
            <div className={s.widthInner}>
                <div className={s.searchInput}>
                <FormControl type='text' placeholder='Search' onChange={(e) => this.handleSearchChange(e)}  className={s.formControlInput} />
                </div>
                <div className={cx(s.padding2, s.paddingTop2, s.displayInlineBlock, s.rightSide)}>
                <Link to={"/siteadmin/manage-location"} className={cx(s.button, s.btnPrimary)} >{formatMessage(messages.addLocation)}</Link>
                </div>
                <div className={cx(s.tableCss, 'tableCss', 'tableSticky')}>
                    <Table className="table">
                        <thead>
                            <tr>
                                <th><FormattedMessage {...messages.id} /></th>
                                <th><FormattedMessage {...messages.location} /></th>
                                <th><FormattedMessage {...messages.description} /></th>
                                <th><FormattedMessage {...messages.status} /></th>
                                <th><FormattedMessage {...messages.action} /></th>
                                <th><FormattedMessage {...messages.deleteAction} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                locationList && locationList.getLocationList && locationList.getLocationList.LocationData && locationList.getLocationList.LocationData.length > 0 && locationList.getLocationList.LocationData.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.locationName}</td>
                                            <td>{item.description}</td>
                                            <td>{item.isActive==true?"Active":"Inactive"}</td>
                                            <td><Link to={'/siteadmin/edit-location/' + item.id}><FormattedMessage {...messages.edit} /></Link></td>
                                            <td className={s.trashPadding}>
                                                <ButtonToolbar>
                                                    <Button onClick={() => this.handleDelete(item.id, currentPage)} className={s.iconBtn}>
                                                        <img src={TrashIcon} className={cx(s.editIcon, 'trashIconRTL', 'editIconRTL')} />
                                                        <span className={s.vtrMiddle}>
                                                        <FormattedMessage {...messages.deleteAction} />
                                                        </span>
                                                    </Button>
                                                </ButtonToolbar>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                               locationList && locationList.getLocationList && locationList.getLocationList.LocationData && locationList.getLocationList.LocationData.length == 0 && (   
                                <tr>
                                    <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                                </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
                {
                        locationList && locationList.getLocationList && locationList.getLocationList.LocationData && locationList.getLocationList.LocationData.length > 0
                        && <div className={cx(s.space5, s.spaceTop5)}>
                            <CustomPagination
                                total={locationList.getLocationList.count}
                                currentPage={currentPage}
                                defaultCurrent={1}
                                defaultPageSize={10}
                                change={this.paginationData}
                                paginationLabel={locationList.getLocationList.count == 1 ?'Location':'Locations'}
                            />
                        </div>
                }
            </div>
        )
    }
}

const mapState = (state) => ({});
const mapDispatch = {
  deleteLocation
};

export default injectIntl(compose(
    withStyles(s),
    connect(mapState, mapDispatch)
)(LocationList));

