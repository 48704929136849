import addItem from '../../../../../actions/shop/menu/addItem';
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {
     let modifiers = JSON.stringify(values.modifiers);
     let tax = values.tax;
     if (!values.tax || (values.tax && values.tax.toString().trim() === '')) tax = null;
     let variables = Object.assign({}, values, {
          modifiers
     },
          {
               tax
          });

     if (!values.image) toastr.error('Error!', 'Please upload the image for food item');
     else await dispatch(addItem(variables));
}

export default submit;