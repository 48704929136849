/* global google */
import React, { Component } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon
} from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import { change } from 'redux-form';
import { connect } from 'react-redux';
import s from './ManageLocation.css';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';

class DrawPolygon extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: [],
      boundry: [],
      addPolygon: false
    };
    this.shapes = [];
    this.handleOverlayComplete = this.handleOverlayComplete.bind(this);
    this.deletePolygon = this.deletePolygon.bind(this);
  }

  handleOverlayComplete(e) {
    const shape = e.overlay;
    shape.type = e.type;
    shape.setEditable(true);
    this.shapes.push(shape);
  };

  toggleSelection(shape) {
    if (shape.getEditable() === true) shape.setEditable(false);
    else shape.setEditable(true);
  }

  deletePolygon(e) {
    const { change } = this.props;
    this.shapes.forEach(shape => shape.setMap(null));
    this.setState({
      addPolygon: true,
      path: [],
    });
    change("ManageLocationForm", 'path', '');
  }

  componentDidMount() {

    const { change, path } = this.props;
    this.setState({
      path: path,
      boundry: path
    });
    change("ManageLocationForm", 'path', path);
  }


  getCoordinates(polygon, add) {

    const { change, path, boundry } = this.props;
    var self = this;

    this.setState({
      addPolygon: false
    });

    if (polygon && polygon.getPath()) {

      if (add == 'add') {

        let getLatlng = polygon.getPath().getArray();
        let latlng = [];

        if (getLatlng && getLatlng.length > 0) {

          getLatlng.map((item, index) => {
            latlng.push({ lat: item.lat(), lng: item.lng() });
            return latlng;
          });

          this.setState({
            boundry: latlng
          });

          change("ManageLocationForm", 'path', latlng);
        }
      }


      //new lat and lng
      google.maps.event.addListener(polygon.getPath(), 'insert_at', async function (
        index,
        obj
      ) {

        let getLatlng = polygon.getPath().getArray();
        let latlng = [];
        if (getLatlng && getLatlng.length > 0) {
          getLatlng.map((item, index) => {
            latlng.push({ lat: item.lat(), lng: item.lng() });
            return latlng;
          });
          self.setState({
            boundry: latlng
          });

          change("ManageLocationForm", 'path', latlng);

        }
      })

      //update lat and lng
      google.maps.event.addListener(polygon.getPath(), 'set_at', function (
        index,
        obj
      ) {
        let getLatlng = polygon.getPath().getArray();
        let latlng = [];
        if (getLatlng && getLatlng.length > 0) {
          getLatlng.map((item, index) => {
            latlng.push({ lat: item.lat(), lng: item.lng() });
            return latlng;
          });
          self.setState({
            boundry: latlng
          });

          change("ManageLocationForm", 'path', latlng);
        }
      });

      //undo lat and lng
      google.maps.event.addListener(polygon.getPath(), 'remove_at', function (
        index,
        obj
      ) {

        let getLatlng = polygon.getPath().getArray();
        let latlng = [];
        if (getLatlng && getLatlng.length > 0) {
          getLatlng.map((item, index) => {
            latlng.push({ lat: item.lat(), lng: item.lng() });
            return latlng;
          });
          self.setState({
            boundry: latlng
          });

          change("ManageLocationForm", 'path', latlng);
        }

      });

    }

  };


  render() {
    const { addPolygon, path, boundry } = this.state;

    return (

    
        <GoogleMap defaultZoom={this.props.zoom} ref={map => {
          const bounds = new window.google.maps.LatLngBounds();
          boundry.map(x => {
            bounds.extend(new window.google.maps.LatLng(x.lat, x.lng));
          });
          map && map.fitBounds(bounds)
        }}>

          <a onClick={this.deletePolygon} className={'link'} >Remove Location</a>
          <Polygon
            ref={(ref) => { this.ref = ref; }}
            path={path}
            key={1}
            editable={true}
            draggable={true}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.35
            }}
            onMouseUp={(polygon) => this.getCoordinates(this.ref, 'add')}
            onDragEnd={(polygon) => this.getCoordinates(this.ref)}
          />
          {addPolygon &&
            <DrawingManager
              defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
              defaultOptions={{
                drawingControl: true,
                drawingControlOptions: {
                  position: google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: [google.maps.drawing.OverlayType.POLYGON]
                },

                polygonOptions: {
                  fillColor: "#FF0000",
                  strokeColor: "#FF0000",
                  fillOpacity: 0.35,
                  strokeWeight: 1,
                  clickable: true,
                  editable: true,
                  draggable: true,
                  zIndex: 1,
                },
              }}
              onOverlayComplete={this.handleOverlayComplete}
              onPolygonComplete={(polygon) => this.getCoordinates(polygon, 'add')}
            />
          }

        </GoogleMap>
      
    );
  }
}

const mapState = state => ({
  // path: state
});

const mapDispatch = { change };

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(withScriptjs(withGoogleMap(DrawPolygon)))));

