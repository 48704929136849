import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import normalizeCss from 'normalize.css';
import s from './Layout.css';
import Footer from '../../../Footer';
import Toaster from '../../../Toaster';
import { connect } from 'react-redux';
import RestaurantHeader from '../RestaurantHeader/RestaurantHeader';
import RestaurantSideMenu from '../../RestaurantSideMenu/RestaurantSideMenu';
import rs from '../../../restaurantCommon.css';
import history from '../../../../history';


class DashboardLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };
    render() {
        let location;
        if (history.location) {
            location = history.location.pathname
        }
        
        return (
            <div className={'restauurant'}>
                <Toaster />
                <div className={rs.restaurantsideNav}>
                    <RestaurantSideMenu location={location} />
                </div>
                <div className={cx(rs.restaurantMainContent, 'print-pagecontentWrapper', 'restaurantMainContentRTL')}>
                    <div className={rs.restaurantHeaderBg}>
                        <RestaurantHeader />
                    </div>
                    <div className={s.restaurantPaddingTop}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
};


const mapStateToProps = (state) => ({
    isRestaurant: state.user.restaurant
});

const mapDispatchToProps = {

};

export default withStyles(normalizeCss, s, rs)(connect(mapStateToProps, mapDispatchToProps)(DashboardLayout));