import React, { Component } from 'react';
import s from './AddCommonSettingForm.css';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { injectIntl } from 'react-intl';
// helpers
import validate from './validate';
import messages from '../../../../locale/messages';
// component
import Loader from '../../../Common/Loader';
// redux actions
import addCommonSettings from '../../../../actions/siteadmin/CommonSettings/commonSettingFormAction';

class AddCommonSettingForm extends Component {
    static defaultProps = {
        loading: false
    };

    constructor(props) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    async handleFormSubmit(values) {
        const { addCommonSettings, paginationData } = this.props;
        const addCommonSettingResponse = await addCommonSettings(values);
        if (addCommonSettingResponse && addCommonSettingResponse.status === 200) {
            paginationData(1);
        }
    }

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxLength }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} maxLength={maxLength} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    render() {
        const { error, handleSubmit, submitting, dispatch, loading, settingId } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div>
                <Form className={s.fullWidth} onSubmit={handleSubmit(this.handleFormSubmit)}>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop1}>
                            <Form.Group className={s.formGroup} >
                                <div>
                                    <Field
                                        name="itemName"
                                        type="text"
                                        placeholder={formatMessage(messages.itemName)}
                                        component={this.renderField}
                                        label={formatMessage(messages.itemName)}
                                        labelClass={s.labelText}
                                        fieldClass={s.formControlInput}
                                        maxLength={50}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                            <div className={cx(s.displayInlineBlock, s.rightSide, s.space1, 'floatLeftRTL')}>
                                <Loader
                                    type={"button"}
                                    label={settingId ? formatMessage(messages.update) : formatMessage(messages.submitButton)}
                                    show={loading}
                                    buttonType={'submit'}
                                    className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                                    disabled={submitting || loading}
                                    isSuffix={true}
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

}

AddCommonSettingForm = reduxForm({
    form: 'CommonSettingsForm',
    validate
})(AddCommonSettingForm);

const selector = formValueSelector('CommonSettingsForm');

const mapState = (state) => ({
    loading: state.loader.CommonSettings,
    settingId: selector(state, 'id')
});

const mapDispatch = {
    addCommonSettings
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AddCommonSettingForm)));