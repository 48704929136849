import React from 'react';
import OperationHours from './OperationHours';
import DashboardLayout from '../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action({ store, intl }) {
    const title = intl.formatMessage(messages.operationHours);

    //from Redux store
    let isAuthenticated = store.getState().runtime.isRestaurantAuthenticated;
    if (!isAuthenticated) {
        return {
            redirect: '/signup'
        }
    }

    return {
        title,
        component: <DashboardLayout>
            <OperationHours title={title} />
        </DashboardLayout>
    }
}

export default action;