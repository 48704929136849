import {
    SHOP_MODIFIER_ITEM_UPDATE_START,
    SHOP_MODIFIER_ITEM_UPDATE_SUCCESS,
    SHOP_MODIFIER_ITEM_UPDATE_ERROR
} from '../../../constants/index';
import getCartModifierItemQuery from './getCartModifierItem.graphql';

export default function getBookedModifierItem(modifierItemId) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: SHOP_MODIFIER_ITEM_UPDATE_START,
        });
        try {
            const { data: { getCartModifierItem } } = await client.query({
                query: getCartModifierItemQuery,
                variables: {
                    modifierItemId
                }
            });
            if (getCartModifierItem && getCartModifierItem.status === 200) {
                dispatch({
                    type: SHOP_MODIFIER_ITEM_UPDATE_SUCCESS,
                });

                return {
                    data: getCartModifierItem.result && getCartModifierItem.result.removeModifierItemId
                }
            }
        } catch (error) {
            dispatch({
                type: SHOP_MODIFIER_ITEM_UPDATE_ERROR,
            });
        }
    }
}