import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import s from './RestaurantHome.css';
import {
  Container
} from 'react-bootstrap';
import NewsBox from '../../../components/Shop/RestaurantHome/NewsBox/NewsBox';
import RestaurantPartners from '../../../components/Shop/RestaurantHome/RestaurantPartners/RestaurantPartners';
import RestaurantSlider from '../../../components/Shop/RestaurantHome/RestaurantSlider/RestaurantSlider';
import RestaurantBanner from '../../../components/Shop/RestaurantHome/RestaurantBanner/RestaurantBanner';
import getCuisineCategories from './getCuisineCategories.graphql';
import getAllPartnerHomepage from './getAllPartnerHomepage.graphql';
import Loader from '../../../components/Common/Loader/Loader';

class RestaurantHome extends React.Component {

  render() {

    const { getCuisine: { getActiveCategories }, getAllPartnerHomepage: { loading, getAllPartnerHomepage } } = this.props
    
    let homepage = {};
    if (!loading) {

      getAllPartnerHomepage && getAllPartnerHomepage.homePageData.map((item) => {
        homepage[item.name] = item.value
      });

    }
    
    return (
      <div className={s.root}>
        { !loading &&
          <div>
            <RestaurantBanner getCuisine={getActiveCategories} homepage={homepage} />
            <Container className={s.container}>
              <RestaurantSlider homepage={homepage} />
              <NewsBox homepage={homepage} />
              <RestaurantPartners homepage={homepage} />
            </Container>
          </div>
        }

      </div>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getCuisineCategories, {
    name: 'getCuisine',
    options: {
      ssr: true
    }
  }),
  graphql(getAllPartnerHomepage, {
    name: 'getAllPartnerHomepage',
    options: {
      ssr: true
    }
  })
)(RestaurantHome);
