import {
    SMS_VERIFICATION_MODAL_OPEN,
    SMS_VERIFICATION_MODAL_CLOSE
} from '../../../constants';


export function openSmsVerificationModal(formType) {

    return (dispatch, getState) => {
      dispatch({
        type: SMS_VERIFICATION_MODAL_OPEN,
        payload: {
          isSmsVerificationModalOpen: true,
          formType
        }
      });
    };
  
}

export function closeSmsVerificationModal() {
    
    return (dispatch, getState) => {
        dispatch({
          type: SMS_VERIFICATION_MODAL_CLOSE,
          payload: {
            isSmsVerificationModalOpen: false,
          }
        });
    };
}