
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './NotFound.css';
import NotFpundImage from '../../../public/SiteImages/404.png';
import Link from '../../components/Link';
import rs from '../../components/restaurantCommon.css';
import cx from 'classnames';

//Locale
import { FormattedMessage } from 'react-intl';
import messages from '../../locale/messages';

class NotFound extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className={s.root}>
        <div className={s.container}>
          <img src={NotFpundImage} className={s.notFoundImg} />
          <h1 className={s.notFoundTitle}>{this.props.title}</h1>
          <p className={s.notFoundSubTitle}><FormattedMessage {...messages.notFoundDesc} /></p>
          <Link to='/' className={cx(rs.button, rs.btnPrimary, rs.spaceTop4, s.btnWidth)}>
            <FormattedMessage {...messages.backToHome} />
          </Link>
        </div>
      </div>
    );
  }
}

export default withStyles(s, rs)(NotFound);