import messages from '../../../locale/messages';

const validate = values => {

  const errors = {}

  if (!values.pushNotificationKey || values.pushNotificationKey.toString().trim() == "") {
    errors.pushNotificationKey = messages.required;
  } 

  if (!values.smtpHost || values.smtpHost.toString().trim() == "") {
    errors.smtpHost = messages.required;
  }

  if (!values.smtpPort || values.smtpPort.toString().trim() == "") {
    errors.smtpPort = messages.required;
  } 

  if (!values.smptEmail || values.smptEmail.toString().trim() == "") {
    errors.smptEmail = messages.required && messages.required;
  } 

  if (!values.smtpSender || values.smtpSender.toString().trim() == "") {
    errors.smtpSender = messages.required;
  } 

  if (!values.smtpPassWord || values.smtpPassWord.toString().trim() == "") {
    errors.smtpPassWord = messages.required;
  }


  if (!values.smtpSenderEmail) {
    errors.smtpSenderEmail = messages.required && messages.required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.smtpSenderEmail)) {
    errors.smtpSenderEmail = messages.emailInvalid && messages.emailInvalid;
  }

  if (!values.twilioAccountSid || values.twilioAccountSid.toString().trim() == "") {
    errors.twilioAccountSid = messages.required;
  } 

  if (!values.twilioAuthToken || values.twilioAuthToken.toString().trim() == "") {
    errors.twilioAuthToken = messages.required;
  } 

  if (!values.twilioPhone || values.twilioPhone && values.twilioPhone.toString().trim() == "") {
    errors.twilioPhone = messages.required && messages.required;
  } else if (values.twilioPhone.length > 30) {
    errors.twilioPhone = messages.phoneNumberLengthInvalid;
  }

  if (!values.stripePublishableKey || values.stripePublishableKey.toString().trim() == "") {
    errors.stripePublishableKey = messages.required;
  } 

  if (!values.maxUploadSize || values.maxUploadSize.toString().trim() == "") {
    errors.maxUploadSize = messages.required;
  } 

  if (!values.maxDistance || values.maxDistance.toString().trim() == "") {
    errors.maxDistance = messages.required;
  } 

  if (!values.subtractTime || values.subtractTime.toString().trim() == "") {
    errors.subtractTime = messages.required;
  } else if (values.subtractTime) {
    if (isNaN(values.subtractTime)) {
      errors.subtractTime = messages.invalid;
    }else if (Number(values.subtractTime) < 10){
      errors.subtractTime = messages.subratctTimeInvalid;
    }
  }

 

  return errors;
}

export default validate;
