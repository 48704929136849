import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
	Row,
	Col,
	Button,
	Container
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from './Orders.css';
import rs from '../../../restaurantCommon.css';

//Images
import viewIcon from '../../../../../public/RestaurantIcon/view-icon.svg';

import messages from '../../../../locale/messages';
import { bookingStatus } from '../../../../helpers/bookingStatus';

import updateOrderStatus from '../../../../actions/shop/orders/updateOrderStatus';
import updateFoodStatus from '../../../../actions/shop/orders/updateFoodStatus';
import { openDeclineReasonModal, openModal, closeModal } from '../../../../actions/siteadmin/modalActions';

import DeclineReasonModal from '../DeclineReasonModal';
import Link from '../../../Link';
import ConfirmationPopup from '../../../Common/ConfirmationPopup';


class Orders extends Component {
	static defaultProps = {
		showDeclineModal: false
	};

	constructor(props) {
		super(props);
		this.state = {
			socketReceivers: [
				'orderReceived', 'shopApproved', 'shopDeclined', 'shopExpired', 'deliveryNotFound', 'testRequest', 'deliveryRequest',
				'deliveryAccept', 'cancelDelivery', 'confirmDelivery', 'startDelivery', 'completeDelivery', 'unableToDelivery', 'completeDeliveryDone', 'pickUpRequest'],
			buttonLoader: {}
		};

		this.renderBookingStatus = this.renderBookingStatus.bind(this);
		this.handleSocketResponse = this.handleSocketResponse.bind(this);
		this.handleDeliveryStatus = this.handleDeliveryStatus.bind(this);
		this.handleButtonLoader = this.handleButtonLoader.bind(this);
		this.confirmModalBody = this.confirmModalBody.bind(this);
	}

	componentDidMount() {
		const { userId, type, currentPage, data, data: { refetch }, socket } = this.props;
		const { socketReceivers } = this.state;
		let page = type === 'upcoming' ? 1 : currentPage;
		let that = this;

		if (socket && data && data.refetch && userId) {
			socketReceivers.map((item) => {
				socket.on(`${item}-${userId}`, (data) => { that.handleSocketResponse(page); });
			});
		}
	}

	componentWillUnmount() {
		const { socket, userId } = this.props;
		const { socketReceivers } = this.state;

		try {
			if (socket && socketReceivers) {
				socketReceivers.map((item) => {
					socket.removeListener(`${item}-${userId}`);
				});
			}
			clearTimeout(this.buttonLoaderTimeout);
		} catch (error) {
			// console.log('error', error);
		}
	}

	handleButtonLoader(id) {
		const { buttonLoader } = this.state;
		let updatedButtonLoader = buttonLoader;

		updatedButtonLoader[id] = true;
		this.setState({ buttonLoader: updatedButtonLoader });
		this.buttonLoaderTimeout = setTimeout(() => {
			updatedButtonLoader[id] = false;
			this.setState({ buttonLoader: updatedButtonLoader });
		}, 750);
	}

	handleSocketResponse(page) {
		const { paginationData } = this.props;
		paginationData(page);
	}

	async handleDeliveryStatus(id, status) {
		const { updateFoodStatus, paginationData, closeModal } = this.props;
		this.handleButtonLoader(id);
		await updateFoodStatus(id, null, status);
		await paginationData(1);
		await closeModal('completeConfirmModal');
	}

	async handleStatusChange(id, e) {
		const { updateOrderStatus, openDeclineReasonModal, currentPage } = this.props;
		let bookingStatus = e.target.value;

		if (bookingStatus === 'approved') {
			await updateOrderStatus(id, bookingStatus, currentPage);
		} else if (bookingStatus === 'declined') {
			await openDeclineReasonModal(id, currentPage);
		}
	}

	renderBookingStatus(status) {
		const { currentLocale } = this.props;
		if (status) {
			return <div>{bookingStatus(status, currentLocale)}</div>;
		}
	}

	confirmModalBody(code) {
		const { formatMessage } = this.props.intl;
		return (
			code &&
			<>
				<h4 className={s.completeText}>
					<FormattedMessage {...messages.orderConfirmation} />
				</h4>
				<h5 className={s.confirmCode} >
					{`${formatMessage(messages.confirmationCode)}: ${code}`}
				</h5>
			</>
		)
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { data, data: { loading, getAllOrders, refetch }, type, showDeclineModal, currentLocale, completeConfirmModal, openModal, modalData, closeModal } = this.props;
		const { buttonLoader } = this.state;
		let allowedFoodStatus = ['approved'];

		return (
			<div>
				{
					type === 'upcoming' && showDeclineModal === true && <DeclineReasonModal refetch={refetch} />
				}
				<div className={s.container}>
					<div>
						<ConfirmationPopup
							modalStatus={completeConfirmModal}
							title={formatMessage(messages.orderComplete)}
							body={this.confirmModalBody(modalData?.confirmCode)}
							closeModal={() => closeModal('completeConfirmModal')}
							popupButtonLeftName={formatMessage(messages.cancelButton)}
							popupButtonRightName={formatMessage(messages.confirmButton)}
							popupButtonLeftFunction={() => closeModal('completeConfirmModal')}
							popupButtonRightFunction={() => this.handleDeliveryStatus(modalData.id, modalData.status)}
							orderTitle={cx(s.orderTitleText, 'textAlignRightRTL')}
						/>
					</div>
					<Container fluid>
						<Row>
							<Col md={12} lg={12} sm={12} xs={12} className={s.noPadding}>
								<div className={s.panelBody}>
									<div className={cx(s.tableCss, 'tableCss', 'restauranttableSticky', 'restaurantTableCss', 'restauranttableOrder', 'NewResponsiveTable')}>
										<table className={cx('table', s.noBorder)}>
											<thead>
												<tr className={cx(s.rowBorder, s.sectionTitleLight, s.textTruncate)}>
													<th className={s.noBorder} scope="col"><FormattedMessage {...messages.orderId} /></th>
													<th className={s.noBorder} scope="col"><FormattedMessage {...messages.nameLabel} /></th>
													<th className={s.noBorder} scope="col"><FormattedMessage {...messages.noOfItem} /></th>
													<th className={s.noBorder} scope="col"><FormattedMessage {...messages.orderStatus} /></th>
													{
														type === 'upcoming' && <th className={s.noBorder} scope="col">
															<FormattedMessage {...messages.foodStatus} /></th>
													}
													<th className={s.noBorder} scope="col"><FormattedMessage {...messages.orderDetails} /></th>
													<th className={s.noBorder} scope="col"><FormattedMessage {...messages.confirmationCode} /></th>
													{
														type === 'upcoming' && <th className={s.noBorder} scope="col">
															<FormattedMessage {...messages.preparTime} /></th>
													}
												</tr>
											</thead>
											<tbody>
												{
													getAllOrders.results && getAllOrders.results.length > 0 && getAllOrders.results.map((item, key) => {
														return (
															<tr className={cx(s.rowBorder, s.sectionTitleLight)} key={key}>
																<td data-label={formatMessage(messages.orderId)}><span className='displayInlineRTL'>#</span>{`${item.orderId}`}</td>
																<td data-label={formatMessage(messages.nameLabel)}>{item.userName}</td>
																<td data-label={formatMessage(messages.noOfItem)}>{item.orderItemsCount}</td>
																<td data-label={formatMessage(messages.orderStatus)}>
																	{
																		item.bookingStatus === 'pending' && <select className={cx(rs.activeSelect, rs.restaurantSelectInput, rs.orderSlectWidth, (item.bookingStatus !== 'pending' ? s.inactive : ''))} value={item.bookingStatus}
																			onChange={(e) => this.handleStatusChange(item.id, e)} disabled={item.bookingStatus !== 'pending' ? true : false}>
																			<option value={'pending'}>{formatMessage(messages.pendingLabel)}</option>
																			<option value={'approved'}>{formatMessage(messages.acceptedLabel)}</option>
																			<option value={'declined'}>{formatMessage(messages.rejectedLabel)}</option>
																		</select>
																	}
																	{
																		item.bookingStatus !== 'pending' && this.renderBookingStatus(item.bookingStatus)
																	}
																</td>
																{
																	type === 'upcoming' && <td data-label={formatMessage(messages.foodStatus)}>
																		{((!item.isDoorDelivery && item.bookingStatus != 'readyForDelivery') || (item.isDoorDelivery)) && <Button className={cx(rs.button, rs.btnPrimary, s.btnPadding, rs.preLineBtn, (allowedFoodStatus.indexOf(item.bookingStatus) == -1 ? s.btnInactive : ''))}
																			disabled={(allowedFoodStatus.indexOf(item.bookingStatus) == -1 ? true : false) || (buttonLoader && buttonLoader[item.id])}
																			onClick={() => this.handleDeliveryStatus(item.id, 'readyForDelivery')}>
																			<span className={'vtrSub'}>{bookingStatus('readyForDelivery', currentLocale)}</span>
																		</Button>}
																		{item.bookingStatus == 'readyForDelivery' && !item.isDoorDelivery && <Button className={cx(rs.button, rs.btnPrimary, s.btnPadding, rs.preLineBtn, (item.bookingStatus == 'readyForDelivery' ? '' : s.btnInactive))}
																			disabled={(item.bookingStatus == 'readyForDelivery' ? false : true) || (buttonLoader && buttonLoader[item.id])}
																			onClick={() => openModal('completeConfirmModal', { id: item.id, confirmCode: item.confirmationCode, status: 'completed' })}>
																			<span className={'vtrSub'}>{bookingStatus('pickUpRequest', currentLocale)}</span>
																		</Button>}
																	</td>
																}
																<td className={s.textTruncate} data-label={formatMessage(messages.orderDetails)}>
																	<Link to={`/shop/order-details/${type}/${item.id}`} className={s.linkColor}>
																		<span className={cx('viewTextRTL', s.viewTextRTL)}>
																			<img src={viewIcon} className={s.iconWidth} />
																		</span>
																		<span>
																			<FormattedMessage {...messages.view} />
																		</span>
																	</Link>
																</td>
																<td data-label={formatMessage(messages.confirmationCode)}>{item.confirmationCode}</td>
																{
																	type === 'upcoming' && <td data-label={formatMessage(messages.preparTime)}>{`${item.shopPreparationTime} ${formatMessage(messages.minutes)}`}</td>
																}
															</tr>
														)
													})
												}
												{
													getAllOrders.results && getAllOrders.results.length <= 0 && <tr className={cx(s.rowBorder, s.sectionTitleLight)}>
														<td colSpan={type === 'upcoming' ? 7 : 5} className={s.alignCenter}><FormattedMessage {...messages.noOrdersFoundLabel} /></td>
													</tr>
												}
											</tbody>
										</table>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
};

const mapDispatch = {
	updateOrderStatus,
	updateFoodStatus,
	openDeclineReasonModal,
	openModal,
	closeModal
};
const mapState = (state) => ({
	completeConfirmModal: state.modalStatus.completeConfirmModal,
	modalData: state.modalStatus.data && state.modalStatus.data[0],
	userId: state.account && state.account.data && state.account.data.id,
	currentLocale: state.intl.locale
});

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(Orders)));