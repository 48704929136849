import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import {
  SET_PAYOUT_START,
  SET_PAYOUT_SUCCESS,
  SET_PAYOUT_ERROR,
  UPDATE_PAYMENT_STATUS_START,
  UPDATE_PAYMENT_STATUS_SUCCESS,
  UPDATE_PAYMENT_STATUS_ERROR
} from '../../../constants';
import getPaymentMethodsQuery from '../../../routes/site-admin/paymentGateway/getPaymentMethods.graphql';
import updatePaymentMutation from './updatePayment.graphql';

export function updatePayoutStatus(id, requestStatus, fieldName) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: SET_PAYOUT_START,
    });

    try {
      let errorMessage;
      let mutation = gql`
        mutation updatePayout ($id: Int!, $requestStatus: Boolean!, $fieldName: String){
          updatePayout(id: $id, requestStatus: $requestStatus, fieldName: $fieldName){
              status
              errorMessage
            }
        }`;

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          requestStatus,
          fieldName
        }
      });

      if (data.updatePayout.status === 200) {
        dispatch({
          type: SET_PAYOUT_SUCCESS,
        });
        toastr.success("Success!", "The requested changes has been updated successfully.");
        return {
          status: 200
        };
      } else {
        errorMessage = data.updatePayout && data.updatePayout.errorMessage || 'Something went wrong. Please try again.';

        dispatch({
          type: SET_PAYOUT_ERROR,
          payload: {
            error: errorMessage
          }
        });

        toastr.error("Failed!", errorMessage);
      }
      return await {
        status: 400
      };
    } catch (error) {
      dispatch({
        type: SET_PAYOUT_ERROR,
        payload: {
          error
        }
      });

      toastr.error("Failed!", `Oops! Something went wrong! ${error}`);

      return {
        status: 400
      };
    }
  };
}

export function updatePaymentMethod(id, status) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: UPDATE_PAYMENT_STATUS_START,
    });
    try {

      const { data } = await client.mutate({
        mutation: updatePaymentMutation,
        variables: { id, status },
        refetchQueries: [{ query: getPaymentMethodsQuery }]
      });
      if (data.updatePayment.status === 200) {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_SUCCESS,
        });
        toastr.success("Success!", "Payment gateway status has been updated successfully.");
      } else {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_ERROR,
          payload: {
            error: data.updatePayment && data.updatePayment.errorMessage
          }
        });
        toastr.error("Failed!", data.updatePayment && data.updatePayment.errorMessage ? data.updatePayment.errorMessage : 'Failed to update status!');
        return false;
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PAYMENT_STATUS_ERROR,
        payload: {
          error
        }
      });
      toastr.error("Failed!", "Failed to update status!");
      return false;
    }
    return true;
  };
}

