import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import EditLocation from './EditLocation';
import messages from '../../../locale/messages';


function action({ store,params, intl }) {
    const title = intl.formatMessage(messages.editLocation);

    //From Redux Store
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }

    const id = Number(params.id);

    return {
        title,
        component: (
            <AdminLayout>
                <EditLocation title={title} id={id} />
            </AdminLayout>
        ),
    }
}

export default action;