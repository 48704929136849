import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import { closeSmsVerificationModal } from './smsVerificationModaAction';

function checkOtp(otp) {
    return async(dispatch, getState, { client }) => {
        try {
             const mutation = gql`
                mutation verifyOtpCode($otp: Int!) {
                    verifyOtpCode(otp: $otp){
                        status
                        errorMessage
                    }
                }
            `;

            const { data } = await client.mutate({
                mutation,
                variables:{
                    otp: otp
                }
            });

            if ( data && data.verifyOtpCode && data.verifyOtpCode.status === 200) {
                toastr.success('Success', 'Phone Number verified successfully');
                await dispatch(closeSmsVerificationModal());
            } else {
                return false;
            }
        } catch(error) {
            return false;
        }
        return true;
    }
}

export default checkOtp;