import gql from 'graphql-tag';
import history from '../../history';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';

export function editDriver(id, firstName, lastName, email, password, phoneDialCode, phoneNumber, vehicleName, vehicleNumber, vehicleType, userStatus, isBan, phoneCountryCode) {
  return async (dispatch, getState, { client }) => {
    let errorMessage = 'Oops! something went wrong. Try again!';
    console.log(vehicleType)
    const mutation = gql`
        
        mutation updateDriver(
            $id: ID,
            $firstName: String,
            $lastName: String,
            $email: String,
            $password: String,
            $phoneDialCode: String
            $phoneNumber: String,
            $vehicleName: String,
            $vehicleNumber: String,
            $vehicleType: Int,
            $userStatus: String,
            $isBan: Int,
            $phoneCountryCode: String
           
            ) {
            updateDriver(
              id: $id
              firstName: $firstName
              lastName: $lastName
              email:$email
              password: $password
              phoneDialCode: $phoneDialCode
              phoneNumber: $phoneNumber
              vehicleName: $vehicleName
              vehicleNumber: $vehicleNumber
              vehicleType: $vehicleType
              userStatus: $userStatus
              isBan: $isBan
              phoneCountryCode: $phoneCountryCode
            )
            {
              status
              errorMessage
            }
          }
        `;
    const query = gql`
    query getAllDrivers($currentPage: Int, $searchList: String){
      getAllDrivers(currentPage: $currentPage, searchList: $searchList){
        count
        userData {
          id
          email
          phoneDialCode
          phoneNumber
          isBan
          userStatus
          createdAt
          profile {
            profileId
            firstName
            lastName
            country
          }
        }   
      }
    }
    `;
    
    dispatch(setLoaderStart('EditDriver'));

    const { data } = await client.mutate({
      mutation,
      variables: {
        id,
        firstName,
        lastName,
        email,
        password,
        phoneDialCode,
        phoneNumber,
        vehicleName,
        vehicleNumber,
        vehicleType: Number(vehicleType),
        userStatus,
        isBan,
        phoneCountryCode
      },
      refetchQueries: [{
        query,
        variables: {
          currentPage: 1
        }
      }]
    });

    dispatch(setLoaderComplete('EditDriver'));
    
    if (data && data.updateDriver && data.updateDriver.status === 200) {
      history.push('/siteadmin/drivers')
      toastr.success('Success!', 'Driver has been updated!')
    } else {
      errorMessage = (data && data.updateDriver && data.updateDriver.errorMessage) ? data.updateDriver.errorMessage : errorMessage;
      toastr.error("Error!", errorMessage);
    }
  }
}