import addMenu from '../../../../actions/shop/menu/addMenu';
import { toastr } from 'react-redux-toastr';
import messages from '../../../../locale/messages';


async function submit(values, dispatch) {

    let isError;
    values.menuAvailability && values.menuAvailability.map((item, index) => {

        item.timeSlot && item.timeSlot.length > 0 && item.timeSlot.map((item2, index) => {

            if (parseFloat(item2.endTime) < parseFloat(item2.startTime) || parseFloat(item2.endTime) == parseFloat(item2.startTime)) {
                isError = true;
            }

        });
    });

    if (isError === true) {
        toastr.error('Error', 'End Time must be more than Start Time');
    } else {
        isError = false;
        await dispatch(addMenu(values));
    }

}

export default submit;
