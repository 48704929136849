import React, { Component } from 'react';
import s from './StaticPageEditForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
	Button,
	Form,
	Col,
	Row,
	FormGroup,
	FormControl,
	Container
} from 'react-bootstrap';
import Link from '../../Link';
import messages from '../../../locale/messages';
import validate from './validate';
import Dropzone from './Dropzone';
import { api, staticpageUploadDir } from '../../../config';
import TrashIcon from '../../../../public/RestaurantIcon/dlty.png';
import { deleteStaticPageImage } from '../../../actions/siteadmin/deleteStaticPageImage';

export class StaticPageEditForm extends Component {

	constructor(props) {
		super(props)
		if (typeof window !== 'undefined') {
			this.ReactQuill = require('react-quill')
		}
		this.state = { editorHtml: '' } // You can also pass a Quill Delta here
		this.handleDeleteImage = this.handleDeleteImage.bind(this);
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		return (
			<Form.Group>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
				{touched && error && <span className={s.errorMessage}>{error.defaultMessage}</span>}
			</Form.Group>
		)
	}

	renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<FormGroup className={s.space3}>
					<div>
						<label className={s.labelText} >{label}</label>
					</div>
					<div>
						<FormControl {...input} placeholder={label} type={type} className={cx(className, s.formControlInput)} maxlength={maxlength} />
						{touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
					</div>
				</FormGroup>
			</div>
		);
	}


	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<FormGroup className={s.space3}>
					<div>
						<label className={s.labelText} >{label}</label>
					</div>
					<div>
						<FormControl
							{...input}
							className={className}
							placeholder={label}
							as="textarea"
							rows="4"
						>
							{children}
						</FormControl>
						{touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
					</div>
				</FormGroup>
			</div>
		);
	}

	renderQuill = ({ input, label, type, meta: { touched, error }, className }) => {
		const ReactQuill = this.ReactQuill;
		const { formatMessage } = this.props.intl;
		let modules = {
			toolbar: [
				[{ 'header': '1' }, { 'header': '2' }],
				[{ size: [] }],
				['bold', 'italic', 'underline', 'strike', 'blockquote'],
				[{ 'list': 'ordered' }, { 'list': 'bullet' },
				{ 'indent': '-1' }, { 'indent': '+1' }],
				['link'],
				// ['link', 'image'],
			],
			clipboard: {
				matchVisual: false,
			}
		};

		let formats = [
			'header', 'size',
			'bold', 'italic', 'underline', 'strike', 'blockquote',
			'list', 'bullet', 'indent',
			'link'
			// 'link', 'image'
		];
		return (
			<div>
				<ReactQuill
					{...input}
					onChange={(newValue, delta, source) => {
						if (source === 'user') {
							input.onChange(newValue);
						}
					}}
					onBlur={(range, source, quill) => {
						if (quill.getHTML() == '<p><br></p>') {
							input.onBlur('');
						}
						else {
							input.onBlur(quill.getHTML());
						}
					}}
					modules={modules}
					formats={formats}
					theme="snow"
				/>

				{touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
			</div>
		);
	}

	async handleDeleteImage() {
		const { change, deleteStaticPageImage, id, pageBanner } = this.props;
		await deleteStaticPageImage(id, pageBanner);
		await change('pageBanner', null);

	}

	render() {
		const { error, handleSubmit, submitting, pageBanner, id } = this.props;
		const { formatMessage } = this.props.intl;
		const ReactQuill = this.ReactQuill;

		if (typeof window !== 'undefined' && ReactQuill) {
			return (
				<div className={cx(s.space5, s.spaceTop5)}>
					<Container fluid>
						<Row>
							<Col lg={8} md={12} sm={12} xs={12} className={s.blackCenter}>
								<div className={s.blackCenterSection}>
									<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
										{/* <div className={s.headingPadding}> <h1 className={s.titleTextAdmin}>{formatMessage(messages.editPageDetails)}</h1></div> */}
										<div>
											<Col lg={12} md={12} sm={12} xs={12} className={s.paddingTop}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.positionRelative)}>
														<label>{formatMessage(messages.pageBanner)}</label><br />
														{
															pageBanner &&
															<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + staticpageUploadDir}large_${pageBanner})` }} />
														}

														{
															pageBanner ?
																<Button onClick={this.handleDeleteImage} className={s.btnTrash}>
																	<img src={TrashIcon} alt='Delete' />
																</Button>
																:
																''
														}

														<div>
															<Dropzone
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																fieldName={'pageBanner'}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																oldFileName={pageBanner}
																id={id}
															/>
														</div>
													</div>
												</Form.Group>
											</Col>
										</div>
										<Col lg={12} md={12} sm={12} xs={12}>
											<div>
												<Form.Group className={s.space3}>
													<Field
														name="metaTitle"
														type="text"
														placeholder={formatMessage(messages.metaTitle)}
														component={this.renderFormControl}
														label={formatMessage(messages.metaTitle)}
														labelClass={s.labelText}
														fieldClass={s.formControlInput}
													/>
												</Form.Group>
											</div>
										</Col>
										<Col lg={12} md={12} sm={12} xs={12}>
											<div>
												<Form.Group className={s.space3}>

													<Field
														name="metaDescription"
														type="text"
														placeholder={formatMessage(messages.metaDescriptionText)}
														component={this.renderFormControlTextArea}
														label={formatMessage(messages.metaDescriptionText)}
														labelClass={s.labelText}
														fieldClass={s.formControlInput}
													/>

												</Form.Group>
											</div>
										</Col>
										<Col lg={12} md={12} sm={12} xs={12}>
											<FormGroup className={s.space3}>
												<div>
													<label className={s.labelText} ><FormattedMessage {...messages.content} /></label>
												</div>
												<div xs={12} sm={9} md={9} lg={9}>
													<Field name="content" component={this.renderQuill} />
												</div>
											</FormGroup>
										</Col>
										<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
											<Form.Group className={s.noMargin}>
												<Button
													type="submit"
													className={cx(s.button, s.btnPrimary)}
												>
													{formatMessage(messages.submitButton)}
												</Button>
												<Link to={'/siteadmin/staticpage/manage'} className={cx(s.backBtn, s.btnSecondary, s.linkBtnInline, 'backBtnRTL')} >{formatMessage(messages.goBack)}</Link>
											</Form.Group>
										</Col>
									</Form>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			)
		} else {
			return <textarea />
		}

	}
}

StaticPageEditForm = reduxForm({
	form: 'StaticPageEditForm',
	onSubmit: submit,
	validate
})(StaticPageEditForm);

const selector = formValueSelector('StaticPageEditForm')

const mapState = (state) => ({
	pageBanner: selector(state, 'pageBanner'),
	id: selector(state, 'id'),
})

const mapDispatch = {
	deleteStaticPageImage
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(StaticPageEditForm)));