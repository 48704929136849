exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n:root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --btn-primary-bg: #9D7A62;\n  --btn-boxShadow-bg: #9D7A62;\n  --btn-bordercolor-bg: #9D7A62;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #9D7A62;\n  --btn-secondary-bg: #9D7A62;\n  --title-color: #1a1b1c;\n  --content-color: #3a3a3a;\n\n}\n\n.wSld0 {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n._37QeM {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 1170px;\n  max-width: var(--max-content-width);\n}\n", ""]);

// exports
exports.locals = {
	"root": "wSld0",
	"container": "_37QeM"
};