import gql from 'graphql-tag';
import {
    UPDATE_CATEGORY_PRIVILEGE_SUCCESS,
    UPDATE_CATEGORY_PRIVILEGE_START,
    UPDATE_CATEGORY_PRIVILEGE_ERROR
} from '../../../constants';
import { toastr } from 'react-redux-toastr';

export default function updateCategoryPrivilege(id, isActive, privilegeType) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: UPDATE_CATEGORY_PRIVILEGE_START
        });
        
        try {
            let errorMessage;

            let mutation = gql`
            mutation($id: Int!, $isActive: Boolean, $privilegeType: String!) {
                updateCategoryPrivilege(id: $id, isActive: $isActive, privilegeType: $privilegeType) {
                    status
                    errorMessage
                }
            }
            `;

            const { data } = await client.mutate({
                mutation,
                variables : {
                    id,
                    privilegeType,
                    isActive
                }
            });
            
            if (data && data.updateCategoryPrivilege && data.updateCategoryPrivilege.status === 200) {
                dispatch({
                    type: UPDATE_CATEGORY_PRIVILEGE_SUCCESS
                });
                toastr.success('Success', `The cuisine has been updated.`);
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: UPDATE_CATEGORY_PRIVILEGE_ERROR
                });

                errorMessage = (data && data.updateCategoryPrivilege && data.updateCategoryPrivilege.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
                return {
                    status: 400
                };
            }
        } catch(err) {
            toastr.error('Error!', err);
            dispatch({
                type: UPDATE_CATEGORY_PRIVILEGE_ERROR
            });
            return {
                status: 400
            };
        }

    }
};